import ReactHtmlParser from "react-html-parser";
import { Button, Carousel, Radio, Typography } from "@material-tailwind/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import "./product.css";
import {
  findCartProductId,
  findWishProductId,
  isUserLoggedIn,
  setSeoData,
} from "../../services";
import { Product } from "../../models/responses/WPProductResponse";
import {
  CartRepository,
  WPProductsRepository,
  WishlistRepository,
} from "../../business";
import { AlertContext } from "../../App";
import PDPImage from "./PDPImage";
import RelatedProduct from "./RelatedProduct";
import { AlertType, FullScreenLoader } from "../../components";
import { useCart, useWishlist } from "../../hooks";
import FetchPincode from "./fetchApi";
import CheckDeliveryComponent from "./fetchApi";
import { Helmet } from "react-helmet-async";
import { useOpenLoginDialog } from "../../components/openLoginDialog";

enum LoadingState {
  LOADING,
  LOADED,
  ERROR,
}

export function ProductDetailPage() {
  const [inCart, setInCart] = useState<boolean>(false);
  const [isImagesVisible, setIsImagesVisible] = useState(false);
  const alertContextValue = useContext(AlertContext);

  let { productId } = useParams();
  const wpProductRepository = new WPProductsRepository();

  const { wishlist, addToWishlist } = useWishlist();
  const { cart, addToCart } = useCart();

  const rating = useRef<string | number>((4 + Math.random()).toFixed(2));

  const totalRatings = useRef<string | number>(
    (Math.random() * (3 - 1) + 1).toFixed(2)
  );

  const openLoginDialog = useOpenLoginDialog();

  const openLogin = () => {
    // Perform some actions
    openLoginDialog(); // Open the login dialog
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [product, setProduct] = useState<Product>();

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADED
  );

  const [inWishlist, setInWishlist] = useState<boolean>(
    findWishProductId(wishlist, product?.id.toString() ?? "0")
  );

  const handleInWishlist = async () => {
    setLoadingState(LoadingState.LOADING);
    const wishlistRepository = new WishlistRepository();
    if (!inWishlist === true) {
      try {
        const wishlistResponse = await wishlistRepository.addToWishlist(
          +(product?.id ?? "0")
        );
        addToWishlist(
          product?.id?.toString() ?? "0",
          wishlistResponse?.data?.toString() ?? "0"
        );
        alertContextValue.showAlert(
          AlertType.SUCCESS,
          "Added to wishlist...",
          null
        );
      } catch (error) {
        console.log(error);
        alertContextValue.showAlert(
          AlertType.ERROR,
          "Something went wrong...",
          null
        );
      }
    }
    setLoadingState(LoadingState.LOADED);
  };

  const [cartLoadingState, setCartLoadingState] = useState<LoadingState>(
    LoadingState.LOADED
  );

  const fetchProduct = async () => {
    setLoading(true);
    try {
      if (isNaN(+(productId ?? ""))) {
        const wpProductResponse = await wpProductRepository.getProducts({
          slug: productId,
        });

        setProduct(wpProductResponse?.data[0]);

        // setInCart(findCartProductId(cart, wpProductResponse?.data[0].id ?? ""));
      } else {
        const wpProductResponse = await wpProductRepository.getProductById(
          +(productId ?? "0")
        );

        setProduct(wpProductResponse?.data);

        // setInCart(findCartProductId(cart, wpProductResponse?.data.id ?? ""));
      }
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const handleAddToCart = async () => {
    setCartLoadingState(LoadingState.LOADING);
    const cartRepository = new CartRepository();
    try {
      const cartResponse = await cartRepository.addToCart(
        +(product?.id ?? "0")
      );
      // console.log(cartResponse?.data);
      // console.log(product?.id.toString(), cartResponse?.data.toString(), 1);
      addToCart(
        product?.id.toString() ?? "0",
        cartResponse?.data.toString() ?? "0",
        1
      );
      alertContextValue.showAlert(AlertType.SUCCESS, "Added to cart !", null);
    } catch (error) {
      console.log(error);
      alertContextValue.showAlert(
        AlertType.ERROR,
        "Something went wrong...",
        null
      );
    }
    setCartLoadingState(LoadingState.LOADED);
  };

  const renderWishlistIcon = () => {
    return inWishlist ? (
      <Button
        placeholder={undefined}
        disabled
        color="gray"
        size="lg"
        className="mt-4 w-full sm:w-auto"
      >
        Added to Wishlist
      </Button>
    ) : (
      <Button
        placeholder={undefined}
        onClick={() => {
          isUserLoggedIn() ? handleInWishlist() : openLogin();
        }}
        size="lg"
        variant="outlined"
        className="mt-4 w-full sm:w-auto "
        fullWidth={true}
      >
        {loadingState === LoadingState.LOADING ? (
          <div
            className="mt-0.5 inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        ) : (
          " Add to Wishlist"
        )}
      </Button>
    );
  };

  const renderAddToCartButton = () => {
    return inCart ? (
      <Button
        placeholder={undefined}
        disabled
        color="gray"
        size="lg"
        className="mt-4 w-full sm:w-auto "
      >
        Added to cart
      </Button>
    ) : (
      <Button
        placeholder={undefined}
        onClick={() => {
          isUserLoggedIn() ? handleAddToCart() : openLogin();
        }}
        size="lg"
        className="mt-4 w-full sm:w-auto"
        fullWidth={true}
      >
        {cartLoadingState === LoadingState.LOADING ? (
          <div
            className="mt-0.5 inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        ) : (
          "Add to cart"
        )}
      </Button>
    );
  };

  useEffect(() => {
    fetchProduct();
  }, [productId]);

  useEffect(() => {
    setSeoData(product?.name, product?.short_description);
    setInCart(findCartProductId(cart, product?.id ?? ""));
    setInWishlist(findWishProductId(wishlist, product?.id ?? 0));
  }, [product]);

  useEffect(() => {
    renderAddToCartButton();
  }, [inCart]);

  useEffect(() => {
    setInCart(findCartProductId(cart, product?.id ?? "0"));
  }, [cart]);

  useEffect(() => {
    setInWishlist(findWishProductId(wishlist, product?.id ?? 0));
    renderWishlistIcon();
  }, [wishlist]);

  console.log(product, "product");
  return (
    <>
      {!isImagesVisible && (
        <div className="p-3 lg:mx-14 xl:mx-16 2xl:mx-48 my-12">
          {loading && (
            <>
              <FullScreenLoader />
              <div className="m-96">&nbsp;</div>
            </>
          )}
          {!loading && product && (
            <>
              {/* <Breadcrumb
            breadcrumb={["Products", product?.slug ?? productId ?? ""]}
          /> */}

              <Helmet>
                <meta charSet="UTF-8" />
                <title>{product?.name}</title>
                <meta name="description" content={product?.description} />
              </Helmet>
              <div className="grid grid-cols-1 md:grid-cols-5 gap-6  mx-4">
                <div className="col-span-3">
                  <PDPImage
                    productImages={product?.images ?? []}
                    setIsImagesVisible={setIsImagesVisible}
                  />
                </div>
                <div className="col-span-2">
                  <h2 className="mt-5 text-2xl font-bold ">
                    {product?.name ?? ""}
                  </h2>
                  <h1 className="text-lg mt-1 text-gray-700">
                    {ReactHtmlParser(product?.short_description)}
                  </h1>

                  <div className="mt-3.5 flex-wrap flex gap-2">
                    <div className="inline-flex border px-1 py-0.5">
                      <Typography
                        placeholder={undefined}
                        color="blue-gray"
                        className="flex items-center gap-1.5 font-normal text-gray-600 text-sm"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className="-mt-0.5 h-5 w-5 text-yellow-700 text-sm"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                            clipRule="evenodd"
                          />
                        </svg>
                        {rating?.current}
                      </Typography>
                      <Typography
                        placeholder={undefined}
                        className="flex  mx-2 items-center gap-1.5 font-normal text-gray-500"
                      >
                        |
                      </Typography>

                      <Typography
                        placeholder={undefined}
                        className="text-sm  flex items-center gap-1.5 font-normal text-gray-600"
                      >
                        {totalRatings?.current}k Ratings
                      </Typography>
                    </div>
                    {product?.featured && (
                      <div className="rounded-sm bg-appPrimary-50 px-2 py-1 flex justify-center items-center">
                        <Typography
                          placeholder={undefined}
                          color="black"
                          className="text-sm uppercase font-medium"
                        >
                          Popular
                        </Typography>
                      </div>
                    )}
                    {product?.on_sale && (
                      <div className="rounded-sm bg-appPrimary-500 px-2 py-1 flex justify-center items-center">
                        <Typography
                          placeholder={undefined}
                          color="black"
                          className="text-sm uppercase font-medium"
                        >
                          {Math.ceil(
                            ((+product?.regular_price - +product?.sale_price) /
                              +product?.regular_price) *
                              100
                          )}
                          % OFF
                        </Typography>
                      </div>
                    )}
                  </div>

                  {/* <div className="my-3">
              <hr />
            </div> */}
                  <Typography
                    placeholder={undefined}
                    className="sh-price font-semibold text-left mt-2 text-2xl no-underline  mt-4"
                  >
                    {ReactHtmlParser(product.price_html)}
                  </Typography>

                  <div>{/* <CheckDeliveryComponent /> */}</div>

                  {product.attributes &&
                    product.attributes?.map((attribute: any) => (
                      <div className="mt-4">
                        <h3 className="text-lg font-semibold text-black  ">
                          {attribute?.name}
                        </h3>
                        <div className="flex gap-4 text-lg font-semibold">
                          {attribute?.options?.flatMap((item: string) =>
                            item
                              .split(",")
                              .map((splitItem: string) => (
                                <Radio
                                  key={splitItem.trim()}
                                  name="type"
                                  label={splitItem.trim()}
                                  value={splitItem.trim()}
                                  crossOrigin={undefined}
                                />
                              ))
                          )}
                        </div>
                      </div>
                    ))}

                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    {(product?.stock_quantity ?? 0) > 0 ? (
                      <>{renderAddToCartButton()}</>
                    ) : (
                      <Button
                        placeholder={undefined}
                        disabled
                        color="amber"
                        size="lg"
                        className="mt-4 w-full sm:w-auto "
                        fullWidth={true}
                      >
                        Out of stock
                      </Button>
                    )}
                    {renderWishlistIcon()}
                  </div>

                  {/* <div className="my-3">
              <hr />
            </div> */}

                  {/* <div className="mt-10">
                <div>
                  <div className="flex items-center gap-3">
                    <h2 className=" text-lg font-semibold ">
                      DELIVERY OPTIONS
                    </h2>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-7 h-7"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 18.75a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 01-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 00-3.213-9.193 2.056 2.056 0 00-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 00-10.026 0 1.106 1.106 0 00-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                      />
                    </svg>
                  </div>

                  <div className="border text-gray-800 font-medium  p-2 mt-2 w-64 cursor-pointer">
                    141001 (Samridh)
                    <button className="ml-10 text-red-300 text-sm">
                      CHANGE
                    </button>
                  </div>
                  <div className="mt-4 text-gray-600 font-medium flex items-center gap-3  text-md">
                    <svg
                      viewBox="0 0 24 25"
                      className="inline-block align-top w-7 h-7 real-descriptor-image"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="M0 1h24v24H0z"></path>
                        <path
                          d="M21.872 12.843l-.68 3.849a1.949 1.949 0 00-.398-.819c-.377-.447-.925-.693-1.549-.693-1.024 0-1.98.669-2.395 1.601l1.159-6.571h1.703c.7 0 1.31.265 1.713.746.415.494.573 1.164.447 1.887m-3.238 5.812c-.297 0-.55-.108-.715-.306-.172-.204-.236-.486-.183-.795.123-.698.816-1.288 1.51-1.288.296 0 .55.108.716.306.17.204.235.486.18.794-.123.699-.814 1.289-1.508 1.289m-11.308 0c-.295 0-.55-.108-.715-.306-.171-.204-.236-.486-.18-.794.122-.699.814-1.289 1.508-1.289.296 0 .55.108.714.306.172.204.237.486.182.794-.123.699-.815 1.289-1.509 1.289m14.932-8.397c-.616-.731-1.518-1.134-2.546-1.134H18.2l.262-1.487A.546.546 0 0017.927 7H6.417a.543.543 0 100 1.086H17.28l-1.557 8.832h-5.8a1.965 1.965 0 00-.438-1.045c-.376-.447-.926-.693-1.548-.693-1.074 0-2.074.734-2.454 1.738h-.356l.143-.811a.543.543 0 10-1.069-.188l-.256 1.447a.546.546 0 00.535.637h.86c.045.389.194.753.438 1.045.375.446.925.693 1.548.693 1.075 0 2.075-.734 2.454-1.738h6.867c.044.389.194.752.439 1.045.375.446.925.693 1.547.693 1.075 0 2.075-.734 2.454-1.738h.52c.264 0 .49-.189.534-.449l.799-4.523c.184-1.043-.058-2.028-.683-2.773"
                          fill="#535766"
                        ></path>
                        <path
                          d="M9.812 9.667c0-.3-.243-.543-.543-.543H1.543a.544.544 0 000 1.086h7.726c.3 0 .543-.243.543-.543M9.387 12.074c0-.3-.243-.543-.543-.543h-5.82a.543.543 0 100 1.086h5.82c.3 0 .543-.243.543-.543M8.42 13.938H4.502a.543.543 0 100 1.086H8.42a.543.543 0 100-1.086"
                          fill="#535766"
                        ></path>
                      </g>
                    </svg>{" "}
                    Get it by Wed, Oct 25
                  </div>
                  <div className="mt-4 text-gray-600 font-medium  font-medium  flex items-center gap-3 text-md">
                    <svg
                      viewBox="0 0 24 25"
                      className="inline-block align-top w-7 h-7 real-descriptor-image"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <path d="M0 1h24v24H0z"></path>
                        <path
                          d="M21.872 12.843l-.68 3.849a1.949 1.949 0 00-.398-.819c-.377-.447-.925-.693-1.549-.693-1.024 0-1.98.669-2.395 1.601l1.159-6.571h1.703c.7 0 1.31.265 1.713.746.415.494.573 1.164.447 1.887m-3.238 5.812c-.297 0-.55-.108-.715-.306-.172-.204-.236-.486-.183-.795.123-.698.816-1.288 1.51-1.288.296 0 .55.108.716.306.17.204.235.486.18.794-.123.699-.814 1.289-1.508 1.289m-11.308 0c-.295 0-.55-.108-.715-.306-.171-.204-.236-.486-.18-.794.122-.699.814-1.289 1.508-1.289.296 0 .55.108.714.306.172.204.237.486.182.794-.123.699-.815 1.289-1.509 1.289m14.932-8.397c-.616-.731-1.518-1.134-2.546-1.134H18.2l.262-1.487A.546.546 0 0017.927 7H6.417a.543.543 0 100 1.086H17.28l-1.557 8.832h-5.8a1.965 1.965 0 00-.438-1.045c-.376-.447-.926-.693-1.548-.693-1.074 0-2.074.734-2.454 1.738h-.356l.143-.811a.543.543 0 10-1.069-.188l-.256 1.447a.546.546 0 00.535.637h.86c.045.389.194.753.438 1.045.375.446.925.693 1.548.693 1.075 0 2.075-.734 2.454-1.738h6.867c.044.389.194.752.439 1.045.375.446.925.693 1.547.693 1.075 0 2.075-.734 2.454-1.738h.52c.264 0 .49-.189.534-.449l.799-4.523c.184-1.043-.058-2.028-.683-2.773"
                          fill="#535766"
                        ></path>
                        <path
                          d="M9.812 9.667c0-.3-.243-.543-.543-.543H1.543a.544.544 0 000 1.086h7.726c.3 0 .543-.243.543-.543M9.387 12.074c0-.3-.243-.543-.543-.543h-5.82a.543.543 0 100 1.086h5.82c.3 0 .543-.243.543-.543M8.42 13.938H4.502a.543.543 0 100 1.086H8.42a.543.543 0 100-1.086"
                          fill="#535766"
                        ></path>
                      </g>
                    </svg>{" "}
                    Pay on delivery available
                  </div>
                  <div className="mt-4 text-gray-600 font-medium  flex items-center gap-3 text-md">
                    <svg
                      viewBox="0 0 24 24"
                      className=" w-7 h-7 real-descriptor-image"
                    >
                      <g fill="#535766">
                        <path d="M15.19 8.606V4.3a.625.625 0 00-.622-.625H6.384V.672a.624.624 0 00-.407-.588.62.62 0 00-.687.178L.367 6.048a.628.628 0 000 .812l4.923 5.778a.626.626 0 00.687.182.624.624 0 00.407-.588V9.228h8.184a.62.62 0 00.621-.622zm-1.244-.625H5.762a.625.625 0 00-.621.625v1.938l-3.484-4.09L5.14 2.362V4.3c0 .344.28.625.621.625h8.184v3.056z"></path>
                        <path d="M22.708 13.028L17.785 7.25a.616.616 0 00-.687-.178.624.624 0 00-.407.587v3.003H8.507a.625.625 0 00-.622.625v4.304c0 .343.28.625.622.625h8.184v3.003a.624.624 0 00.621.625.626.626 0 00.473-.219l4.923-5.781a.632.632 0 000-.816zm-4.774 4.497v-1.937a.625.625 0 00-.622-.625H9.13v-3.054h8.183a.625.625 0 00.622-.625V9.347l3.484 4.09-3.484 4.088z"></path>
                      </g>
                    </svg>
                    Easy 14 days return available
                  </div>
                </div>
                <h4 className="mt-4 mb-5 text-gray-600 text-sm">
                  100% Original Products
                </h4>

                <div className="mt-10">
                  <h2 className="text-lg font-semibold my-2 flex items-center gap-2">
                    Customer Ratings By features
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </h2>
                  <div>
                    <div className="">
                      <div className="mb-1 grid grid-cols-[0.5fr_0.1fr_0.1fr] gap-2 items-center">
                        <h3 className="text-md text-gray-700">Sheerness</h3>
                        <Rating value={3} readonly />
                      </div>
                      <div className="mb-1 grid grid-cols-[0.5fr_0.1fr_0.1fr] gap-2 items-center">
                        <h3 className="text-md text-gray-700">Thickness</h3>
                        <Rating value={4} readonly />
                      </div>
                      <div className="mb-1 grid grid-cols-[0.5fr_0.1fr_0.1fr] gap-2 items-center">
                        <h3 className="text-md text-gray-700">Softens</h3>
                        <Rating value={5} readonly />
                      </div>
                      <div className="mb-1 grid grid-cols-[0.5fr_0.1fr_0.1fr] gap-2 items-center">
                        <h3 className="text-md text-gray-700">
                          Value for money
                        </h3>
                        <Rating value={4} readonly />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                  <div className="mt-10 ">
                    <h2 className="text-lg font-semibold mt-6 mb-2 flex items-center gap-3">
                      PRODUCT DETAILS
                    </h2>
                    <div className="desc text-md text-justify text-gray-600">
                      {ReactHtmlParser(product.description)}
                    </div>
                  </div>
                </div>
              </div>

              {product?.related_ids && product?.related_ids?.length > 0 && (
                <div className="mt-10 ">
                  <h2 className="mx-4 lg:mx-14 xl:mx-16 text-lg font-semibold mt-6 mb-2 flex items-center">
                    RELATED PRODUCT
                  </h2>
                  <RelatedProduct products={product?.related_ids} />
                </div>
              )}
            </>
          )}
        </div>
      )}

      {isImagesVisible && (
        <div className="relative mx-2">
          <div className="absolute  right-2 md:right-10 z-10">
            <div
              className="absolute top-0 right-0 md:right-10 z-10 border border-black p-1 rounded-full cursor-pointer"
              onClick={() => setIsImagesVisible(false)}
            >
              <IoClose size={30} />
            </div>
          </div>
          <div className="flex justify-center items-center  gap-6 mt-16 pt-20 lg:pt-0">
            <Carousel
              placeholder={undefined}
              className="h-[30rem] sm:h-[44rem] w-[42rem] "
              navigation={({ setActiveIndex, activeIndex, length }) => (
                <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                  {new Array(length).fill("").map((_, i) => (
                    <span
                      key={i}
                      className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                        activeIndex === i ? "w-8 bg-white" : "w-4 bg-white/50"
                      }`}
                      onClick={() => setActiveIndex(i)}
                    />
                  ))}
                </div>
              )}
            >
              {product?.images &&
                product?.images?.length > 1 &&
                product?.images?.map((item: any) => (
                  <img
                    key={item.id}
                    className="h-full w-full object-fill cursor-pointer border rounded-lg"
                    src={item.src}
                    alt={item.alt}
                  />
                ))}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}

// export default connector(ProductDetailPage);
