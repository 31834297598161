import { useEffect, useState, useContext } from "react";
import CartRepository from "../../business/CartRepository";
import {
  Button,
  Radio,
  Select,
  Spinner,
  Option,
} from "@material-tailwind/react";
import { AlertContext } from "../../App";
import { useLocation, useNavigate } from "react-router-dom";
import { parseCart } from "../../services/cart";
import { getKEY_ID } from "../../services/user";
import { pushOrderToShipRocket } from "../../services/shiprocket";
import {
  PaymentRepository,
  WPCustomerRepository,
  WPOrderRepository,
  WPProductsRepository,
} from "../../business";
import { UserAddress } from "../../models/responses/UserResponse";
import { Coupon, Customer } from "../../models/responses/WPResponse";
import { WPCouponsRepository } from "../../business/WP/WPCouponsRepository";
import { Cart, OrderPriceSummary } from "../../models/responses/CartResponse";
import {
  AlertType,
  CartProfileFormModal,
  FullScreenLoader,
} from "../../components";
import { CartAddress } from "./CartAddress";
import { CartProductCard } from "./CartProductCard";
import { useCart } from "../../hooks";
import PhonePayPaymentRepository from "../../business/PhonePayPaymentRepository";
import { useMediaQuery } from "react-responsive";

enum LoadingState {
  LOADING,
  LOADED,
  ERROR,
}

enum OrderType {
  COD = "COD",
  ONLINE = "ONLINE",
}

export const CartPage = () => {
  const { cart, clearCart } = useCart();
  const isMobile = useMediaQuery({ query: "(max-width: 1024px)" });

  const navigate = useNavigate();
  const alertContextValue = useContext(AlertContext);
  const cartRepository = new CartRepository();

  const paymentRepository = new PhonePayPaymentRepository();

  const [cartData, setCartData] = useState<Cart[] | undefined>();
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING
  );

  const [address, setAddress] = useState<UserAddress[] | []>([]);

  const [profile, setProfile] = useState<Customer>();

  const [orderPriceSummary, setOrderPriceSummary] = useState<OrderPriceSummary>(
    {
      subTotal: 0,
      discount: 0,
      convenienceFee: 0,
      shippingFee: 0,
      totalAmount: 0,
    }
  );

  const [placeOrderLoading, setPlaceOrderLoading] = useState<LoadingState>(
    LoadingState.LOADED
  );

  const [orderType, setOrderType] = useState<OrderType>(OrderType.COD);

  const [coupons, setCoupons] = useState<Coupon[] | [] | undefined>([]);
  const [selectedCoupon, setSelectedCoupon] = useState<string | undefined>();

  const applyCoupan = (coupon: any, cartData: any) => {
    const orderTotal = orderPriceSummary.subTotal;

    setSelectedCoupon(coupon.code);

    const cartProductCategories = cartData.flatMap((item: any) =>
      item.product_detail.categories.map((category: any) => category.id)
    );
    const cartProductIds = cartData.map((item: any) => item.cart_product_id);

    const isCategoryApplicable =
      coupon.product_categories.length === 0 ||
      coupon.product_categories.some((categoryId: any) =>
        cartProductCategories.includes(categoryId)
      );
    const isProductApplicable =
      coupon.product_ids.length === 0 ||
      coupon.product_ids.some((productId: any) =>
        cartProductIds.includes(productId)
      );

    if (!isCategoryApplicable && !isProductApplicable) {
      alert("The offer is not applicable to the products in your cart.");
      setSelectedCoupon("Invalid Coupon");
      return;
    }

    if (orderTotal <= +coupon.minimum_amount) {
      alert("Minimum order value should be " + coupon.minimum_amount);
      setSelectedCoupon("Invalid Coupon");
      return;
    }

    let usedCoupons = JSON.parse(sessionStorage.getItem("usedCoupons") || "[]");
    if (coupon.usage_limit_per_user === 1 && usedCoupons.includes(coupon.id)) {
      alert("This coupon has already been used.");
      setSelectedCoupon("Invalid Coupon");
      return;
    }

    sessionStorage.setItem("appliedCoupan", coupon.id);
    setSelectedCoupon(coupon.code);

    let discountAmount = 0;

    cartData.forEach((item: any) => {
      const isItemCategoryApplicable =
        coupon.product_categories.length === 0 ||
        item.product_detail.categories.some((category: any) =>
          coupon.product_categories.includes(category.id)
        );
      const isItemProductApplicable =
        coupon.product_ids.length === 0 ||
        coupon.product_ids.includes(item.cart_product_id);

      if (isItemCategoryApplicable || isItemProductApplicable) {
        const itemPrice = parseFloat(item.prodTotalPrice);

        if (coupon.discount_type === "percent") {
          const itemDiscount = (itemPrice * +coupon.amount) / 100;
          discountAmount += itemDiscount;
        } else if (coupon.discount_type === "fixed_cart") {
          const applicableItemsCount = cartData.filter((cartItem: any) => {
            const isCategoryApplicable =
              coupon.product_categories.length === 0 ||
              cartItem.product_detail.categories.some((category: any) =>
                coupon.product_categories.includes(category.id)
              );
            const isProductApplicable =
              coupon.product_ids.length === 0 ||
              coupon.product_ids.includes(cartItem.cart_product_id);
            return isCategoryApplicable || isProductApplicable;
          }).length;
          const itemDiscount = +coupon.amount / applicableItemsCount;
          discountAmount += itemDiscount;
        }
      }
    });

    setOrderPriceSummary((prev) => ({
      ...prev,
      discount: discountAmount,
      totalAmount: prev.subTotal + prev.shippingFee - discountAmount,
    }));

    if (coupon.usage_limit_per_user === 1) {
      usedCoupons.push(coupon.id);
      sessionStorage.setItem("usedCoupons", JSON.stringify(usedCoupons));
    }
  };

  const applyCoupanOnRelod = async (cartData: any) => {
    const coupanId = sessionStorage.getItem("appliedCoupan") ?? "";

    const wpCouponsRepository = new WPCouponsRepository();
    try {
      const couponsResponse = await wpCouponsRepository.getACoupan(coupanId);
      applyCoupan(couponsResponse?.data, cartData);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProductInfo = async (productId: number) => {
    const productRepository = new WPProductsRepository();
    return await productRepository.getProductById(productId);
  };

  const fetchCoupons = async () => {
    const wpCouponsRepository = new WPCouponsRepository();
    try {
      const couponsResponse = await wpCouponsRepository.getCoupons();
      setCoupons(couponsResponse?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCartItems = async () => {
    setLoadingState(LoadingState.LOADING);
    try {
      const cartResponse = await cartRepository.getCartItems();

      const cartData = [];

      for (const item of cartResponse?.data.cart ?? []) {
        const product = await fetchProductInfo(+item.cart_product_id);
        cartData.push({
          ...item,
          product_detail: product?.data,
        });
      }

      const parsedCartData = parseCart(cartData);

      setCartData(parsedCartData?.cart);

      const shippingClass = cartData[0]?.product_detail?.shipping_class || "0";
      const shippingFee = parseInt(shippingClass, 10);

      setOrderPriceSummary({
        ...parsedCartData?.orderPriceSummary,
        shippingFee: shippingFee,
        totalAmount:
          parsedCartData?.orderPriceSummary.totalAmount + shippingFee,
      });

      await fetchCoupons();
      const coupanId = sessionStorage.getItem("appliedCoupan") ?? "";
      if (coupanId) {
        await applyCoupanOnRelod(cartData);
      }
      setLoadingState(LoadingState.LOADED);
    } catch (error) {
      console.log(error);
      setLoadingState(LoadingState.ERROR);
    }
  };

  const fetchUserProfile = async () => {
    const wpCustomerRepository = new WPCustomerRepository();
    try {
      const userResponse = await wpCustomerRepository.getCustomerById(
        getKEY_ID()
      );
      setProfile(userResponse?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const isPlaceOrderEnable = () => {
    if (cartData?.length == 0) {
      return false;
    }
    if (orderPriceSummary?.totalAmount == 0) {
      return false;
    }

    if (address.length == 0) {
      return false;
    }

    if (!isUserProfileComplete()) return false;

    return true;
  };

  const isUserProfileComplete = () => {
    if (
      profile?.first_name == "" ||
      profile?.last_name == "" ||
      profile?.email == "" ||
      profile?.email == `${profile?.billing.phone}@mail.com`
    ) {
      return false;
    }

    return true;
  };

  const updateProfile = async (profile: Customer) => {
    const wpCustomerRepository = new WPCustomerRepository();
    try {
      if (profile) {
        const userResponse = await wpCustomerRepository.updateCustomer(
          getKEY_ID(),
          profile
        );
        userResponse?.data &&
          alertContextValue.showAlert(
            AlertType.SUCCESS,
            "Profile Updated",
            "Your profile has been updated successfully"
          );
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(orderPriceSummary.discount.toFixed(2), "discount");
  const handlePlaceOrder = async () => {
    const wpOrderRepository = new WPOrderRepository();
    setPlaceOrderLoading(LoadingState.LOADING);
    let paymentLink = "";
    const tempAddress = address.filter((item) => item.default)[0];
    let orderDetails = {
      customer_id: getKEY_ID(),
      payment_method: orderType === OrderType.COD ? "cod" : "phonepay",
      payment_method_title:
        orderType === OrderType.COD ? "Cash on delivery" : "Phone Pay",
      set_paid: false,
      status: orderType === OrderType.COD ? "processing" : "pending",
      billing: {
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        address_1: tempAddress.address,
        address_2: "",
        city: tempAddress.city,
        state: tempAddress.state,
        postcode: tempAddress.pincode,
        country: "IN",
        email: profile?.email,
        phone: profile?.billing?.phone,
      },
      shipping: {
        first_name: profile?.first_name,
        last_name: profile?.last_name,
        address_1: tempAddress.address,
        address_2: "",
        city: tempAddress.city,
        state: tempAddress.state,
        postcode: tempAddress.pincode,
        country: "IN",
        email: profile?.email,
        phone: profile?.billing?.phone,
      },
      line_items: cartData
        ?.map((item: Cart) =>
          (item?.product_detail?.stock_quantity ?? 0) > 0
            ? {
                product_id: item.cart_product_id,
                quantity: item.cart_quantity,
              }
            : null
        )
        .filter((item) => item != null),
      coupon_lines: selectedCoupon
        ? [
            {
              code: selectedCoupon,
            },
          ]
        : [],
      shipping_lines: [
        {
          method_id: "flat_rate",
          method_title: "Flat Rate",
          total: orderPriceSummary.shippingFee.toString(),
        },
      ],
      discount_total: orderPriceSummary.discount.toFixed(2),
      shipping_total: orderPriceSummary.shippingFee.toString(),
      total: orderPriceSummary.totalAmount.toString(),
    };

    if (orderType === OrderType.ONLINE) {
      try {
        const paymentReponse = await paymentRepository.createPaymentLink({
          merchantId: "M22LNB7L2FN0Z",
          merchantTransactionId:
            "ISS" +
            Array.from({ length: Math.floor(Math.random() * 15) + 4 }, () =>
              Math.floor(Math.random() * 10)
            ).join(""),
          merchantUserId: profile?.first_name ?? "" + profile?.last_name ?? "",
          amount: Math.floor(orderPriceSummary.totalAmount ?? 0) * 100,
          redirectUrl: window.location.origin,
          redirectMode: "REDIRECT",
          callbackUrl: window.location.origin,
          mobileNumber: profile?.billing?.phone ?? "",
          paymentInstrument: {
            type: "PAY_PAGE",
          },
        });
        if (paymentReponse?.data.success) {
          paymentLink =
            paymentReponse?.data?.data?.instrumentResponse?.redirectInfo?.url;
          orderDetails = {
            ...orderDetails,
            billing: {
              ...orderDetails.billing,
              address_2: paymentReponse?.data?.data?.merchantTransactionId,
            },
            shipping: {
              ...orderDetails.shipping,
              address_2: paymentReponse?.data?.data.merchantTransactionId,
            },
          };
        }
      } catch (error) {
        console.log(error);
      }
    }

    try {
      const res = await wpOrderRepository.addOrderToWP(orderDetails);
      const shipData = res?.data;
      await pushOrderToShipRocket({
        order_id: `ISS-${shipData?.id}`,
        order_date: shipData?.date_created,
        pickup_location: "Primary",
        comment: "",
        billing_customer_name: shipData?.billing?.first_name,
        billing_last_name: shipData?.billing?.last_name,
        billing_address: shipData?.billing?.address_1,
        billing_address_2: shipData?.billing?.address_2,
        billing_city: shipData?.billing?.city,
        billing_pincode: shipData?.billing?.postcode,
        billing_state: shipData?.billing?.state,
        billing_country: "India",
        billing_email: shipData?.billing?.email,
        billing_phone: shipData?.billing?.phone,
        shipping_is_billing: true,
        order_items:
          shipData?.line_items?.map((item) => ({
            name: item?.name,
            sku: item?.sku,
            units: item?.quantity,
            selling_price: item?.price,
          })) ?? [],

        payment_method: shipData?.payment_method == "cod" ? "COD" : "Prepaid",
        shipping_charges: orderPriceSummary.shippingFee,
        giftwrap_charges: 0,
        transaction_charges: 0,
        total_discount: shipData?.discount_total,
        sub_total: +(shipData?.total ?? 0) + +(shipData?.discount_total ?? 0),
        length: 1,
        breadth: 1,
        height: 1,
        weight: 1,
      });

      orderType === OrderType.COD &&
        alertContextValue.showAlert(
          AlertType.SUCCESS,
          "Order Placed",
          "Your order has been placed successfully"
        );

      orderType === OrderType.ONLINE && window.open(paymentLink, "_blank");

      await cartRepository.emptyCart();
      clearCart();
      sessionStorage.removeItem("appliedCoupan");
      navigate("/order");
    } catch (error) {
      console.log(error);
    }

    setPlaceOrderLoading(LoadingState.LOADED);
  };

  const handleInitialRelod = async () => {
    setSelectedCoupon(undefined);
    await fetchCartItems();
    await fetchUserProfile();
  };

  useEffect(() => {
    handleInitialRelod();
  }, []);

  useEffect(() => {
    fetchCartItems();
  }, [cart]);

  return (
    <>
      {loadingState === LoadingState.LOADING && (
        <>
          <FullScreenLoader />
          <div className="m-96">&nbsp;</div>
        </>
      )}
      {loadingState === LoadingState.LOADED &&
        cartData &&
        cartData.length != 0 && (
          <div className="mt-16 px-4 lg:px-44 lg:pl-36  md:grid lg:grid-cols-[2fr_1fr] gap-2 ">
            <div className="mt-3">
              {!isUserProfileComplete() && (
                <CartProfileFormModal
                  profile={profile}
                  setProfile={setProfile}
                  updateProfile={updateProfile}
                />
              )}

              {!isMobile && (
                <CartAddress
                  profile={profile}
                  address={address}
                  setAddress={setAddress}
                />
              )}
              <div className="flex justify-between mx-2 mt-6 mb-6 items-center">
                <div className="font-bold">
                  Total items: {cartData?.length ?? "-"}
                </div>
              </div>
              {cartData?.map((item: Cart) => (
                <CartProductCard key={item.cart_id} item={item} />
              ))}
            </div>

            {/* right section */}
            <div className="py-4 px-2 sm:px-6">
              {isMobile && (
                <CartAddress
                  profile={profile}
                  address={address}
                  setAddress={setAddress}
                />
              )}

              {coupons && coupons.length > 0 && (
                <>
                  <h5 className="text-sm mt-2 font-bold">Coupons</h5>
                  {selectedCoupon ? (
                    <div className="mt-2 flex justify-between items-center">
                      <h6 className="text-lg text-rose-500 font-semibold uppercase">
                        {selectedCoupon}
                      </h6>
                      <Button
                        placeholder={undefined}
                        variant="text"
                        color="pink"
                        size="sm"
                        onClick={() => {
                          setSelectedCoupon(undefined);
                          sessionStorage.removeItem("appliedCoupan");
                          window.location.reload();
                        }}
                      >
                        X
                      </Button>
                    </div>
                  ) : (
                    <div className="mb-8 mt-3">
                      <Select placeholder={undefined} label="Select Coupon">
                        {coupons.map((coupon) => (
                          <Option
                            key={coupon.id}
                            value={coupon.code}
                            onClick={() => {
                              applyCoupan(coupon, cartData);
                            }}
                          >
                            <h6 className="text-lg text-rose-500 font-bold uppercase">
                              {coupon.code}
                            </h6>
                            {coupon.description && (
                              <p className="text-sm text-gray-500">
                                {coupon.description}
                              </p>
                            )}
                            {coupon.amount && (
                              <p className="text-sm text-gray-500">
                                {coupon.discount_type === "percent"
                                  ? `Max Discount: ${parseFloat(
                                      coupon.amount
                                    ).toFixed(0)}%`
                                  : `Max Discount: ₹${parseFloat(
                                      coupon.amount
                                    ).toFixed(0)}`}
                              </p>
                            )}
                            {coupon?.minimum_amount &&
                              +coupon?.minimum_amount != 0 && (
                                <p className="text-sm text-gray-500">
                                  Min OrderValue: ₹{coupon.minimum_amount}
                                </p>
                              )}
                            {coupon?.maximum_amount &&
                              +coupon?.maximum_amount != 0 && (
                                <p className="text-sm text-gray-500">
                                  Max OrderValue: ₹{coupon.maximum_amount}
                                </p>
                              )}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  )}

                  <div className="border-b  mt-4 mb-3 border-gray-500 w-full"></div>
                </>
              )}

              <h5 className="text-sm mt-2 font-bold">
                PRICE DETAILS <span>({cartData?.length ?? "-"} items)</span>
              </h5>
              <div className="text-[#282C3F] text-sm">
                <div className="mb-2 mt-5">
                  <span className="">Total MRP</span>
                  <span className=" float-right">
                    ₹ {orderPriceSummary?.subTotal}
                  </span>
                </div>
                <div className=" mb-2">
                  <span className="">Discount on MRP</span>
                  <span className="float-right text-green-400">
                    ₹ {orderPriceSummary?.discount.toFixed(2)}
                  </span>
                </div>
                <div className=" mb-2">
                  <span className="">Delivery Fee</span>
                  <span className=" float-right">
                    ₹ {orderPriceSummary?.shippingFee}
                  </span>
                </div>
              </div>

              <div className="border-b  mt-4 mb-3 border-gray-500 w-full"></div>

              <div className="font-semibold">
                <span>Total Amount</span>
                <span className="float-right">
                  ₹ {orderPriceSummary?.totalAmount}
                </span>
              </div>

              <div className="grid grid-cols-1 gap-1 mt-6">
                <h5 className="text-sm mt-2 font-bold">
                  CHOOSE PAYMENT METHOD
                </h5>
                {[
                  {
                    label: "Cash on Delivery",
                    value: OrderType.COD,
                  },
                  {
                    label: "Pay Online (UPI, Card, Netbanking etc)",
                    value: OrderType.ONLINE,
                  },
                ].map((item) => (
                  <Radio
                    key={item.value}
                    name="type"
                    checked={orderType === item.value}
                    label={item.label}
                    value={item.value}
                    onClick={() => setOrderType(item?.value)}
                    crossOrigin={undefined}
                  />
                ))}
              </div>

              <div className="text-center mt-2">
                <Button
                  placeholder={undefined}
                  onClick={handlePlaceOrder}
                  disabled={!isPlaceOrderEnable()}
                  size="lg"
                  className="mt-4 flex items-center justify-center"
                  fullWidth={true}
                >
                  {placeOrderLoading === LoadingState.LOADING && (
                    <Spinner color="orange" />
                  )}

                  {placeOrderLoading === LoadingState.LOADED &&
                    orderType === OrderType.COD &&
                    "Place Order"}
                  {(placeOrderLoading === LoadingState.LOADED && orderType) ===
                    OrderType.ONLINE && "Pay Now & Place Order"}
                </Button>
              </div>
            </div>
          </div>
        )}

      {loadingState === LoadingState.LOADED &&
        (cartData == undefined || cartData?.length == 0) && (
          <div className="my-16">
            <h2 className="text-3xl uppercase font-bold text-center">CART</h2>
            <div className="mt-4">
              <img
                className="mx-auto"
                src="https://cdni.iconscout.com/illustration/premium/thumb/empty-cart-5521508-4610092.png"
                alt="empty cart"
              />
            </div>
          </div>
        )}
    </>
  );
};
