import {
  Card,
  CardHeader,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { WPCategory } from "../models/responses/WPCategoriesResponse";

export function CategoryCard({category} : {category:WPCategory}) {
  return (
    <Link to={`/products/${category?.slug}`}>
      <Card
        placeholder={undefined}
        shadow={false}
        className="relative rounded-none cursor-pointer grid h-[24rem] w-full sm:max-w-[20rem] items-end justify-center overflow-hidden text-center"
      >
        <CardHeader
          placeholder={undefined}
          floated={false}
          shadow={false}
          color="transparent"
          className={
            "absolute inset-0 m-0 h-full w-full rounded-none bg-cover bg-center"
          }
          style={{
            backgroundImage: "url(" + category?.image?.src + ")",
          }}
        >
          <div className="to-bg-black-10 absolute inset-0 h-full w-full bg-gradient-to-t from-black/80 via-black/50" />
        </CardHeader>
        <CardBody
          placeholder={undefined}
          className="relative py-14 px-6 md:px-12"
        >
          <Typography
            placeholder={undefined}
            variant="h4"
            color="white"
            className="font-medium leading-[1.5]"
          >
            {category?.name ?? "Category Name"}
          </Typography>
        </CardBody>
      </Card>
    </Link>
  );
}
