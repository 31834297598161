
import { WPGetMenuByIdRequest } from "../../models/requests";
import { WPMenu } from "../../models/responses/WPMenuResponse";
import WPAbstractLogicLayer from "./WPAbstractLogicLayer";

export class WPMenuRepository extends WPAbstractLogicLayer {
  async getMenuByID(parameter?: number) {
    const request = new WPGetMenuByIdRequest(parameter);
    return this.performRequest<WPMenu>(request);
  }
}
