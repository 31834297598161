import { GetCMSRequest } from "../models/requests";
import AbstractLogicLayer from "./AbstractLogicLayer";

export enum CMSContentType {
  HOME_BANNER = "homeBanner",
  FEATURED = "featured",
  VIRTUAL_STORE = "virtualStore"
}

export class CMSRepository extends AbstractLogicLayer {
  async getCMSContent(contentType: CMSContentType) {
    const request = new GetCMSRequest(contentType);
    return this.performRequest<any>(request);
  }
}
