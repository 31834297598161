import {
  CreatePhonePayPaymentLinkRequest,
  PhonePayTransaction,
} from "../models/requests/CreatePhonePayPaymentLinkRequest";
import { PhonePayPaymentResponse } from "../models/responses/PaymentResponse";
import AbstractLogicLayer from "./AbstractLogicLayer";

export class PhonePayPaymentRepository extends AbstractLogicLayer {
  async createPaymentLink(data: PhonePayTransaction) {
    const request = new CreatePhonePayPaymentLinkRequest(data);
    return this.performRequest<PhonePayPaymentResponse>(request);
  }
}

export default PhonePayPaymentRepository;
