import { useEffect, useState } from "react";
import WPProductsRepository from "../../business/WP/WPProductsRepository";
import Skeleton from "react-loading-skeleton";
import { Product } from "../../models/responses/WPResponse";
import { ProductCard } from "../../components";

function ProductCardForWishlist({ productCode }: { productCode: string }) {
  const productRepository = new WPProductsRepository();

  const [productInfo, setProductInfo] = useState<Product | any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchProductInfo = async () => {
    setIsLoading(true);
    try {
      const productResponse = await productRepository.getProductById(
        +productCode
      );
      setProductInfo(productResponse?.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setTimeout(() => {
      fetchProductInfo();
    }, Math.floor(Math.random() * (1000 - 500 + 1)) + 500);
  }, []);

  return isLoading ? (
    <div className="w-[20.5rem]  h-[28rem] px-1.5">
      <Skeleton width="100%" duration={0.8} height="100%" />
    </div>
  ) : (
    productInfo && <ProductCard product={productInfo} />
  );
}

export default ProductCardForWishlist;
