import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import WPProductsRepository from "../../business/WP/WPProductsRepository";
import { Product } from "../../models/responses/WPProductResponse";
import { ProductCard } from "../../components";

interface HomePLPProps {
  products: number[];
}

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
}

function RelatedProduct(props: HomePLPProps) {
  const { products } = props;

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING
  );

  const [homePlp, setHomePlp] = useState<Product[] | undefined | null>([]);

  const tempProducts = useRef<Product[]>([]);

  const wpProductsRepository = new WPProductsRepository();

  const fetchAllProducts = async () => {
    for (let i = 0; i < products?.length; i++) {
      const product = await wpProductsRepository.getProductById(products[i]);
      product?.data && tempProducts.current.push(product?.data);
    }
    console.log("temp products", tempProducts.current);

    setHomePlp(tempProducts.current);
  };

  const fetchHomePLP = async () => {
    setLoadingState(LoadingState.LOADING);
    try {
      await fetchAllProducts();
      setLoadingState(LoadingState.LOADED);
    } catch (error) {
      console.log(error);
      setLoadingState(LoadingState.FAILED);
    }
  };

  useEffect(() => {
    fetchHomePLP();
  }, []);

  useEffect(() => {
    console.log("home plp", homePlp);
  }, [homePlp]);

  return (
    <div className="mx-2 lg:mx-10 xl:mx-12 gap-y-4 grid  sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
      {loadingState === LoadingState.LOADING &&
        [1, 2, 3, 4].map((item) => (
          <div key={item} className="w-full max-w-[26rem] h-[32rem] px-1.5">
            <Skeleton width="100%" duration={0.8} height="100%" />
          </div>
        ))}

      {loadingState === LoadingState.LOADED &&
        homePlp &&
        homePlp?.map((product: Product) => (
          <div key={product.id} className="mx-auto">
            <ProductCard product={product} />
          </div>
        ))}
    </div>
  );
}

export default RelatedProduct;
