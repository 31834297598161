import { useContext } from "react";
import { WishlistContext, WishlistItem } from "../layout";

export function useWishlist() {
  const wishlistValue = useContext(WishlistContext);

  const addToWishlist = (prodId: string, wishId: string) => {
    wishlistValue.updateWishlist([
      ...(wishlistValue?.wishlist ?? []),
      {
        productId: +prodId,
        wishId: +wishId,
      },
    ]);
  };
    
    const removeFromWishlist = (prodId: string) => {
        wishlistValue.updateWishlist(wishlistValue?.wishlist?.filter((item: WishlistItem) => item.productId !== +prodId));
    }

  return {
    wishlist: wishlistValue.wishlist,
    addToWishlist,
    removeFromWishlist,
  };
}
