import { useLocation, useParams } from "react-router-dom";
import { Breadcrumb, ProductListing, SearchBar } from "../components";

interface ProductListingProps {
  isSearch?: boolean;
}

export function ProductListingPage(props: ProductListingProps) {
  const { isSearch } = props;

  const location = useLocation();

  let { category } = useParams();

  return (
    <>
      <div className="lg:mx-14 xl:mx-16 2xl:mx-48 my-12">
        <div className="sm:hidden mb-8 p-2">
          <SearchBar />
        </div>

        {location.pathname != "/search" && (
          <>
            <Breadcrumb breadcrumb={["products", category ?? ""]} />
            <ProductListing category={category} isSearch={isSearch} />
          </>
        )}

        {location.pathname == "/search" && (
          <div className="mt-16 mb-24">
            <img
              src="https://cdni.iconscout.com/illustration/premium/thumb/search-engine-optimization-3678968-3092479.png"
              alt="login required"
              className="mx-auto"
            />
          </div>
        )}
      </div>
    </>
  );
}
