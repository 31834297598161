import { useContext } from "react";
import { ModelContext } from "../App";

export const useOpenLoginDialog = () => {
  const modelContextValue = useContext(ModelContext);

  const openLoginDialog = () => {
    modelContextValue?.authDialog?.setOpen(
      !modelContextValue?.authDialog?.open
    );
  };

  return openLoginDialog;
};
