import {useContext, useEffect,useState} from "react";
import Skeleton from "react-loading-skeleton";
import { AlertContext } from "../App";
import { Customer } from "../models/responses/WPResponse";
import { UserAddress } from "../models/responses/UserResponse";
import { getKEY_ID } from "../services";
import { AlertType, CartProfileFormModal } from "../components";
import { CartAddress } from "./Cart";
import { WPCustomerRepository } from "../business";

export const ProfilePage = () => {
  const alertContextValue = useContext(AlertContext);

  const [profile, setProfile] = useState<Customer>();
  const [address, setAddress] = useState<UserAddress[] | []>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchUserProfile = async () => {
    setIsLoading(true);
    const wpCustomerRepository = new WPCustomerRepository();
    try {
      const userResponse = await wpCustomerRepository.getCustomerById(
        getKEY_ID()
      );
      setProfile(userResponse?.data);
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  const updateProfile = async (profile: Customer) => {
    setIsLoading(true);
    const wpCustomerRepository = new WPCustomerRepository();
    try {
      if (profile) {
        const userResponse = await wpCustomerRepository.updateCustomer(
          getKEY_ID(),
          profile
        );
        userResponse?.data &&
          alertContextValue.showAlert(
            AlertType.SUCCESS,
            "Profile Updated",
            "Your profile has been updated successfully"
          );
      }
    } catch (error) {
      console.log(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  return (
    <div className="p-3 lg:mx-14 xl:mx-16 2xl:mx-48 my-16 flex flex-col justify-center items-center">
      <h2 className="text-3xl uppercase font-bold text-center">MY Profile</h2>
      <div className="my-6 w-full sm:w-[540px] p-2 mt-12">
        {isLoading &&
          [1, 2, 3, 4].map((item) => (
            <div key={item} className="w-[full] my-2 h-[4rem] px-1.5">
              <Skeleton width="100%" duration={0.8} height="100%" />
            </div>
          ))}

        {!isLoading && (
          <>
            <CartProfileFormModal
              hideBg={true}
              profile={profile}
              setProfile={setProfile}
              updateProfile={updateProfile}
            />
            {profile?.first_name && (
              <div className="mb-5">
                <h4 className="text-sm text-gray-600">Name</h4>
                <p className="font-medium text-xl mt-1">
                  {profile?.first_name + " " + profile?.last_name ?? "N/A"}
                </p>
              </div>
            )}
            {profile?.billing?.phone && (
              <div className="mb-5">
                <h4 className="text-sm text-gray-600">Mobile</h4>
                <p className="font-medium text-xl mt-1">
                  {profile?.billing?.phone ?? "N/A"}
                </p>
              </div>
            )}
            {profile?.email &&
              !(profile?.email == `${profile?.billing?.phone}@mail.com`) && (
                <div className="mb-5">
                  <h4 className="text-sm text-gray-600">Email</h4>
                  <p className="font-medium text-xl mt-1">
                    {profile?.email ?? "N/A"}
                  </p>
                </div>
              )}
          </>
        )}
        <div className="mb-5">
          <h4 className="text-sm text-gray-600">Address</h4>

          <CartAddress
            profile={profile}
            address={address}
            setAddress={setAddress}
          />
        </div>
      </div>
    </div>
  );
};

