
import { AddToWishlistRequest, GetWishlistRequest, RemoveFromWishlist } from "../models/requests";
import { AddToWishlistResponse, WishlistResponse } from "../models/responses/common";
import AbstractLogicLayer from "./AbstractLogicLayer";

export enum WishlistQuery {
  ALL = "all",
  DEFAULT = " ",
}

export class WishlistRepository extends AbstractLogicLayer {
  async fetchWishlistItems(
    pageSize: number,
    currentPage: number,
    query: WishlistQuery
  ) {
    const request = new GetWishlistRequest(pageSize, currentPage, query);
    return this.performRequest<WishlistResponse>(request);
  }

  async addToWishlist(
    prodId: number
  ) {
    const request = new AddToWishlistRequest(prodId);
    return this.performRequest<AddToWishlistResponse>(request);
  }

  async removeFromWishlist(
    wishId:number
  ) {
    const request = new RemoveFromWishlist(wishId);
    return this.performRequest<number>(request);
  }
}

export default WishlistRepository;
