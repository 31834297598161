import { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { classNames } from "../../services";
import { Link } from "react-router-dom";
import { getKEY_ID } from "../../services/user";
import OrderTrack from "./OrderTrack";
import { LineItems, SHOrder } from "../../models/responses/WPResponse";
import { WPOrderRepository } from "../../business";

function OrderPage() {
  const orderRepository = new WPOrderRepository();

  const [isLoading, setIsLoading] = useState(false);

  const [orders, setOrders] = useState<SHOrder[] | undefined | null | []>([]);
  // console.log(orders, "orders");

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const orderResponse = await orderRepository.getOrders({
        customer: getKEY_ID(),
      });
      setOrders(orderResponse?.data);
    } catch (e) {
      console.log(e);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <div className="p-3 lg:mx-14 xl:mx-16 2xl:mx-48 my-12">
      <h2 className="text-3xl uppercase font-bold text-center">ORDERS</h2>
      {orders && orders?.length > 0 ? (
        <div
          className={classNames(
            "grid mt-6 gap-y-5 grid-cols-1 px-2 sm:px-0  lg:grid-cols-2",
            isLoading ? "" : "gap-x-5"
          )}
        >
          {isLoading &&
            [1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
              <div key={item} className="w-full h-[16rem] px-1.5">
                <Skeleton width="100%" duration={0.8} height="100%" />
              </div>
            ))}

          {!isLoading &&
            orders.map((item: SHOrder) => {
              console.log(item.discount_total, "discountorder");
              return (
                <div className="w-full pt-4 pl-4 pr-6 pb-8 border">
                  <div className="flex justify-between items-center">
                    <h2 className="text-md font-medium text-gray-500">
                      Order no.{" "}
                      <span className="text-md font-bold text-gray-700">
                        ISS-{item.id}
                      </span>
                    </h2>
                    <h2 className="text-sm font-medium text-gray-500">
                      {item?.date_created}
                    </h2>
                  </div>
                  <div className="mt-4">
                    <h2 className=" text-md font-medium text-gray-500">
                      Order items{" "}
                    </h2>

                    <div className="mt-2 w-full overflow-x-scroll flex justify-start items-center gap-2">
                      {item?.line_items?.map((product: LineItems) => (
                        <Link to={"/product/" + product?.product_id}>
                          <div className="p-0">
                            <div className="w-[96px] h-[96px] rounded">
                              <img
                                src={
                                  product?.image?.src ??
                                  "https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg"
                                }
                                className="w-full h-full object-cover rounded"
                                alt=""
                              />
                            </div>
                            <div className="relative text-sm bottom-9 inline-block rounded-full left-2 bg-white p-1 px-1.5 font-medium">
                              x{product?.quantity}
                            </div>
                          </div>
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="mt-4 flex flex-col gap-2 sm:gap-1 justify-start items-start sm:flex-row sm:justify-between sm:items-center">
                    <div className="flex w-full sm:block sm:w-auto justify-between items-center text-md font-medium text-gray-500">
                      Subtotal.{" "}
                      <span className="text-md font-bold text-gray-700">
                        ₹{" "}
                        {(
                          parseFloat(item?.discount_total) +
                          parseFloat(item?.total) -
                          parseFloat(item?.shipping_total)
                        ).toFixed(2)}
                      </span>
                    </div>
                    <div className="flex w-full sm:block sm:w-auto justify-between items-center text-md font-medium text-gray-500">
                      Discount.{" "}
                      <span className="text-md font-bold text-gray-700">
                        ₹ {item?.discount_total}
                      </span>
                    </div>
                    <div className="flex w-full sm:block sm:w-auto justify-between items-center text-md font-medium text-gray-500">
                      Delivery Fee.{" "}
                      <span className="text-md font-bold text-gray-700">
                        ₹ {item?.shipping_total}
                      </span>
                    </div>
                    <div className=" sm:hidden border-b  mt-4 mb-3 border-gray-500 w-full"></div>
                    <div className="flex w-full sm:block sm:w-auto justify-between items-center text-md font-medium text-gray-500">
                      <span>Total. </span>

                      <span className="text-2xl font-bold text-green-700">
                        ₹ {item?.total}
                      </span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <h2 className=" text-md font-medium text-gray-500">
                      Delivery Details{" "}
                    </h2>
                    <p className="mt-0.5 text-sm font-medium text-gray-700">
                      {item.billing.first_name + " " + item.billing.last_name},{" "}
                      {item.billing.phone}
                    </p>
                    <p className="mt-0.5 text-sm font-medium text-gray-700">
                      {item.shipping.address_1}
                      ,&nbsp;
                      {item.shipping.city},&nbsp;
                      {item.shipping.state},&nbsp;
                      {item.shipping.postcode}&nbsp;
                    </p>
                    {item.payment_method_title && (
                      <p className="text-sm mt-0.5 text-rose-500 font-medium">
                        ({item.payment_method_title})
                      </p>
                    )}
                  </div>
                  <div className="mt-4 w-full p-4 pb-8">
                    <OrderTrack
                      orderType={item?.payment_method}
                      orderStatus={item?.status}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className="my-2">
          <Link to="/">
            <img
              width="40%"
              className="mx-auto"
              src="https://kwabey.com/shared/img/kwabey/initials/no_order_found_1.jpg"
              alt="no order found"
            />
          </Link>
        </div>
      )}
    </div>
  );
}

export default OrderPage;
