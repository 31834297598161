import { useContext } from "react";
import { CategoryCarousel } from "./CategoryCarousel";
import { CategoriesContext } from "../layout";
import { SkeletonCarousel } from "./SkeletonCrousel";

export function ExploreCategories() {
  const categoriesData = useContext(CategoriesContext);

  return (
    <div>
      <h1 className="text-center text-2xl font-bold mb-8">
        Explore Categories
      </h1>

      {categoriesData?.categories && categoriesData?.categories?.length > 0 ? (
        <CategoryCarousel categories={categoriesData?.categories} />
      ) : (
        <SkeletonCarousel />
      )}
    </div>
  );
}
