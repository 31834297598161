import { CMSContentType } from "../../business";
import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";

export class GetCMSRequest implements Requester {
  private contentType:CMSContentType;

  constructor(contentType: CMSContentType) {
      this.contentType = contentType;
  }

  path(): string {
    return ApiPath.CMS;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      query: this.contentType,
    };
  }

  body(): any {
    return null;
  }
}
