export interface AddUserRequestData {
  user_username: string;
  user_phone: string;
  user_id: number;
}

export interface UpdateUserRequestData {
  user_username: string;
  user_phone: string;
  user_id: number;
}

export interface UserAddress {
  id: string;
  pincode: string;
  state: string;
  address: string;
  city: string;
  type: AddressType;
  default: boolean;
}

export enum AddressType{
  HOME = "home",
  OFFICE = "office",
  OTHER = "other"
}

export interface UserData {
  user_username: string;
  user_phone: string;
  user_id: number;
}
