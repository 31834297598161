import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { CartRepository } from "../business/CartRepository";
const filledColor = "black";
const currentColor = "grey";

export function BottomNav() {
  const location = useLocation();
  const path = location.pathname;

  const cartRepo = new CartRepository();

  // Assuming you're calling this within an async function

  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  // console.log(cartItems, "cartitems");
  useEffect(() => {
    fetchCartItems();
  }, [totalQuantity, cartItems,cartRepo]);

  useEffect(() => {
    calculateTotalQuantity(cartItems);
  }, [totalQuantity, cartItems,cartRepo]);

  const fetchCartItems = async () => {
    try {
      const cartResponse = await cartRepo.getCartItems();
      const items: any = cartResponse?.data?.cart;
      setCartItems(items);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const calculateTotalQuantity = (items: any) => {
    const total = items?.reduce(
      (acc: any, item: any) => acc + parseInt(item?.cart_quantity),
      0
    );
    setTotalQuantity(total);
  };

  return (
    <>
      <div className="p-6">&nbsp;</div>
      <div className="border-t   flex bg-white w-full z-30 fixed p-4 px-5 bottom-0 justify-between items-center sm:hidden">
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={path == "/" ? filledColor : currentColor}
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
            />
          </svg>
        </Link>

        <Link to="/search">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={path.includes("/search") ? filledColor : currentColor}
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </Link>
        <Link to={"/cart"} className="relative">
          <div className="absolute right-[-3px] top-[-7px] rounded-full bg-white shadow-2xl px-1  text-center justify-center text-xs">
            {totalQuantity && totalQuantity}
          </div>
          <Link to={"/cart"}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke={path.includes("/cart") ? filledColor : currentColor}
              className="w-7 h-7"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
              />
            </svg>
          </Link>
        </Link>
        <Link to="/order">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={path.includes("/order") ? filledColor : currentColor}
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
        </Link>

        <Link to="/wishlist">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke={path.includes("/wishlist") ? filledColor : currentColor}
            className="w-7 h-7"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
            />
          </svg>
        </Link>
      </div>
    </>
  );
}
