import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";
import { ProductFilter, ProductListingFilter } from "./ProductListingFilter";
import { WPCategoryRepository, WPProductsRepository } from "../../business";
import { Product } from "../../models/responses/WPProductResponse";
import { Pagination } from "../Pagination";
import { ProductCard } from "../ProductCard";
import { setSeoData } from "../../services";
import { MenuDrawer } from "../MenuDrawer";
import FilterDrawer from "../FilterDrawer";
import { useMediaQuery } from "react-responsive";
interface ProductListingProps {
  category: string | undefined | null;
  isSearch?: boolean;
}

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
}

const PAGE_SIZE = 9;

export function ProductListing(props: ProductListingProps) {
  const { category, isSearch } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 642px)" });
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING
  );

  const [plp, setPlp] = useState<Product[] | undefined | null>(null);

  const [productFilter, setProductFilter] = useState<ProductFilter>({
    code: "date",
    name: "Newly Arrived",
    asc: false,
  });

  const [productPriceRange, setProductPriceRange] = useState<{
    min: number;
    max: number;
  }>({
    min: 0,
    max: 0,
  });

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalProducts, setTotalProducts] = useState<number>(0);

  const wpCategoryRepository = new WPCategoryRepository();
  const wpProductRepository = new WPProductsRepository();

  const fetchPLP = async () => {
    setLoadingState(LoadingState.LOADING);
    try {
      if (category) {
        if (isSearch) {
          let tempParams: any = {
            search: category,
            per_page: PAGE_SIZE,
            page: currentPage,
            orderby: productFilter?.code,
            order: productFilter.asc ? "asc" : "desc",
          };

          if (productPriceRange.min > 0) {
            tempParams.min_price = productPriceRange.min;
          }

          if (productPriceRange.max > 0) {
            tempParams.max_price = productPriceRange.max;
          }

          const productResponse = await wpProductRepository.getProducts(
            tempParams
          );

          if (productResponse?.data.length !== 0) {
            // console.log(productResponse);
            setTotalPages(productResponse?.headers["x-wp-totalpages"]);
            setTotalProducts(productResponse?.headers["x-wp-total"]);
            setPlp(productResponse?.data);
          } else {
            setPlp([]);
            currentPage > 2 && setCurrentPage(currentPage - 1);
          }
        } else {
          const categoryResponse = await wpCategoryRepository.getCategories({
            slug: category,
          });

          let tempParams: any = {
            category: categoryResponse?.data[0].id ?? 0,
            per_page: PAGE_SIZE,
            page: currentPage,
            orderby: productFilter?.code,
            order: productFilter.asc ? "asc" : "desc",
          };

          if (productPriceRange.min > 0) {
            tempParams.min_price = productPriceRange.min;
          }

          if (productPriceRange.max > 0) {
            tempParams.max_price = productPriceRange.max;
          }

          const productResponse = await wpProductRepository.getProducts(
            tempParams
          );

          if (productResponse?.data.length !== 0) {
            setTotalPages(productResponse?.headers["x-wp-totalpages"]);
            setTotalProducts(productResponse?.headers["x-wp-total"]);
            setPlp(productResponse?.data);
          } else {
            setPlp([]);

            currentPage > 2 && setCurrentPage(currentPage - 1);
          }
        }
      } else {
        let tempParams: any = {
          per_page: PAGE_SIZE,
          page: currentPage,
          orderby: productFilter?.code,
          order: productFilter.asc ? "asc" : "desc",
        };

        if (productPriceRange.min > 0) {
          tempParams.min_price = productPriceRange.min;
        }

        if (productPriceRange.max > 0) {
          tempParams.max_price = productPriceRange.max;
        }

        const productResponse = await wpProductRepository.getProducts(
          tempParams
        );

        if (productResponse?.data.length !== 0) {
          setTotalPages(productResponse?.headers["x-wp-totalpages"]);
          setTotalProducts(productResponse?.headers["x-wp-total"]);
          setPlp(productResponse?.data);
        } else {
          setPlp([]);
          currentPage > 2 && setCurrentPage(currentPage - 1);
        }
      }
      setLoadingState(LoadingState.LOADED);
    } catch (error) {
      console.log(error);
      setLoadingState(LoadingState.FAILED);
    }
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchPLP();
  }, [productFilter]);

  useEffect(() => {
    fetchPLP();
  }, [category, currentPage]);

  useEffect(() => {
    setSeoData(category, category);
  }, [category]);

  useEffect(() => {
    fetchPLP();
  }, [productPriceRange]);

  const [productFilterMobile, setProductFilterMobile] = useState(false);
  return (
    <>
      <button
        onClick={() => {
          setProductFilterMobile((prev) => !prev);
        }}
        className="md:hidden mt-4"
      >
        <div className="flex gap-2 px-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M10.5 6h9.75M10.5 6a1.5 1.5 0 1 1-3 0m3 0a1.5 1.5 0 1 0-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m-9.75 0h9.75"
            />
          </svg>
          Filters
        </div>
      </button>
      {productFilterMobile && (
        <div>
          <FilterDrawer
            setProductFilterMobile={setProductFilterMobile}
            productFilterMobile={productFilterMobile}
            productFilter={productFilter}
            setProductFilter={setProductFilter}
            productPriceRange={productPriceRange}
            setProductPriceRange={setProductPriceRange}
            loadingState={loadingState}
          />
        </div>
      )}
      <div className="mt-6  grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4">
        {loadingState === LoadingState.LOADED && (
          <div className="hidden md:block">
            <ProductListingFilter
              productFilter={productFilter}
              setProductFilter={setProductFilter}
              productPriceRange={productPriceRange}
              setProductPriceRange={setProductPriceRange}
            />
          </div>
        )}

        {loadingState === LoadingState.LOADING && (
          <div className="hidden md:block mr-1.5">
            <Skeleton width="100%" duration={0.8} height="100%" />
          </div>
        )}

        {loadingState === LoadingState.LOADING && (
          <div className="grid gap-y-3 grid-cols-1 sm:grid-cols-2 md:col-span-2 md:grid-cols-1 lg:grid-cols-2 xl:col-span-3 xl:grid-cols-3">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
              <div key={item} className="w-full max-w-[26rem] h-[32rem] px-1.5">
                <Skeleton width="100%" duration={0.8} height="100%" />
              </div>
            ))}
          </div>
        )}

        {!isMobile &&
          loadingState === LoadingState.LOADED &&
          plp &&
          plp?.length > 0 && (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:col-span-2 md:grid-cols-1 lg:grid-cols-2 xl:col-span-3 xl:grid-cols-3">
              {plp?.map((item: Product, index: any) => (
                <div key={`${item.id} ${index}`} className="px-1  mx-auto ">
                  <ProductCard product={item} />
                </div>
              ))}
            </div>
          )}
        {isMobile &&
          loadingState === LoadingState.LOADED &&
          plp &&
          plp?.length > 0 && (
            <div className="grid grid-cols-2 ">
              {plp?.map((item: Product, index: any) => (
                <div key={`${item.id} ${index}`} className="">
                  <ProductCard product={item} />
                </div>
              ))}
            </div>
          )}

        {plp?.length === 0 && loadingState === LoadingState.LOADED && (
          <div className="mt-12 col-span-1 md:col-span-2 xl:col-span-3">
            <img
              className="mx-auto w-100 sm:w-[480px]"
              src="https://res.cloudinary.com/joinventures/raw/upload/propshop24/assets/images/no_product_1666.png"
              alt="no product found"
            />
            <h1 className="text-center mt-6 text-xl font-medium	 text-gray-600">
              No Products Found...
            </h1>
          </div>
        )}

        {loadingState === LoadingState.LOADED &&
          plp &&
          plp?.length > 0 &&
          totalPages > 1 && (
            <div className="mt-6 flex justify-end align-center col-span-1 sm:col-span-2 md:col-span-2 lg:col-span-4">
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
      </div>
    </>
  );
}
