import { IconButton, Typography } from "@material-tailwind/react";
import { FaMinus, FaPlus } from "react-icons/fa";
import { useRef, useState, useContext } from "react";
import { AlertContext } from "../../App";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { Cart } from "../../models/responses/CartResponse";
import { AlertType } from "../../components";
import { CartRepository } from "../../business";
import { useCart } from "../../hooks";

interface CartProductCardProps {
  item: Cart;
}

export function CartProductCard(props: CartProductCardProps) {
  const { item } = props;

  const { decreaseQuantity, increaseQuantity, removeFromCart } = useCart();

  const availableUnits = item?.product_detail?.stock_quantity ?? 0;

  const cartRepository = new CartRepository();

  const alertContextValue = useContext(AlertContext);

  const quantity = useRef<number>(+item.cart_quantity);
  const [showProductQuanitity, setShowProductQuantity] = useState<number>(
    +item.cart_quantity
  );

  const handleIncreaseQuantity = async () => {
    if (quantity.current < 10 && quantity.current < availableUnits) {
      quantity.current++;
      setShowProductQuantity(quantity.current);
      await updateQuantity();
      increaseQuantity(item?.cart_product_id);
    } else {
      alertContextValue.showAlert(
        AlertType.INFO,
        "Max quantity reached...",
        null
      );
    }
  };

  const handleDecreaseQuantity = async () => {
    if (quantity.current > 1) {
      quantity.current--;
      setShowProductQuantity(quantity.current);
      await updateQuantity();
      decreaseQuantity(item?.cart_product_id);
    } else {
      alertContextValue.showAlert(
        AlertType.INFO,
        "Min quantity reached...",
        null
      );
    }
  };

  const updateQuantity = async () => {
    try {
      await cartRepository.updateQuantity(+item.cart_id, quantity.current);
    } catch (error) {
      console.log(error);
    }
  };

  const removeItemFromCart = async () => {
    const confirmation = window.confirm(
      `Are you sure you want to remove this item ?`
    );

    if (!confirmation) {
      return;
    }

    try {
      const cartId = +item.cart_id;

      if (cartId) {
        await cartRepository.removeFromCart(cartId);

        removeFromCart(item?.cart_product_id);
        alertContextValue.showAlert(
          AlertType.ERROR,
          "Removed from cart...",
          null
        );
      } else {
        alert("dsadasdad");
        alertContextValue.showAlert(
          AlertType.ERROR,
          "Something went wrong...",
          null
        );
      }
    } catch (error) {
      console.log(error);
      alertContextValue.showAlert(
        AlertType.ERROR,
        "Something went wrong...",
        null
      );
    }
  };

  return (
    <div key={item.cart_id} className=" relative mb-4">
      <div className=" p-2 flex gap-5">
        <div>
          <img
            className="w-24 sm:w-48  md:w-36"
            src={
              item?.product_detail?.images[0].src ??
              "https://t4.ftcdn.net/jpg/04/99/93/31/360_F_499933117_ZAUBfv3P1HEOsZDrnkbNCt4jc3AodArl.jpg"
            }
            alt=""
          />
        </div>
        <div className="flex justify-between w-[100%]">
          <div className="flex flex-col">
            <Link to={"/product/" + item?.product_detail?.slug}>
              <Typography
                placeholder={undefined}
                variant="h5"
                color="blue-gray"
                className="font-medium "
              >
                {item?.product_detail?.name ?? "Product Name"}
              </Typography>
            </Link>
            <Typography
              placeholder={undefined}
              className="font-semibold text-left mt-2 text-xl"
              color="green"
            >
              ₹{item.prodTotalPrice}{" "}
              {(item?.product_detail?.stock_quantity ?? 0) > 0 && (
                <span className="text-sm text-gray-500">
                  (₹{item?.product_detail?.price ?? "0"} x{" "}
                  {showProductQuanitity})
                </span>
              )}
            </Typography>

            <p className="hidden sm:block text-sm mt-3">
              {ReactHtmlParser(item?.product_detail?.short_description ?? "")}
            </p>
          </div>
          <div className="self-end flex justify-start gap-2 items-center mr-2 mb-2">
            {availableUnits > 0 ? (
              <>
                <IconButton
                  placeholder={undefined}
                  onClick={handleDecreaseQuantity}
                  variant="outlined"
                  size="sm"
                  style={{
                    width: "26px",
                    height: "26px",
                    borderRadius: "4px",
                  }}
                >
                  <FaMinus />
                </IconButton>
                <h2 className="px-1.5 text-xl font-normal">
                  {showProductQuanitity}
                </h2>
                <IconButton
                  placeholder={undefined}
                  style={{
                    width: "26px",
                    height: "26px",
                    borderRadius: "4px",
                  }}
                  onClick={handleIncreaseQuantity}
                  size="sm"
                >
                  <FaPlus />
                </IconButton>
              </>
            ) : (
              <h5 className="p-1.5 px-2.5 uppercase rounded text-rose-500 font-bold text-md">
                Out of stock
              </h5>
            )}
          </div>
        </div>
      </div>

      <div
        onClick={removeItemFromCart}
        className="absolute top-2 right-3 overflow-hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 cursor-pointer "
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  );
}
