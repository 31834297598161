import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";
import { AddUserRequestData } from "../responses/UserResponse";

export class AddUserRequest implements Requester {
  private userData: AddUserRequestData;

  constructor(userData: AddUserRequestData) {
    this.userData = userData;
  }

  path(): string {
    return ApiPath.USER;
  }

  method(): RequestType {
    return RequestType.POST;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return this.userData;
  }
}

