import { useContext, useEffect} from "react";
import { AlertContext } from "../../App";
import { Customer } from "../../models/responses/WPResponse";
import { UserAddress } from "../../models/responses/UserResponse";
import { AddressFormModal, AlertType } from "../../components";
import { ProfileRepository } from "../../business";

export function CartAddress({ address,profile, setAddress } : {profile:Customer | undefined, address: UserAddress[] | [], setAddress: any}) {
  const alertContextValue = useContext(AlertContext);
  const profileRepository = new ProfileRepository();

  const fetchAddress = async () => {
    try {
      const addressResponse = await profileRepository.getAddress();
      const data = addressResponse?.data?.toString() || "[]";
      setAddress(JSON.parse(data));
    } catch (error) {
      console.log(error);
    }
  };

  const updateAddress = async (address: UserAddress[] | []) => {
    if (address.length > 0) {
      try {
        const addressResponse = await profileRepository.updateAddress(address);
        console.log(addressResponse);

        alertContextValue.showAlert(
          AlertType.SUCCESS,
          "Address Book Updated...",
          "Your address book has been updated."
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchAddress();
  }, []);

  useEffect(() => {
    updateAddress(address);
  }, [address]);

  return (
    <div className="w-full py-2 flex items-center justify-between">
      <div>
        {Array.isArray(address) && address?.length > 0 ? (
          address
            ?.filter((item: UserAddress) => item.default === true)
            ?.map((item: UserAddress) => (
              <div key={item.address + item.pincode}>
                <p className="">
                  Deliver to:{" "}
                  <span className="font-bold text-rose-500">
                    {profile?.first_name &&
                      profile?.last_name &&
                      profile?.first_name +
                        " " +
                        profile?.last_name +
                        " (" +
                        profile?.billing?.phone +
                        ")" +
                        ","}{" "}
                  </span>
                  <span className="font-semibold">{item?.pincode}</span>
                </p>
                <p className="text-sm">
                  {item?.address}, {item?.city}, {item?.state}
                  {"\n"}
                  <span className="text-rose-500 text-xs font-normal">
                    ({item?.type?.toUpperCase()})
                  </span>
                </p>
              </div>
            ))
        ) : (
          <p className="font-medium text-gray-700">No default address found.</p>
        )}
      </div>
      <div>
        <AddressFormModal setAddress={setAddress} address={address} />
      </div>
    </div>
  );
}
