import {  PassCode } from "../../business/VerifyRepository";
import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class SendOTPRequest implements Requester {
  private phone: string;
  private otp: string;
  private code: PassCode;

  constructor(phone: string, otp: string, code: PassCode) {
    this.phone = phone;
    this.otp = otp;
    this.code = code;
  }

  path(): string {
    return ApiPath.VERIFY;
  }

  method(): RequestType {
    return RequestType.POST;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      pass_code: this.code,
    };
  }

  body(): any {
    return {
        phone: this.phone,
        otp: this.otp
    };
  }
}

export default SendOTPRequest;
