function convertPhoneNumber(phoneNumber:string, countryCode = "+91") {
  if (phoneNumber.startsWith(countryCode)) {
    return phoneNumber;
  } else {
    return countryCode + phoneNumber;
  }
}

function removeCountryCode(phoneNumber:string) {
  // Check if the phoneNumber starts with '+91'
  if (phoneNumber.startsWith("+91")) {
    // Remove the first three characters ('+91')
    return phoneNumber.substring(3);
  } else {
    // Return the phoneNumber as is if it doesn't start with '+91'
    return phoneNumber;
  }
}


// Function to create a div with a specific id
function createDivWithId(divId:string) {
  var div = document.createElement('div');
  div.id = divId;
  document?.body?.appendChild(div);
}

// Function to remove a div by its id
function removeDivById(divId:string) {
  var div = document.getElementById(divId);
  if (div) {
    div?.parentNode?.removeChild(div);
  }
}

export { removeCountryCode,convertPhoneNumber, createDivWithId, removeDivById };
