import { WishlistQuery } from "../../business/WishlistRepository";
import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class GetWishlistRequest implements Requester {
  private pageSize: number;
  private currentPage: number;
  private query: WishlistQuery;

  constructor(pageSize: number, currentPage: number, query: WishlistQuery) {
    this.pageSize = pageSize;
    this.currentPage = currentPage;
    this.query = query;
  }

  path(): string {
    return ApiPath.WISHLIST;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      pageSize: this.pageSize,
      currentPage: this.currentPage,
      query: this.query,
    };
  }

  body(): any {
    return null;
  }
}
