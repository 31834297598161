import { Outlet, useLocation } from "react-router-dom";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { AlertContext } from "../App";
import {
  BottomNav,
  CustomAlert,
  FullScreenLoader,
  Header,
} from "../components";
import Footer from "../components/Footer";
import { __DEV__ } from "../constant";
import {
  CartRepository,
  WPCategoryRepository,
  WPCustomerRepository,
  WishlistQuery,
  WishlistRepository,
} from "../business";
import { WPCategory } from "../models/responses/WPCategoriesResponse";
import { Wishlist } from "../models/responses/common";
import { getKEY_ID, isUserLoggedIn } from "../services";
import {
  Storage,
  StorageActionType,
  StorageKey,
  StorageType,
} from "../utils/helpers/storage";
import { Cart } from "../models/responses/CartResponse";

export const CategoriesContext = createContext({
  categories: [] as WPCategory[] | [] | undefined | null,
});

export const WishlistContext = createContext({
  wishlist: [],
  updateWishlist: (value: WishlistItem) => {},
} as {
  wishlist: WishlistItem[] | [] | undefined | null;
  updateWishlist: any;
});

export const CartContext = createContext({
  cart: [],
  updateCart: (value: CartItem) => {},
} as {
  cart: CartItem[] | [] | undefined | null;
  updateCart: any;
});

export interface CartItem {
  productId: number;
  cartId: number;
  quantity: number;
}

export interface WishlistItem {
  productId: number;
  wishId: number;
}

export function Layout() {
  const { pathname } = useLocation();

  const [categories, setCategories] = useState<
    WPCategory[] | [] | undefined | null
  >([]);

  const [wishlist, setWishlist] = useState<
    WishlistItem[] | [] | undefined | null
  >([]);
  const [cart, setCart] = useState<CartItem[] | [] | undefined | null>([]);

  const alertContextValue = useContext(AlertContext);

  const categoriesContextValue = useMemo(
    () => ({
      categories: categories,
    }),
    [categories]
  );

  const updateWishlist = (value: WishlistItem[]) => setWishlist(value);
  const updateCart = (value: CartItem[]) => setCart(value);

  const wishlistContextValue = useMemo(
    () => ({
      wishlist: wishlist,
      updateWishlist: updateWishlist,
    }),
    [wishlist]
  );

  const cartContextValue = useMemo(
    () => ({
      cart: cart,
      updateCart: updateCart,
    }),
    [cart]
  );

  const wpCategoriesRepository = new WPCategoryRepository();

  const wishlistRepository = new WishlistRepository();
  const cartRepository = new CartRepository();

  const [isWishlistFetched, setIsWishlistFetched] = useState<boolean>(false);
  const [isCartFetched, setIsCartFetched] = useState<boolean>(false);

  const fetchWishlistItems = async () => {
    try {
      const wishlistResponse = await wishlistRepository.fetchWishlistItems(
        1,
        1,
        WishlistQuery.ALL
      );

      setWishlist(
        wishlistResponse?.data?.wishlist?.map((item: Wishlist) => ({
          productId: +item?.wish_product_id,
          wishId: +item?.wish_id,
        }))
      );

      setIsWishlistFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCartItems = async () => {
    try {
      const cartResponse = await cartRepository.getCartItems();

      console.log(cartResponse);

      setCart(
        cartResponse?.data?.cart?.map((item: Cart) => ({
          productId: +item?.cart_product_id,
          cartId: +item?.cart_id,
          quantity: +item.cart_quantity,
        }))
      );

      setIsCartFetched(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllCategories = async () => {
    const per_page = 100;
    try {
      const categoriesResponse = await wpCategoriesRepository.getCategories({
        per_page,
      });
      const totalPages = categoriesResponse?.headers["x-wp-totalpages"];
      __DEV__ && console.log("Total pages for categories: ", totalPages);

      if (totalPages > 1) {
        let tempCategoriesData = categoriesResponse?.data;

        for (let i = 2; i <= totalPages; i++) {
          const tempCategoriesResponse =
            await wpCategoriesRepository.getCategories({
              per_page,
              page: i,
            });

          tempCategoriesData = [
            ...(tempCategoriesData ?? []),
            ...(tempCategoriesResponse?.data ?? []),
          ];
        }

        __DEV__ && console.log("All categories", tempCategoriesData);
      } else {
        setCategories(categoriesResponse?.data);
      }

      __DEV__ && console.log("Categories:", categoriesResponse?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserProfile = async () => {
    const wpCustomerRepository = new WPCustomerRepository();
    try {
      const userResponse = await wpCustomerRepository.getCustomerById(
        getKEY_ID()
      );
      Storage(
        StorageType.SESSION,
        StorageActionType.SET,
        StorageKey.FULLNAME,
        userResponse?.data?.first_name ?? ""
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllCategories();
    fetchUserProfile();
    fetchWishlistItems();
    fetchCartItems();
  }, []);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
  }, [pathname]);

  return (
    <CategoriesContext.Provider value={categoriesContextValue}>
      <CartContext.Provider value={cartContextValue}>
        <WishlistContext.Provider value={wishlistContextValue}>
          <CustomAlert
            alertType={alertContextValue.alertType}
            message={alertContextValue.alertMessage}
            onClose={alertContextValue.hideAlert}
            visible={alertContextValue.isAlertVisible}
            title={alertContextValue.alertTitle}
          />
          <Header />

          {!isWishlistFetched && !isCartFetched && <FullScreenLoader />}
          <Outlet />

          {!isUserLoggedIn() &&
            (pathname === "/cart" ||
              pathname === "/order" ||
              pathname === "/wishlist" ||
              pathname === "/profile") && (
              <div className="mt-16 mb-24">
                <img
                  src="https://cdni.iconscout.com/illustration/premium/thumb/fingerprint-authentication-required-for-payment-4658575-3867633.png"
                  alt="login required"
                  className="mx-auto"
                />

                <h2 className="text-2xl font-medium text-rose-500 text-center">
                  Account Login Required !
                </h2>
              </div>
            )}

          {/* <div className="mt-12 hidden sm:block">
            <Footer />
          </div> */}
          <div className="fixed z-50 w-[48px] h-[48px] sm:w-[54px] sm:h-[54px] bottom-16 sm:bottom-6 right-4 rounded-full opacity-60 hover:opacity-100">
            <a href="https://wa.me/+918950714229" target="_blank">
              <img
                className="w-full h-full rounded-full"
                src="https://seeklogo.com/images/W/whatsapp-icon-logo-BDC0A8063B-seeklogo.com.png"
                alt=""
              />
            </a>
          </div>

          <Footer />

          <BottomNav />
        </WishlistContext.Provider>
      </CartContext.Provider>
    </CategoriesContext.Provider>
  );
}
