import { HashRouter, Routes, Route } from "react-router-dom";
import { Layout } from "../layout";
import {
  CartPage,
  Home,
  ProductDetailPage,
  ProductListingPage,
  ProfilePage,
  Terms,
} from "../pages";
import { isUserLoggedIn } from "../services";
import OrderPage from "../pages/Order/OrderPage";
import WishlistPage from "../pages/Wishlist";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MagnifyImagespage from "../pages/ProductDetailPage/MagnifyImagespage";

const isAllowedToView = (element: any) => (isUserLoggedIn() ? element : null);

function RouteGuard() {
  return (
    <HelmetProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="*" element={<Home />} />
            <Route path="/cart" element={isAllowedToView(<CartPage />)} />
            <Route path="/terms of use" element={<Terms />} />
            <Route path="/products" element={<ProductListingPage />} />
            <Route path="/product/:productId" element={<ProductDetailPage />} />
            <Route
              path="/products/:category"
              element={<ProductListingPage />}
            />
            <Route
              path="/products/:sample/:category"
              element={<ProductListingPage />}
            />
            <Route path="/search" element={<ProductListingPage isSearch />} />
            <Route
              path="/search/:category"
              element={<ProductListingPage isSearch />}
            />
            <Route path="/order" element={isAllowedToView(<OrderPage />)} />
            <Route path="/profile" element={isAllowedToView(<ProfilePage />)} />
            <Route
              path="/wishlist"
              element={isAllowedToView(<WishlistPage />)}
            />
            <Route
              path="/images"
              element={(<MagnifyImagespage />)}
            />
          </Route>
        </Routes>
      </HashRouter>
    </HelmetProvider>
  );
}

export default RouteGuard;
