import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
  Input,
  Textarea,
  Radio,
  Spinner,
} from "@material-tailwind/react";
import {
  Storage,
  StorageActionType,
  StorageKey,
  StorageType,
} from "../utils/helpers/storage";
import { FaTrash } from "react-icons/fa";
import { AddressType, UserAddress } from "../models/responses/UserResponse";
import { AlertContext } from "../App";
import { AlertType } from "./CustomAlert";
import { FullScreenLoader } from "./FullScreenLoader";
import { PencilIcon } from "@heroicons/react/20/solid";

interface AddressFormModalProps {
  address: UserAddress[] | [];
  setAddress: any;
}

export function AddressFormModal(props: AddressFormModalProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const alertContextValue = useContext(AlertContext);
  const { address, setAddress } = props;

  const [open, setOpen] = React.useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const handleOpen = () => {
    setActiveTab("1"); // Set the default tab when the dialog opens
    setOpen(!open);
  };

  function switchToEditTab() {
    // Assuming "2" represents the second tab
    setActiveTab("2");
    // console.log(activeTab);
  }

  const geoLocation = Storage(
    StorageType.COOKIE,
    StorageActionType.GET,
    StorageKey.GEO_LOCATION
  );
  const [pincode, setPincode] = React.useState<string>(
    geoLocation?.pincode ?? ""
  );
  const [state, setState] = React.useState<string>(geoLocation?.state ?? "");
  const [addressLine, setAddressLine] = React.useState<string>("");
  const [city, setCity] = React.useState<string>(geoLocation?.city ?? "");
  const [type, setType] = React.useState<AddressType>(AddressType.HOME);
  const [isDefault, setIsDefault] = React.useState<boolean>(false);
  const [statesData, setStatesData] = React.useState<any[]>([]);

  const [citiesData, setCitiesData] = React.useState<any[]>([]);

  const [isAddressButtonLoading, setIsAddressButtonLoading] =
    React.useState<boolean>(false);

  const handleAddAddress = async () => {
    if (!pincode || !state || !addressLine || !city || !type) {
      alertContextValue.showAlert(
        AlertType.INFO,
        "All Fields Required !",
        "Please fill all the fields."
      );
      return;
    }

    setIsAddressButtonLoading(true);
    try {
      const currentDateTime = new Date().toISOString().replace(/[-T:Z.]/g, "");
      const randomUID =
        [...Array(32 - currentDateTime.length)]
          .map(() => ((Math.random() * 36) | 0).toString(36))
          .join("") + currentDateTime;
      setAddress(
        [
          ...address,
          {
            id: randomUID,
            pincode: pincode,
            state: state,
            address: addressLine,
            city: city,
            type: type,
            default: address.length === 0 ? true : false,
          },
        ].sort((a: UserAddress, b: UserAddress) => {
          if (a.default) return -1;
          if (b.default) return 1;
          return 0;
        })
      );

      setPincode(geoLocation?.pincode ?? "");
      setAddressLine("");
      setCity(geoLocation?.city ?? "");
      setState(geoLocation?.state ?? "");
      setType(AddressType.HOME);
      setIsDefault(false);

      handleOpen();
    } catch (error) {
      console.log(error);
    }

    setIsAddressButtonLoading(false);
  };

  function updateDefaultById(id: string) {
    const updatedAddress = address.map((item) => ({
      ...item,
      default: item?.id === id,
    }));

    setAddress(updatedAddress);

    handleOpen();
  }

  function deleteAddressById(id: string) {
    const confirmation = window.confirm(
      `Are you sure you want to delete the address?`
    );

    if (!confirmation) {
      return;
    }

    const updatedAddress = address.filter((item) => item.id !== id);

    setAddress(updatedAddress);

    handleOpen();
  }

  const getStates = async () => {
    setIsLoading(true);
    let headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "NTkxd3JyYXBKbUdmamF6N1hLS2JKYlpoQUxRcUJvT0dxQzN6MlhsUQ=="
    );

    await fetch("https://api.countrystatecity.in/v1/countries/IN/states", {
      method: "GET",
      headers: headers,
      redirect: "follow",
    })
      .then(async (response) => {
        if (response.status === 200) {
          const result = await response.json();
          setStatesData(result);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    setIsLoading(false);
  };

  const getCities = async (selectedCity: string) => {
    setIsLoading(true);
    let headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      "NTkxd3JyYXBKbUdmamF6N1hLS2JKYlpoQUxRcUJvT0dxQzN6MlhsUQ=="
    );

    await fetch(
      `https://api.countrystatecity.in/v1/countries/IN/states/${selectedCity}/cities`,
      {
        method: "GET",
        headers: headers,
        redirect: "follow",
      }
    )
      .then(async (response) => {
        if (response.status === 200) {
          const result = await response.json();
          setCitiesData(result);
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    setIsLoading(false);
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <>
      <Button
        placeholder={undefined}
        onClick={handleOpen}
        variant="outlined"
        className="inline-block border rounded transition ease-in-out delay-150 text-white border-black-500 bg-black  px-2 py-1 text-sm  w-36 text-center"
      >
        {address?.length == 0 ? "Add Address" : "Update"}
      </Button>
      <Dialog
        placeholder={undefined}
        size={window.innerWidth < 720 ? "lg" : "xs"}
        open={open}
        handler={handleOpen}
      >
        <DialogHeader placeholder={undefined}>Address Book</DialogHeader>
        <DialogBody placeholder={undefined}>
          <Tabs value={activeTab} key={activeTab}>
            <TabsHeader placeholder={undefined}>
              <Tab
                placeholder={undefined}
                key="1"
                value={"1"}
                onClick={() => setActiveTab("1")}
              >
                All
              </Tab>
              <Tab
                placeholder={undefined}
                color="pink"
                key="2"
                value={"2"}
                onClick={() => setActiveTab("2")}
              >
                Add New Address
              </Tab>
            </TabsHeader>
            <TabsBody placeholder={undefined}>
              {isLoading && <FullScreenLoader />}
              <TabPanel
                key="1"
                value={"1"}
                className="grid grid-cols-1 gap-6 pt-12"
              >
                {address && address.length > 0 ? (
                  address?.map((item: UserAddress) => (
                    <div
                      className="flex items-center justify-between"
                      key={item.address + item.pincode}
                    >
                      <div className="flex items-center">
                        <div className="mr-2 ">
                          <Radio
                            onClick={() => updateDefaultById(item?.id)}
                            name="address"

                            crossOrigin={undefined}
                            checked={item?.default === true ? true : false}
                          />
                        </div>
                        <div>
                          <p className="font-normal">
                            Deliver to:{" "}
                            <span className="font-semibold ">
                              {item?.pincode}
                            </span>
                            {item?.default === true && (
                              <span className="ml-1 text-rose-500 text-xs font-medium">
                                (Default)
                              </span>
                            )}
                          </p>
                          <p className="text-sm">
                            {item?.address}, {item?.city}, {item?.state}
                            {"\n"}
                            <span className="text-rose-500 text-xs font-normal">
                              ({item?.type?.toUpperCase()})
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        {/* <button
                          onClick={() => {
                            switchToEditTab();
                            // handleOpen();
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-5 h-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                        </button> */}
                        {item.default === false && (
                          <div className=" ">
                            <FaTrash
                              className="cursor-pointer"
                              onClick={() => deleteAddressById(item.id)}
                              color="red"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="font-medium text-gray-700">
                    No default address found.
                  </p>
                )}
              </TabPanel>
              <TabPanel
                key="2"
                value={"2"}
                className="flex items-center justify-center"
              >
                <form className="mt-6 mb-2 w-full max-w-screen-lg sm:w-96">
                  <div className="mb-1 flex flex-col gap-4">
                    <Textarea
                      value={addressLine}
                      onChange={(e) => setAddressLine(e.target.value)}
                      label="Address"
                  
                    />
                    {statesData && statesData.length > 0 && (
                      <select
                        onChange={(e) => {
                          let parts = e.target.value.split("::");
                          let code = parts[0]; // "ML"
                          let state = parts[1];
                          setState(state);
                          getCities(code);
                        }}
                        className="p-2 border rounded w-full"
                      >
                        <option value="">Select State</option>
                        {statesData.map((item, index) => (
                          <option
                            key={index}
                            value={item.iso2 + "::" + item.name}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}
                    {state && citiesData && citiesData.length > 0 && (
                      <select
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                        className="p-2 border rounded w-full"
                      >
                        <option value="">Select City</option>
                        {citiesData.map((item, index) => (
                          <option key={index} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    )}

                    {statesData &&
                      statesData.length > 0 &&
                      !(
                        <>
                          <Input
                            onChange={(e) => setCity(e.target.value)}
                            value={city}
                            size="lg"
                            label="City"
                            
                            crossOrigin={undefined}
                          />
                          <Input
                            onChange={(e) => setState(e.target.value)}
                            value={state}
                            size="lg"
                            label="State"
                            
                            crossOrigin={undefined}
                          />
                        </>
                      )}

                    <Input
                      onChange={(e) => setPincode(e.target.value)}
                      value={pincode}
                      type="number"
                      size="lg"
                      label="Pincode"
                  
                      crossOrigin={undefined}
                    />
                    <div className="flex gap-4">
                      {[
                        {
                          label: "Home",
                          value: AddressType.HOME,
                        },
                        {
                          label: "Office",
                          value: AddressType.OFFICE,
                        },
                        {
                          label: "Other",
                          value: AddressType.OTHER,
                        },
                      ].map((item) => (
                        <Radio
                          key={item.value}
                          
                          name="type"
                          checked={type === item.value}
                          label={item.label}
                          value={item.value}
                          onClick={() => setType(item?.value)}
                          crossOrigin={undefined}
                        />
                      ))}
                    </div>
                  </div>

                  <Button
                    placeholder={undefined}
                    onClick={handleAddAddress}
                    size="lg"
    
                    className="mt-6 flex justify-center items-center"
                    fullWidth
                  >
                    {isAddressButtonLoading ? (
                      <Spinner color="pink" />
                    ) : (
                      "Add Address"
                    )}
                  </Button>
                </form>
              </TabPanel>
            </TabsBody>
          </Tabs>
        </DialogBody>
        <DialogFooter placeholder={undefined}>
          {null}
        </DialogFooter>
      </Dialog>
    </>
  );
}
