import { WPAddOrderRequest, WPGetOrdersRequest } from "../../models/requests";
import { Order, SHOrder } from "../../models/responses/WPResponse";
import WPAbstractLogicLayer from "./WPAbstractLogicLayer";

export class WPOrderRepository extends WPAbstractLogicLayer {
  async getOrders(parameter?: object) {
    const request = new WPGetOrdersRequest(parameter);
    return this.performRequest<SHOrder[]>(request);
  }

  async addOrderToWP(data: any) {
    const request = new WPAddOrderRequest(data);
    return this.performRequest<Order>(request);
  }
}

export default WPOrderRepository;
