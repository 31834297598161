import { Breadcrumbs } from "@material-tailwind/react";
import { Link } from "react-router-dom";

interface BreadcrumbProps {
  breadcrumb: string[];
}

export function Breadcrumb(props: BreadcrumbProps) {
  const { breadcrumb } = props;

  return (
    <Breadcrumbs placeholder={undefined} color="pink" className="bg-white p-0 sm:pt-10">
      <Link to="/" className="opacity-60 hover:text-rose-500 px-2 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-4 w-4"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
        </svg>
      </Link>
      {breadcrumb?.length !== 0 &&
        breadcrumb?.map((item: string, index: number) => (
          <Link
            key={index}
            to={item == "products" ? "/products" : `/products/${item.toLowerCase()}`}
            className={
              index !== breadcrumb?.length - 1
                ? "hover:text-rose-500 opacity-60"
                : "hover:text-rose-500"
            }
          >
            <span className="capitalize">{item}</span>
          </Link>
        ))}
    </Breadcrumbs>
  );
}
