import { API_HOST } from "../constant";
import {
  ApiClient,
  ApiInterceptor,
  Requester,
} from "../core/network/ApiClient";

class AbstractLogicLayer implements ApiInterceptor {
  private client: ApiClient;

  constructor() {
    this.client = new ApiClient(`${API_HOST}/api`);
    this.client.interceptor = this;
  }

  defaultHeaders() {
    return this.getDefaultHeaders();
  }

  protected async performRequest<T>(requester: Requester, headers?: any) {
    return this.client.perform<T>(requester, headers);
  }

  // eslint-disable-next-line class-methods-use-this
  protected async getDefaultHeaders() {
    return {};
  }
}

export default AbstractLogicLayer;
