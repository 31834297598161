import round1 from "../../assets/img/round1.png";
import round2 from "../../assets/img/round2.png";
import round3 from "../../assets/img/round3.png";
import round4 from "../../assets/img/round4.png";
import round5 from "../../assets/img/round5.png";

const RoundStrap = () => {
  return (
    <div className="mx-[1.5rem] my-[2rem]">
      <div className=" text-center my-10">
        <h1 className="font-bold text-2xl">Why Us? </h1>
        <h3>
          Genuine Indian Craft, Sustainable Fibers, Eco-Friendly Passion, Proper
          Quality Checks
        </h3>
      </div>
      <div className="flex flex-wrap justify-center gap-2 sm:gap-4 lg:gap-10 ">
        <img className="h-24 sm:h-32 lg:h-40" src={round2} alt="img" />
        <img className="h-24 sm:h-32 lg:h-40" src={round1} alt="img" />
        <img className="h-24 sm:h-32 lg:h-40" src={round4} alt="img" />
        <img className="h-24 sm:h-32 lg:h-40" src={round3} alt="img" />
        <img className="h-24 sm:h-32 lg:h-40" src={round5} alt="img" />
      </div>
    </div>
  );
};

export default RoundStrap;
