import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import ReactHtmlParser from "react-html-parser";
import { useContext, useEffect, useRef, useState } from "react";
import ProductImageCarousel from "./ProductImageCrousel";
import { Link } from "react-router-dom";
import { AlertContext } from "../../App";
import { isUserLoggedIn } from "../../services/user";
import { NO_IMAGE } from "../../assets/img";
import "./product.css";
import { Product } from "../../models/responses/WPProductResponse";
import { AlertType } from "../CustomAlert";
import {
  findCartProductId,
  findWishProductId,
  getWishlistId,
  sliceSentence,
} from "../../services";
import { useCart, useWishlist } from "../../hooks";
import { CartRepository, WishlistRepository } from "../../business";
import { Helmet } from "react-helmet-async";
import { useOpenLoginDialog } from "../../components/openLoginDialog";
import { useMediaQuery } from "react-responsive";

interface ProductCardProps {
  product: Product;
}

enum LoadingState {
  LOADING,
  LOADED,
  ERROR,
}

export function ProductCard(props: ProductCardProps) {
  const openLoginDialog = useOpenLoginDialog();

  const openLogin = () => {
    // Perform some actions
    openLoginDialog(); // Open the login dialog
  };
  const { product } = props;
  const isMobile = useMediaQuery({ query: "(max-width: 642px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 380px)" });
  const { cart, addToCart } = useCart();
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();

  const alertContextValue = useContext(AlertContext);

  const [showCrouselImages, setShowCrouselImages] = useState(false);

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADED
  );
  const [inWishlist, setInWishlist] = useState<boolean>(
    findWishProductId(wishlist, product?.id)
  );

  const [cartLoadingState, setCartLoadingState] = useState<LoadingState>(
    LoadingState.LOADED
  );
  const [inCart, setInCart] = useState<boolean>(
    findCartProductId(cart, product.id)
  );

  const rating = useRef<string | number>((4 + Math.random()).toFixed(2));

  const [isHover, setIsHover] = useState(false);

  const handleShowAddToCart = () => setIsHover(true);
  const handleHideAddToCart = () => setIsHover(false);

  const handleShowImageCrousel = () => setShowCrouselImages(true);

  const handleHideImageCrousel = () => setShowCrouselImages(false);

  const handleInWishlist = async () => {
    setLoadingState(LoadingState.LOADING);
    const wishlistRepository = new WishlistRepository();
    if (!inWishlist === true) {
      try {
        const wishlistResponse = await wishlistRepository.addToWishlist(
          +product.id
        );
        addToWishlist(
          product.id.toString(),
          wishlistResponse?.data.toString() ?? "0"
        );

        alertContextValue.showAlert(
          AlertType.SUCCESS,
          "Added to wishlist...",
          null
        );
      } catch (error) {
        console.log(error);
        alertContextValue.showAlert(
          AlertType.ERROR,
          "Something went wrong...",
          null
        );
      }
    } else {
      try {
        const wishId = getWishlistId(wishlist, product.id);

        if (wishId) {
          await wishlistRepository.removeFromWishlist(wishId);
          removeFromWishlist(product.id.toString());
          alertContextValue.showAlert(
            AlertType.ERROR,
            "Removed from wishlist...",
            null
          );
        } else {
          alertContextValue.showAlert(
            AlertType.ERROR,
            "Something went wrong...",
            null
          );
        }
      } catch (error) {
        console.log(error);
        alertContextValue.showAlert(
          AlertType.ERROR,
          "Something went wrong...",
          null
        );
      }
    }

    setLoadingState(LoadingState.LOADED);
  };

  const handleAddToCart = async () => {
    setCartLoadingState(LoadingState.LOADING);
    const cartRepository = new CartRepository();
    try {
      const cartResponse = await cartRepository.addToCart(+product?.id);
      console.log(cartResponse?.data);

      addToCart(
        product?.id?.toString(),
        cartResponse?.data.toString() ?? "0",
        1
      );

      alertContextValue.showAlert(AlertType.SUCCESS, "Added to cart...", null);
    } catch (error) {
      console.log(error);
      alertContextValue.showAlert(
        AlertType.ERROR,
        "Something went wrong...",
        null
      );
    }
    setCartLoadingState(LoadingState.LOADED);
  };

  const renderWishlistIcon = () => {
    return inWishlist ? (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-6 h-6"
      >
        <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
      </svg>
    ) : (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
      </svg>
    );
  };

  const renderAddToCartButton = () => {
    return inCart ? (
      <Button
        placeholder={undefined}
        disabled
        color="gray"
        size="lg"
        className="mt-4"
        fullWidth={true}
      >
        Added to cart
      </Button>
    ) : (
      <Button
        placeholder={undefined}
        onClick={() => {
          isUserLoggedIn() ? handleAddToCart() : openLogin();
        }}
        size={isMobile ? "sm" : "lg"}
        className="mt-2 sm:mt-4"
        fullWidth={true}
      >
        {cartLoadingState === LoadingState.LOADING ? (
          <div
            className="mt-0.5 inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        ) : (
          " Add to cart"
        )}
      </Button>
    );
  };

  useEffect(() => {
    window.innerWidth < 768 && setIsHover(true);
    window.innerWidth < 768 && setShowCrouselImages(true);
  }, []);

  useEffect(() => {
    setInWishlist(findWishProductId(wishlist, product?.id));
    renderWishlistIcon();
  }, [wishlist]);

  useEffect(() => {
    setInCart(findCartProductId(cart, product?.id));
    renderAddToCartButton();
  }, [cart]);

  return (
    <Card
      placeholder={undefined}
      onMouseOver={handleShowAddToCart}
      onMouseOut={handleHideAddToCart}
      className="sm:w-full sm:max-w-[26rem]  rounded-none shadow-none cursor-pointer relative"
    >
      <CardHeader
        placeholder={undefined}
        onMouseOver={handleShowImageCrousel}
        onMouseOut={handleHideImageCrousel}
        className="shadow-none rounded-none"
        floated={false}
        color="blue-gray"
      >
        <Helmet>
          <meta charSet="UTF-8" />
          <title>{product?.name}</title>
          <meta name="description" content={product?.description} />
        </Helmet>
        <div className="h-full w-full object-fill">
          {showCrouselImages ? (
            <ProductImageCarousel
              path={`/product/${product?.slug}`}
              images={product?.images ?? []}
            />
          ) : (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to={`/product/${product?.slug}`}
            >
              <img
                className="h-full w-full object-fill"
                src={
                  product?.images[0]?.src ?? {
                    src: NO_IMAGE,
                  }
                }
                alt={product.images[0]?.alt ?? "Product Image"}
              />
            </Link>
          )}
        </div>

        {!showCrouselImages && (
          <div className="to-bg-white-10 absolute inset-0 h-full w-full object-fill bg-gradient-to-tr from-transparent via-transparent to-white/60 " />
        )}

        <IconButton
          placeholder={undefined}
          onClick={() => {
            isUserLoggedIn() ? handleInWishlist() : openLogin();
          }}
          size="sm"
          variant="text"
          color="black"
          className="!absolute top-2 right-2 rounded-full bg-white "
        >
          {loadingState === LoadingState.LOADING ? (
            <div
              className="mt-0.5 inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
          ) : (
            renderWishlistIcon()
          )}
        </IconButton>
        <div className="!absolute top-1 left-1 sm:top-2 sm:left-2 block sm:flex gap-2">
          {product?.on_sale && (
            <div className="rounded-sm bg-appPrimary-500 px-1 sm:px-2 py-1">
              <Typography
                placeholder={undefined}
                color="black"
                className="text-xs sm:text-sm uppercase font-medium"
              >
                {Math.ceil(
                  ((+product?.regular_price - +product?.sale_price) /
                    +product?.regular_price) *
                    100
                )}
                % OFF
              </Typography>
            </div>
          )}
          {product?.featured && !isSmallMobile && (
            <div className="rounded-sm bg-appPrimary-50 px-2 py-1 my-1 sm:my-0">
              <Typography
                placeholder={undefined}
                color="black"
                className="text-xs sm:text-sm uppercase font-medium"
              >
                Popular
              </Typography>
            </div>
          )}
        </div>
      </CardHeader>
      <CardBody
        placeholder={undefined}
        className={isHover ? "relative p-4" : ""}
      >
        <Link
          target="_blank"
          rel="noopener noreferrer"
          to={`/product/${product?.slug}`}
        >
          <div className="mb-3 flex items-center justify-between ">
            <Typography
              placeholder={undefined}
              // variant="h6"
              color="blue-gray"
              className="font-medium text-sm sm:text-lg"
            >
              {isHover ? product?.name : sliceSentence(product?.name, 54)}
            </Typography>

            {/* <Typography
              color="blue-gray"
              className="flex items-center gap-1.5 font-normal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="-mt-0.5 h-5 w-5 text-yellow-700"
              >
                <path
                  fillRule="evenodd"
                  d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                  clipRule="evenodd"
                />
              </svg>
              {rating.current}
            </Typography> */}
          </div>
          {/* <Typography placeholder={undefined} color="gray">
            {ReactHtmlParser(product.short_description)}
          </Typography> */}
          <Typography
            placeholder={undefined}
            className="sh-price font-semibold text-left mt-2 text-md sm:text-xl no-underline	"
          >
            {ReactHtmlParser(product.price_html)}
          </Typography>
        </Link>
        {isHover &&
          ((product?.stock_quantity ?? 0) > 0 ? (
            renderAddToCartButton()
          ) : (
            <Button
              placeholder={undefined}
              disabled
              color="amber"
              size="lg"
              className="mt-4"
              fullWidth={true}
            >
              Out of stock
            </Button>
          ))}
      </CardBody>
    </Card>
  );
}
