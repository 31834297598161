import {
  WPAddCustomerRequest,
  WPGetCustomerByIdRequest,
  WPUpdateCustomerRequest,
} from "../../models/requests";
import { Customer } from "../../models/responses/WPResponse";
import WPAbstractLogicLayer from "./WPAbstractLogicLayer";

export class WPCustomerRepository extends WPAbstractLogicLayer {
  async getCustomerById(parameter: number) {
    const request = new WPGetCustomerByIdRequest(parameter);
    return this.performRequest<Customer>(request);
  }

  async addCustomerToWP(data: any) {
    const request = new WPAddCustomerRequest(data);
    return this.performRequest<any>(request);
  }

  async updateCustomer(paramter: number, data: any) {
    const request = new WPUpdateCustomerRequest(paramter, data);
    return this.performRequest<any>(request);
  }
}

export default WPCustomerRepository;
