import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class GetUserByUid implements Requester {
  path(): string {
    return ApiPath.USER;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return null;
  }
}


