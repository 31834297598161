import { SendOTPRequest } from "../models/requests";
import VerifyOTPRequest from "../models/requests/VerifyOTPRequest";
import { SendOTPResponseData, VerifyOTPResponseData } from "../models/responses/VerifyResposne";
import AbstractLogicLayer from "./AbstractLogicLayer";

export enum OtpType {
  SMS = "sms",
  CALL = "call",
}

export enum PassCode {
  CODE = "LVoBwxESvtD%ucbfYMG9kCZnHy",
}

export class VerifyRepository extends AbstractLogicLayer {
  async sendOTP(phone: string, type: OtpType, code = PassCode.CODE) {
    const request = new SendOTPRequest(phone, type, code);
    return this.performRequest<SendOTPResponseData>(request);
  }

  async verifyOTP(phone: string, otp: string, code = PassCode.CODE) {
    const request = new VerifyOTPRequest(phone, otp,code);
    return this.performRequest<VerifyOTPResponseData>(request);
  }
}
