import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class RemoveFromCartRequest implements Requester {
  private cartId: number;

  constructor(cartId: number) {
    this.cartId = cartId;
  }

  path(): string {
    return ApiPath.CART;
  }

  method(): RequestType {
    return RequestType.DELETE;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      cart_id: this.cartId,
    };
  }

  body(): any {
    return null;
  }
}
