import { useEffect, useState } from "react";
import { ProductQuery } from "./HomePLP";
import { Product } from "../models/responses/WPProductResponse";
import { WPProductsRepository } from "../business";
import { __DEV__ } from "../constant";
import { SkeletonCarousel } from "./SkeletonCrousel";
import { WideHomePLPCarousel } from "./WideHomePLPCarousel";

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
}

export function WideHomePLP(props:any) {
  const { title, query } = props;

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING
  );

  const [homePlp, setHomePlp] = useState<Product[] | undefined | null>([]);

  const wpProductsRepository = new WPProductsRepository();

  const fetchHomePLP = async () => {
    const per_page = 24;
    setLoadingState(LoadingState.LOADING);
    try {
      const wpProductsResponse =
        query === ProductQuery.FEATURED
          ? await wpProductsRepository.getProducts({
              featured: true,
              per_page,
            })
          : await wpProductsRepository.getProducts({
              per_page,
            });
      __DEV__ && console.log("HomePLP:", wpProductsResponse?.data);
      setHomePlp(wpProductsResponse?.data);
      setLoadingState(LoadingState.LOADED);
    } catch (error) {
      console.log(error);
      setLoadingState(LoadingState.FAILED);
    }
  };

  useEffect(() => {
    fetchHomePLP();
  }, []);

  return (
    <div className="p-2">
      <h2 className="text-center text-2xl font-bold mb-6">{title}</h2>

      {loadingState === LoadingState.LOADING && <SkeletonCarousel />}

      {loadingState === LoadingState.LOADED && homePlp && (
        <WideHomePLPCarousel products={homePlp} />
      )}
    </div>
  );
}
