import axios, { AxiosError } from "axios";
import { getKEY_ID, getUID } from "../../services/user";
import { __DEV__ } from "../../constant";

export enum RequestType {
  POST = "post",
  GET = "get",
  DELETE = "delete",
  PUT = "put",
}

export enum ApiPath {
  CATEGORIES = "category.php",
  SEARCH = "search.php",
  WISHLIST = "wishlist.php",
  CART = "cart.php",
  USER = "user.php",
  VERIFY = "verify.php",
  PROFILE = "profile.php",
  ORDER = "order.php",
  PAYMENT = "payment.php",
  PhonePay_PAYMENT = "phonePayPayment.php",
  PRODUCT = "product.php",
  CMS = "cms.php",
  WP_PRODUCT = "wp-json/wc/v3/products",
  WP_CATEGORY = "wp-json/wc/v3/products/categories",
  WP_USER = "wp-json/wc/v3/customers",
  WP_ORDER = "wp-json/wc/v3/orders",
  WP_MENU = "wp-json/wp-api-menus/v2/menus",
  WP_COUPON = "wp-json/wc/v3/coupons",
  EMPTY_CART = "emptyCart.php",
}

export interface Requester {
  path(): string;
  headers(): any;
  params(): any;
  body(): any;
  method(): RequestType;
}

export interface ApiInterceptor {
  defaultHeaders(): any;
}

export class ApiClient {
  private baseUrl: string;

  public interceptor?: ApiInterceptor;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private prepareURL(request: Requester) {
    let url = this.baseUrl;
    if (request.path()) {
      url = url.concat(`/${request.path()}`);
    }
    return url;
  }

  public async perform<T>(request: Requester, additionalHeaders?: any) {
    const fullUrl = this.prepareURL(request);
    try {
      let headers = { ...additionalHeaders, ...request.headers() };
      if (this.interceptor != null) {
        headers = {
          ...(await this.interceptor.defaultHeaders()),
          ...headers,
          "Cache-Control": "no-cache",
        };
      }
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(headers);
        // eslint-disable-next-line no-console
        console.log(
          `calling ${fullUrl} with headers ${JSON.stringify(
            headers
          )} with body ${JSON.stringify(
            request.body()
          )} and params ${JSON.stringify(request.params())}`
        );
      }
      const response = await axios.request<T>({
        method: request.method(),
        url: fullUrl,
        data: request.body(),
        params: {
          ...request.params(),
          user_id: getKEY_ID() ?? "",
        },
        headers: {
          uid: getUID() ?? "",
        },
      });
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`request success for ${fullUrl}`);
      }
      return response;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`request failed for ${fullUrl}`);
        // eslint-disable-next-line no-console
        console.log(err.response?.data);
        // console.log(error)
      }
    }
  }
}

export class WPApiClient {
  private baseUrl: string;

  public interceptor?: ApiInterceptor;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private prepareURL(request: Requester) {
    let url = this.baseUrl;
    if (request.path()) {
      url = url.concat(`/${request.path()}`);
    }
    return url;
  }

  public async perform<T>(request: Requester, additionalHeaders?: any) {
    const fullUrl = this.prepareURL(request);
    try {
      let headers = { ...additionalHeaders, ...request.headers() };
      if (this.interceptor != null) {
        headers = {
          ...(await this.interceptor.defaultHeaders()),
          ...headers,
        };
      }
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(headers);
        // eslint-disable-next-line no-console
        console.log(
          `calling ${fullUrl} with headers ${JSON.stringify(
            headers
          )} with body ${JSON.stringify(
            request.body()
          )} and params ${JSON.stringify(request.params())}`
        );
      }
      const response = await axios.request<T>({
        method: request.method(),
        url: fullUrl,
        data: request.body(),
        params: {
          ...request.params(),
        },
        headers: {
          Authorization:
            "Basic Y2tfMWI0ZDc3MzNjMjZlMzk3MzhjMjcwMGNhYTRmNzFjZTdkOTIzMTQ1Njpjc182MDZmNmMzNTFlMTAxM2VjZjk0YjlkYmQ0ZDIyNDllMjUzOWQ0MjMx",
        },
      });
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`request success for ${fullUrl}`);
      }
      return response;
    } catch (error) {
      const err: AxiosError = error as AxiosError;
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(`request failed for ${fullUrl}`);
        // eslint-disable-next-line no-console
        console.log(err.response?.data);
        // console.log(error)
      }
    }
  }
}
