import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  Input,
  Alert,
} from "@material-tailwind/react";
import { Customer } from "../models/responses/WPResponse";
import {
  Storage,
  StorageActionType,
  StorageKey,
  StorageType,
} from "../utils/helpers/storage";
export function CartProfileFormModal(props: any) {
  const { profile, setProfile, hideBg, updateProfile } = props;

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const [name, setName] = useState<string>();
  // const [gender, setGender] = useState<string>();
  const [email, setEmail] = useState<string>();

  const handleProfileForm = () => {
    const [firstName, lastName] = name?.split(" ").slice(0, 2) ?? ["", ""];

    const profileData = {
      first_name: firstName ?? "-",
      last_name: lastName ?? "-",
      email: email,
      billing: {
        phone: profile?.billing?.phone ?? "",
        first_name: firstName ?? "-",
        last_name: lastName ?? "-",
        email: email,
      },
      shipping: {
        first_name: firstName ?? "-",
        last_name: lastName ?? "-",
        phone: profile?.billing?.phone ?? "",
      },
    };

    setProfile(profileData);

    Storage(
      StorageType.SESSION,
      StorageActionType.SET,
      StorageKey.FULLNAME,
      firstName ?? ""
    );
    updateProfile(profileData);
    handleOpen();
  };

  return (
    <>
      <div className="mb-6">
        <Alert
          color={hideBg ? "amber" : "red"}
          variant="ghost"
          action={
            <Button
              placeholder={undefined}
              onClick={handleOpen}
              className="!absolute top-3 right-3"
              color="red"
              size="sm"
            >
              Update Profile
            </Button>
          }
        >
          {hideBg
            ? "Want to update your profile ?"
            : " Complete your profile to place order..."}
        </Alert>
      </div>
      <Dialog
        placeholder={undefined}
        size={window.innerWidth < 720 ? "lg" : "xs"}
        open={open}
        handler={handleOpen}
      >
        <DialogHeader placeholder={undefined}>My Profile</DialogHeader>
        <DialogBody placeholder={undefined}>
          <form className="mt-2 mb-2 w-full max-w-screen-lg">
            <div className="mb-1 flex flex-col gap-4">
              <Input
                onChange={(e) => setName(e.target.value)}
                value={name}
                size="lg"
                label="Name"
                color="black"
                crossOrigin={undefined}
              />

              <Input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="email"
                size="lg"
                label="Email"
                color="black"
                crossOrigin={undefined}
              />

              {/* <div className="flex gap-4">
                {[
                  {
                    label: "Male",
                    value: "Male",
                  },
                  {
                    label: "Female",
                    value: "Female",
                  },
                  {
                    label: "Other",
                    value: "Other",
                  },
                ].map((item, index) => (
                  <Radio
                    key={index}
                    color="pink"
                    name="type"
                    checked={gender === item.value}
                    label={item.label}
                    value={item.value}
                    onClick={() => setGender(item?.value)}
                    crossOrigin={undefined}
                  />
                ))}
              </div> */}
            </div>

            <Button
              placeholder={undefined}
              onClick={handleProfileForm}
              size="lg"
              className="mt-6 flex justify-center items-center"
              fullWidth
            >
              Update Profile
            </Button>
          </form>
        </DialogBody>
      </Dialog>
    </>
  );
}
