import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";


export class WPGetCouponsRequest implements Requester {
  private parameter: object | undefined;

  constructor(parameter?: object) {
    this.parameter = parameter;
  }

  path(): string {
    return ApiPath.WP_COUPON;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return this.parameter;
  }

  body(): any {
    return null;
  }
}
export class WPGetACouponsRequest implements Requester {
  private parameter: string | undefined;

  constructor(parameter?: string) {
    this.parameter = parameter;
  }

  path(): string {
    return ApiPath.WP_COUPON + "/" + this.parameter;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return null;
  }
}
