import { WishlistItem } from "../layout";

function findWishProductId(
  wishlist: WishlistItem[] | [] | null | undefined,
  prodId: number | string
) {
  const wishItem = wishlist?.find(
    (item: WishlistItem) => +item?.productId === +prodId
  );
  if (wishItem) {
    return true;
  }
  return false; // Return null if wishId is not found in the wishlist
}

function getWishlistId(
  wishlist: WishlistItem[] | [] | null | undefined,
  prodId: number | string
) {
  const wishItem = wishlist?.find(
    (item: WishlistItem) => +item?.productId === +prodId
  );
  if (wishItem) {
    return +(wishItem.wishId ?? "0");
  } else {
    return null;
  }
}

export { findWishProductId, getWishlistId };
