import { memo, useEffect } from "react";
import {
  ExploreCategories,
  HeroSection,
  PopularCategories,
  ProductQuery,
  VirtualStore,
  WideHomePLP,
} from "../../components";
import { setSeoData } from "../../services";
import RoundStrap from "../RoundStrap";
import InstagramPage from "../InstagramPage";

import Ticker from "react-ticker";

export function Home() {
  useEffect(() => {
    setSeoData(
      "Inside Style Store",
      "Discover exquisite home decor at Inside Style Store. Explore a curated collection of furniture, accessories, and decor accents to elevate your living spaces. Transform your home with sophistication and style. Shop now..."
    );
  }, []);

  return (
    <>
      <div className="mt-14">
        <HeroSection />
      </div>
      {/* <WideHomePLP title="Best Seller" query={ProductQuery.BEST_SELLER} /> */}
      <WideHomePLP title="Best Seller" query={ProductQuery.FEATURED} />
      {/* <PopularCategories /> */}
      <hr></hr>
      <WideHomePLP title="Newly Arrived" query={ProductQuery.NEW} />

      {/* <div className="mt-2">
          <HomePLP title="Popular Products" query={ProductQuery.FEATURED} />
        </div> */}
      {/* <hr></hr> */}
      <VirtualStore />
      <hr></hr>
      <div className="my-8 px-1 sm:px-4">
        <ExploreCategories />
      </div>
      {/* <InstagramPage /> */}
      <hr></hr>
      <RoundStrap />
      {/* <WideHomePLP title="Popular Products" query={ProductQuery.FEATURED} /> */}
      {/* <div className="mt-2">
          <HomePLP title="Newly Arrived" query={ProductQuery.NEW} />
        </div> */}
    </>
  );
}
