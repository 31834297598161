import { useContext, useRef, useState } from "react";
import {
  Button,
  Dialog,
  Card,
  CardBody,
  CardFooter,
  Typography,
  Input,
  Checkbox,
  Spinner,
} from "@material-tailwind/react";
import { AlertContext, ModelContext } from "../../App";
import { FaArrowLeft, FaEye, FaEyeSlash } from "react-icons/fa";
import { removeCountryCode } from "./DataModels";
import { getUSERNAME } from "../../services/user";
import { AlertType, CustomAlert } from "../CustomAlert";
import { OtpType, UserRepository, VerifyRepository, WPCustomerRepository } from "../../business";
import {
  Storage,
  StorageActionType,
  StorageKey,
  StorageType,
} from "../../utils/helpers/storage";

enum UserNameType {
  EMAIL = "email",
  MOBILE = "mobile",
  INVALID = "invalid",
}

enum LoginType {
  OTP = "otp",
  PASSWORD = "password",
  EMAIL_LINK = "email_link",
}

export function Auth() {
  const verifyRepository = new VerifyRepository();
  const userRespoitory = new UserRepository();
  const wpCustomerRepository = new WPCustomerRepository();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const handleShowPassword = () => setShowPassword(!showPassword);

  const [userName, setUserName] = useState<string>(getUSERNAME() ?? "");
  const [password, setPassword] = useState<string>("");

  const [otp, setOTP] = useState<string[]>(["", "", "", "", "", ""]);
  const inputRefs = [
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
    useRef<any>(),
  ];

  const handleInput = (e: any, index: number) => {
    const value = e.target.value;

    if (!isNaN(Number(value)) && value.length <= 1) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      if (index < 5 && value !== "") {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  const [currentStep, setCurrentStep] = useState<number>(1);

  const [loginType, setLoginType] = useState<LoginType>(LoginType.OTP);

  const [isUserAuthLoading, setIsUserAuthLoading] = useState<boolean>(false);

  const [isResendAllowed, setIsResendAllowed] = useState<boolean>(false);

  const [timer, setTimer] = useState<number>(0);

  const dialogContextValue = useContext(ModelContext);
  const open = dialogContextValue?.authDialog?.open;

  const handleOpen = () =>
    dialogContextValue?.authDialog?.setOpen(
      !dialogContextValue?.authDialog?.open
    );

  const alertContextValue = useContext(AlertContext);

  const checkInputUserNameType = (inputValue: string): UserNameType => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const phonePattern = /^(\+91)?\d{10}$/;

    if (emailPattern.test(inputValue)) {
      return UserNameType.EMAIL;
    } else if (phonePattern.test(inputValue)) {
      return UserNameType.MOBILE;
    } else {
      return UserNameType.INVALID;
    }
  };

  const handleSendOTP = async (OtpType: OtpType) => {
    const verifyResponse = await verifyRepository.sendOTP(
      removeCountryCode(userName),
      OtpType
    );
    // console.log(verifyResponse?.data);

    if (verifyResponse?.data?.response?.return) {
      alertContextValue.showAlert(
        AlertType.SUCCESS,
        `Otp sent to ${userName}`,
        null
      );

      setCurrentStep(2);
      setLoginType(LoginType.OTP);
      setIsResendAllowed(false);
      startTimer();

      return verifyResponse?.data?.user;
    } else {
      alertContextValue.showAlert(
        AlertType.WARNING,
        "Unable to send otp",
        "Please try login with email..."
      );
    }
  };

  const handleUserAuth = async () => {
    setIsUserAuthLoading(true);

    const userNameType = checkInputUserNameType(userName);

    if (currentStep === 1) {
      if (userNameType === UserNameType.EMAIL) {
        alertContextValue.showAlert(
          AlertType.INFO,
          "Email Login",
          "Email Login is disabled for now..."
        );
      } else if (userNameType === UserNameType.MOBILE) {
        const isUserExists = await handleSendOTP(OtpType.SMS);

        if (!isUserExists) {
          try {
            const currentDateTime = new Date()
              .toISOString()
              .replace(/[-T:Z.]/g, "");
            const randomUID =
              [...Array(32 - currentDateTime.length)]
                .map(() => ((Math.random() * 36) | 0).toString(36))
                .join("") + currentDateTime;

            const wpUserResponse = await wpCustomerRepository.addCustomerToWP({
              username: randomUID.toString(),
              password: randomUID.toString(),
              email: userName.toString() + "@mail.com",
              billing: {
                phone: userName,
              },
            });

            const userResponse = await userRespoitory.addUser({
              user_id: wpUserResponse?.data?.id,
              user_username: randomUID.toString(),
              user_phone: userName,
            });

            if (userResponse?.data && wpUserResponse?.data) {
              alertContextValue.showAlert(
                AlertType.SUCCESS,
                "Account Created",
                "Your account has been created successfully..."
              );
            }
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        alertContextValue.showAlert(
          AlertType.ERROR,
          "Invalid Mobile or Email",
          "Mobile or Email you entered is invalid..."
        );
      }
    }

    if (currentStep === 2) {
      if (loginType === LoginType.OTP) {
        try {
          const otpResponse = await verifyRepository.verifyOTP(
            userName,
            otp.join("")
          );

          if (otpResponse?.data.otp_status == "verified") {
            alertContextValue.showAlert(
              AlertType.SUCCESS,
              "Login Success",
              "You have been logged in successfully..."
            );

            Storage(
              StorageType.COOKIE,
              StorageActionType.SET,
              StorageKey.UID,
              otpResponse?.data?.user?.user_username
            );

            Storage(
              StorageType.COOKIE,
              StorageActionType.SET,
              StorageKey.KEYID,
              otpResponse?.data?.user?.user_id
            );
            Storage(
              StorageType.COOKIE,
              StorageActionType.SET,
              StorageKey.USERNAME,
              userName
            );

            handleOpen();
            window.location.reload();
          }

          if (otpResponse == undefined) {
            alertContextValue.showAlert(
              AlertType.ERROR,
              "Invalid OTP",
              "Please enter valid OTP..."
            );
          }
        } catch (error: any) {
          // console.log("hello ji");
          alertContextValue.showAlert(
            AlertType.ERROR,
            "Invalid OTP",
            "Please enter valid OTP..."
          );
          console.log(error?.error_message);
        }
      } else if (loginType === LoginType.PASSWORD) {
      } else if (loginType === LoginType.EMAIL_LINK) {
      }
    }

    setIsUserAuthLoading(false);
  };

  function startTimer() {
    let seconds = 60;
    let countdown = setInterval(function () {
      if (seconds > 0) {
        seconds--;
        setTimer(seconds);
      } else {
        setIsResendAllowed(true);
        clearInterval(countdown);
        seconds = 60;
      }
    }, 1000);
  }

  return (
    <>
      <Dialog
        placeholder={undefined}
        size="xs"
        open={open}
        handler={handleOpen}
        className="bg-transparent shadow-none z-40"
      >
        <Card
          placeholder={undefined}
          className="mx-auto rounded  w-full max-w-[24rem]"
        >
          <CardBody placeholder={undefined} className="flex flex-col gap-6">
            <CustomAlert
              alertType={alertContextValue.alertType}
              message={alertContextValue.alertMessage}
              onClose={alertContextValue.hideAlert}
              visible={alertContextValue.isAlertVisible}
              title={alertContextValue.alertTitle}
            />
            <Typography
              placeholder={undefined}
              className="flex justify-start items-center"
              variant="h4"
              color="blue-gray"
            >
              {currentStep === 2 && (
                <FaArrowLeft
                  onClick={() => setCurrentStep(1)}
                  size={20}
                  className="mr-4 cursor-pointer"
                />
              )}
              Login / Register
            </Typography>

            {currentStep === 1 && (
              <div className="relative">
                <span className="absolute top-5 left-1 text-sm text-rose-500 font-medium">
                  +91
                </span>
                <Input
                  icon={
                    <img
                      className="w-[28px] h-[16px] mr-2 mb-0.5"
                      src="https://cdn.pixabay.com/photo/2016/08/24/17/07/india-1617463_640.png"
                    />
                  }
                  label="Mobile"
                  variant="static"
                  className="outline-rose-500 w-100 pl-8"
                  size="lg"
                  color="black"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  crossOrigin={undefined}
                />
              </div>
            )}

            {currentStep === 2 && (
              <>
                {loginType === LoginType.PASSWORD && (
                  <Input
                    icon={
                      loginType === LoginType.PASSWORD ? (
                        showPassword ? (
                          <FaEyeSlash
                            className="cursor-pointer "
                            onClick={handleShowPassword}
                          />
                        ) : (
                          <FaEye
                            className="cursor-pointer "
                            onClick={handleShowPassword}
                          />
                        )
                      ) : null
                    }
                    color="black"
                    variant="standard"
                    label={"Password"}
                    size="lg"
                    type={showPassword ? "text" : "password"}
                    crossOrigin={undefined}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                )}

                {loginType === LoginType.OTP && (
                  <div>
                    <h3 className="text-center text-green-700 font-normal">
                      OTP Sent to <span className="font-bold">{userName}</span>
                    </h3>
                    <style>
                      {`
          input[type="number"]::-webkit-inner-spin-button,
          input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            appearance: none;
            margin: 0;
          }
        `}
                    </style>
                    <div className="px-0 sm:px-8 mt-4 grid grid-cols-6 gap-2.5">
                      {otp.map((digit, index) => (
                        <input
                          className="border text-bold  rounded py-2 px-2.5 sm:px-3 border-black outline-0"
                          key={index}
                          ref={inputRefs[index]}
                          type="number"
                          value={digit}
                          maxLength={1}
                          onChange={(e) => handleInput(e, index)}
                          onKeyUp={(e) => {
                            if (e.keyCode === 8 && index !== 0) {
                              inputRefs[index - 1].current?.focus();
                            } else {
                              handleInput(e, index);
                            }
                          }}
                        />
                      ))}
                    </div>

                    <div className="mt-5 flex justify-between items-center">
                      <div>
                        <Button
                          className="p-2"
                          color="black"
                          variant="text"
                          size="sm"
                          disabled={!isResendAllowed}
                          onClick={() => handleSendOTP(OtpType.SMS)}
                          placeholder={undefined}
                        >
                          Resend OTP
                        </Button>
                        <span className="mx-2">|</span>
                        <Button
                          placeholder={undefined}
                          className="p-2"
                          color="black"
                          variant="text"
                          size="sm"
                          disabled={!isResendAllowed}
                          onClick={() => handleSendOTP(OtpType.CALL)}
                        >
                          Get on call
                        </Button>
                      </div>

                      <div className="text-sm text-green-500 font-normal">
                        {timer != 0 && `in ${timer} sec`}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            {currentStep === 1 && (
              <div className="-ml-2.5 -mt-3">
                <Checkbox label="Remember Me" crossOrigin={undefined} />
              </div>
            )}
          </CardBody>
          <CardFooter placeholder={undefined} className="pt-0">
            <Button
              color="black"
              placeholder={undefined}
              variant="filled"
              className="flex justify-center items-center"
              onClick={handleUserAuth}
              fullWidth
              size="lg"
            >
              {isUserAuthLoading ? <Spinner color="orange" /> : "Continue"}
            </Button>

            <Typography
              placeholder={undefined}
              variant="small"
              className="mt-4 text-center"
            >
              By continuing, I agree to the
              <span className="text-rose-500 font-semibold cursor-pointer">
                {" "}
                Terms of Service{" "}
              </span>
              and{" "}
              <span className="text-rose-500 font-semibold cursor-pointer">
                Privacy Policy
              </span>
            </Typography>
          </CardFooter>
        </Card>
      </Dialog>
    </>
  );
}
