import { GetAddressRequest } from "../models/requests";
import UpdateAddressRequest from "../models/requests/UpdateAddressRequest";
import { UserAddress } from "../models/responses/UserResponse";
import AbstractLogicLayer from "./AbstractLogicLayer";

export enum ProfileQuery{
    ADDRESS =  "address"
}

export class ProfileRepository extends AbstractLogicLayer {
  async getAddress() {
    const request = new GetAddressRequest();
    return this.performRequest<any>(request);
  }

  async updateAddress(address: UserAddress[] | []) {
    const request = new UpdateAddressRequest(address);
    return this.performRequest<any>(request);
  }
}

export default ProfileRepository;
