import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";

export class WPGetCustomerByIdRequest implements Requester {
  private parameter: number | undefined;

  constructor(parameter?: number) {
    this.parameter = parameter;
  }

  path(): string {
    return ApiPath.WP_USER + "/" + this.parameter;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return null;
  }
}
