import { WPGetCouponsRequest,WPGetACouponsRequest } from "../../models/requests";
import { Coupon } from "../../models/responses/WPResponse";
import WPAbstractLogicLayer from "./WPAbstractLogicLayer";


export class WPCouponsRepository extends WPAbstractLogicLayer{
  async getCoupons(parameter?: object) {
    const request = new WPGetCouponsRequest(parameter);
    return this.performRequest<Coupon[]>(request);
  }
  async getACoupan(parameter?: string) {
    const request = new WPGetACouponsRequest(parameter);
    return this.performRequest<Coupon>(request);
  }
}
