import { ProfileQuery } from "../../business/ProfileRepository";
import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class GetAddressRequest implements Requester {
  path(): string {
    return ApiPath.PROFILE;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
      return {
        query: ProfileQuery.ADDRESS
      };
  }

  body(): any {
    return null;
  }
}

export default GetAddressRequest;
