import { WPGetCategoriesRequest } from "../../models/requests";
import { WPCategory } from "../../models/responses/WPCategoriesResponse";
import WPAbstractLogicLayer from "./WPAbstractLogicLayer";

export class WPCategoryRepository extends WPAbstractLogicLayer {
  async getCategories(parameter?: object) {
    const request = new WPGetCategoriesRequest(parameter);
    return this.performRequest<WPCategory[]>(request);
  }
}
