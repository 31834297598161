export function createArray(N: number) {
  let newArr = [];
  for (let i = 1; i <= N; i++) {
    newArr.push(i);
  }
  return newArr;
}

function capitalizeFirstLetterOfSentences(str: string) {
  // Split the string into sentences using punctuation marks (., ?, !) as separators
  var sentences = str.split(/\. |\? |\! /);

  // Capitalize the first letter of each sentence
  var capitalizedSentences = sentences.map(function (sentence) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  });

  // Join the sentences back together with proper punctuation
  var capitalizedString = capitalizedSentences.join(". ");

  return capitalizedString;
}

export function setSeoData(
  _title: string | null | undefined,
  _desc: string | null | undefined
) {
  document.title = capitalizeFirstLetterOfSentences(
    _title ?? "Inside Style Store"
  );
  var metaTag = document.querySelector('meta[name="description"]');
  if (metaTag) {
    metaTag.setAttribute(
      "content",
      capitalizeFirstLetterOfSentences(
        _title ??
          "Discover exquisite home decor at Inside Style Store. Explore a curated collection of furniture, accessories, and decor accents to elevate your living spaces. Transform your home with sophistication and style. Shop now!"
      )
    );
  }
}
