import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";


export class WPAddOrderRequest implements Requester {
  private data: any;

  constructor(data: any) {
    this.data = data;
  }

  path(): string {
    return ApiPath.WP_ORDER;
  }

  method(): RequestType {
    return RequestType.POST;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return this.data;
  }
}


