import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Skeleton from "react-loading-skeleton";

export function SkeletonCarousel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1900 },
      items: 9,
    },
    desktop: {
      breakpoint: { max: 1900, min: 1500 },
      items: 6,
    },
    tablet: {
      breakpoint: { max: 1300, min: 1000 },
      items: 4,
    },
    miniTablet: {
      breakpoint: { max: 1000, min: 700 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 2,
    },
    miniMobile: {
      breakpoint: { max: 540, min: 0 },
      items: 1,
    },
  };

  return (
    <Carousel
      responsive={responsive}
      arrows={true}
      autoPlay={false}
      autoPlaySpeed={1500}
      infinite={true}
      className="mx-auto"
    >
      {[
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
      ].map((item) => (
        <div key={item} className="px-1 h-[24rem] w-full sm:max-w-[20rem]">
          <Skeleton duration={0.8} width="100%" height="100%" />
        </div>
      ))}
    </Carousel>
  );
}
