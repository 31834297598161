import { useEffect, useState } from "react";
import { Alert, Typography } from "@material-tailwind/react";

export enum AlertType {
  ERROR = "red",
  SUCCESS = "green",
  WARNING = "amber",
  INFO = "blue",
}

export interface ShowAlertProps {
  alertType: AlertType;
  message: string | null;
  onClose: () => void;
  visible: boolean;
  title: string;
}

export function CustomAlert(props: ShowAlertProps) {
  const { alertType, message, onClose, visible, title } = props;

  const [showAlert, setShowAlert] = useState<boolean>(visible);

  useEffect(() => {
    setShowAlert(visible);

    if (visible) {
      const timeout = setTimeout(() => {
        setShowAlert(false);
        onClose();
      }, 2500);

      return () => clearTimeout(timeout);
    }
  }, [visible, onClose]);

  return (
    <div className="fixed z-50 w-full px-2 top-18 right-auto left-auto md:max-w-[640px] md:w-auto md:right-2 md:top-14">
      <Alert
        open={showAlert}
        color={alertType}
        onClose={() => setShowAlert(false)}
        animate={{
          mount: { y: 0 },
          unmount: { y: 100 },
        }}
      >
        <Typography variant="h6" color="white" placeholder={undefined}>
          {title}
        </Typography>
        <span className="font-medium"></span>
        {message && (
          <Typography
            color="white"
            className="mt-2 font-normal"
            placeholder={undefined}
          >
            {message}
          </Typography>
        )}
      </Alert>
    </div>
  );
}
