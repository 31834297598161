import { API_WP } from "../../constant";
import {
  ApiInterceptor,
  Requester,
  WPApiClient,
} from "../../core/network/ApiClient";

class WPAbstractLogicLayer implements ApiInterceptor {
  private client: WPApiClient;

  constructor() {
    this.client = new WPApiClient(`${API_WP}`);
    this.client.interceptor = this;
  }

  defaultHeaders() {
    return this.getDefaultHeaders();
  }

  protected async performRequest<T>(requester: Requester, headers?: any) {
    return this.client.perform<T>(requester, headers);
  }

  // eslint-disable-next-line class-methods-use-this
  protected async getDefaultHeaders() {
    return {};
  }
}

export default WPAbstractLogicLayer;
