import { useEffect, useRef, useState } from "react";
import { LoadingState } from "./HeroSection";
import { CMSContentType, CMSRepository } from "../business";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { Button } from "@material-tailwind/react";

enum Direction {
  LEFT,
  TOP,
}

function getCords(data: string, dir: Direction):string {
  const temp = data.split(",");

  return dir === Direction.LEFT ? temp[0] : temp[1];
}

function ImageDimensions(props: any) {
  const { src, alt, products } = props;

  const imageRef = useRef<any>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleImageLoad = () => {
      if (imageRef.current) {
        const width = imageRef.current.clientWidth;
        const height = imageRef.current.clientHeight;
        setDimensions({ width, height });
      }
    };

    if (imageRef.current && imageRef.current.complete) {
      // If the image is already loaded (cached), handle it immediately
      handleImageLoad();
    } else {
      // If the image is not loaded, add an event listener for the load event
      imageRef.current.addEventListener("load", handleImageLoad);
    }

    return () => {
      if (imageRef.current) {
        // Clean up by removing the event listener
        imageRef.current.removeEventListener("load", handleImageLoad);
      }
    };
  }, []);

  return (
    <>
      <img className="md:h-[420px]" ref={imageRef} src={src} alt={alt} />
      {dimensions?.width > 0 && dimensions?.height > 0 && (
        <RenderStore products={products} dimensions={dimensions} />
      )}
    </>
  );
}

const RenderStore = (props: any) => {
  const { products,dimensions } = props;

  return products && products.length > 0 ? (
    <>
      {products?.map((item: any, index: any, key: any) => (
        <div
          key={key + index}
          className="flex justify-center items-center group absolute cursor-pointer border-4 rounded-full border-white parent-element hover:border-none"
          style={{
            left: `${
              (+getCords(item?.coords, Direction.LEFT) / 100) * dimensions?.width
            }px`,
            top: `${
              (+getCords(item?.coords, Direction.TOP) / 100) * dimensions?.height
            }px`,
          }}
        >
          <div className="bg-white p-2 rounded-full m-1"></div>
          <Link to={`/product/${item?.slug}`}>
            <div className="child-elements hidden group-hover:block absolute w-[240px] z-50">
              <Button size="sm" placeholder={undefined} color="white">
                View Product
              </Button>
            </div>
          </Link>
        </div>
      ))}
    </>
  ) : null;
};

export const VirtualStore = () => {
  const [virtualStore, setVirtualStore] = useState<any>([]);
  const [virtualStoreLoadingState, setVirtualStoreLoadingState] =
    useState<LoadingState>(LoadingState.Loading);

  const cmsRepository = new CMSRepository();

  const fetchVirtualStore = async () => {
    setVirtualStoreLoadingState(LoadingState.Loading);

    try {
      const cmsResponse = await cmsRepository.getCMSContent(
        CMSContentType.VIRTUAL_STORE
      );

      // console.log(cmsResponse?.data);

      setVirtualStore(cmsResponse?.data ?? []);
      setVirtualStoreLoadingState(LoadingState.Loaded);
    } catch (error) {
      setVirtualStoreLoadingState(LoadingState.Error);
    }
  };

  useEffect(() => {
    fetchVirtualStore();
  }, []);

  return (
    <div className="mt-4">
      <div className="grid sm:grid-cols-[1fr_1fr_1fr] gap-5 m-8">
        {virtualStoreLoadingState === LoadingState.Loading && (
          <>
            <div className="mb-8 md:mb-0 h-[32rem] w-full">
              <Skeleton duration={0.8} width="100%" height="100%" />
            </div>

            <div className="mb-8 md:mb-0 h-[32rem] w-full">
              <Skeleton duration={0.8} width="100%" height="100%" />
            </div>
          </>
        )}

        {virtualStoreLoadingState === LoadingState.Loaded &&
          virtualStore &&
          virtualStore?.length > 0 &&
          virtualStore?.map((item: any, key: any, index: any) => (
            <div key={key + index}>
              <div className="relative ">
                <ImageDimensions
                  src={item?.img}
                  alt={item?.slug}
                  products={JSON.parse(item?.products)}
                />
              </div>

              {/* <h2 className="text-xl font-medium m-3 text-center">
                Chair sets from ₹ 2300
              </h2> */}
            </div>
          ))}
      </div>
    </div>
  );
};
