import { AddUserRequest, GetUserByUid, UpdateUserRequest } from "../models/requests";
import { AddUserRequestData, UserData } from "../models/responses/UserResponse";
import AbstractLogicLayer from "./AbstractLogicLayer";

export class UserRepository extends AbstractLogicLayer {
  async getUserById() {
    const request = new GetUserByUid();
    return this.performRequest<UserData>(request);
  }

  async addUser(userData: AddUserRequestData) {
    const request = new AddUserRequest(userData);
    return this.performRequest<number>(request);
  }

  async updateUser(userData: UserData) {
    const request = new UpdateUserRequest(userData);
    return this.performRequest<number>(request);
  }
}

export default UserRepository;
