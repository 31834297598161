import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

interface DropDownProps {
  title: any;
  showDropArrowIcon: boolean;
  dropDownItems: any;
}

export function DropDown(props: DropDownProps) {
  const { title, showDropArrowIcon, dropDownItems } = props;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className=" flex uppercase font-medium items-center justify-center gap-x-0.5">
          {title}
          {showDropArrowIcon && (
            <ChevronDownIcon
              className="-mr-1 h-5 w-5 text-rose-500	"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
      </div>

      {dropDownItems && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            {dropDownItems?.map((item: any, key: number) => (
              <div className="py-1">
                <Menu.Item >{item}</Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
}
