import Cookies from "js-cookie";

export enum StorageActionType {
  SET = "SET",
  GET = "GET",
  CLEAR = "CLEAR",
  CLEAR_ALL = "CLEAR_ALL",
}

export enum StorageKey {
  TOKEN = "token",
  GTOKEN = "gtoken",
  UID = "uid",
  EMAIL = "email",
  KEYID = "keyid",
  FULLNAME = "userFullName",
  USERNAME = "userName",
  GEO_LOCATION = "geoLocation",
}

export enum StorageType {
  LOCAL = "local",
  SESSION = "session",
  COOKIE = "cookie",
}

const clearAllCookies = () => {
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.TOKEN);
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.GTOKEN);
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.UID);
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.EMAIL);
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.KEYID);
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.GEO_LOCATION);
  Storage(StorageType.COOKIE, StorageActionType.CLEAR, StorageKey.FULLNAME);
};

export const Storage = (
  sType: StorageType,
  aType: StorageActionType,
  key: StorageKey,
  value: any = null,
  expiresIn?: number | undefined
) => {
  const setData = () => {
    const jsonValue = JSON.stringify(value ?? "null");
    const expTime = new Date(Date.now() + (expiresIn ?? 0) * 1000);

    switch (sType) {
      case StorageType.COOKIE:
        return expiresIn
          ? Cookies.set(key, jsonValue, { expires: expTime })
          : Cookies.set(key, jsonValue);

      case StorageType.LOCAL:
        return localStorage.setItem(key, jsonValue);

      case StorageType.SESSION:
        return sessionStorage.setItem(key, jsonValue);

      default:
        return null;
    }
  };

  const getData = () => {
    let jsonValue;

    switch (sType) {
      case StorageType.COOKIE:
        jsonValue = Cookies.get(key);
        break;

      case StorageType.LOCAL:
        jsonValue = localStorage.getItem(key);
        break;

      case StorageType.SESSION:
        jsonValue = sessionStorage.getItem(key);
        break;

      default:
        return null;
    }

    return jsonValue != null ? JSON.parse(jsonValue) : null;
  };

  const clearData = () => {
    switch (sType) {
      case StorageType.COOKIE:
        Cookies.remove(key);
        break;

      case StorageType.LOCAL:
        localStorage.removeItem(key);
        break;

      case StorageType.SESSION:
        sessionStorage.removeItem(key);
        break;

      default:
        return null;
    }
  };

  const clearAll = () => {
    localStorage.clear();
    sessionStorage.clear();
    clearAllCookies();
  };

  switch (aType) {
    case StorageActionType.GET:
      return getData();

    case StorageActionType.SET:
      return setData();

    case StorageActionType.CLEAR:
      return clearData();

    case StorageActionType.CLEAR_ALL:
      return clearAll();

    default:
      return null;
  }
};
