import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class RemoveFromWishlist implements Requester {
  private wishId: number;

  constructor(wishId: number) {
    this.wishId = wishId;
  }

  path(): string {
    return ApiPath.WISHLIST;
  }

  method(): RequestType {
    return RequestType.DELETE;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      wish_id: this.wishId,
    };
  }

  body(): any {
    return null;
  }
}
