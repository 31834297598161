import React, { useContext } from "react";
import { Drawer, Typography, IconButton } from "@material-tailwind/react";
import { ModelContext } from "../App";
import NavbarWithMegaMenu from "./NavbarWithMegaMenu";
// import NavbarWithMegaMenu from "../Header/NavbarWithMegaMenu";

export function MenuDrawer() {
  const menuContextValue = useContext(ModelContext);

  const open = menuContextValue?.menuDrawer?.open;

  const handleMenuOpen = (value: boolean) =>
    menuContextValue?.menuDrawer?.setOpen(value);

  const openDrawer = () => handleMenuOpen(true);
  const closeDrawer = () => handleMenuOpen(false);

  return (
    <React.Fragment>
      <Drawer
        placeholder={undefined}
        open={open}
        onClose={closeDrawer}
        className="p-4"
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography placeholder={undefined} variant="h5" color="blue-gray">
            Menu
          </Typography>
          <IconButton
            placeholder={undefined}
            variant="text"
            color="blue-gray"
            onClick={closeDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <div><NavbarWithMegaMenu callBackFunc={closeDrawer} /></div>
      </Drawer>
    </React.Fragment>
  );
}
