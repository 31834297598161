import { useContext } from "react";
import { CartContext, CartItem } from "../layout";

export function useCart() {
  const cartValue = useContext(CartContext);

  const addToCart = (productId: string, cartId: string, quantity: number) => {
    cartValue.updateCart([
      ...(cartValue?.cart ?? []),
      {
        productId: +productId,
        cartId: +cartId,
        quantity: +quantity,
      },
    ]);
  };

  const removeFromCart = (productId: string) => {
    cartValue.updateCart(
      cartValue?.cart?.filter(
        (item: CartItem) => item?.productId !== +productId
      )
    );
  };

  const clearCart = () => cartValue.updateCart([]);

  const increaseQuantity = (productId: string) => {
    cartValue.updateCart(
      cartValue?.cart?.map((item: CartItem) => {
        if (item?.productId === +productId) {
          return {
            ...item,
            quantity: (item?.quantity || 0) + 1,
          };
        }

        return item;
      })
    );
  };

  const decreaseQuantity = (productId: string) => {
    cartValue.updateCart(
      cartValue?.cart?.map((item: CartItem) => {
        if (item?.productId === +productId) {
          return {
            ...item,
            quantity: (item?.quantity || 1) - 1,
          };
        }

        return item;
      })
    );
  };

  return {
    cart: cartValue.cart,
    addToCart,
    removeFromCart,
    clearCart,
    increaseQuantity,
    decreaseQuantity,
  };
}
