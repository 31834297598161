import { createContext, useMemo, useState } from "react";
import { AlertType } from "./components";
import RouteGuard from "./routes/routes";
import "react-loading-skeleton/dist/skeleton.css";
import "animate.css";

export const ModelContext = createContext({
  authDialog: {
    open: false,
    setOpen: () => {},
  } as {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  },
  menuDrawer: {
    open: false,
    setOpen: () => {},
  } as {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  },
});

export const AlertContext = createContext({
  isAlertVisible: false,
  alertTitle: "Hello Ji",
  alertMessage: "msg",
  alertType: AlertType.SUCCESS,
  showAlert: (
    _alertType: AlertType,
    _title: string,
    _message: string | null
  ) => {},
  hideAlert: () => {},
} as {
  isAlertVisible: boolean;
  alertTitle: string;
  alertMessage: string | null;
  alertType: AlertType;
  showAlert: (
    _alertType: AlertType,
    _title: string,
    _message: string | null
  ) => void;
  hideAlert: () => void;
});

function App() {
  const [showAuthForm, setAuthForm] = useState<boolean>(false);
  const [menuDrawer, setMenuDrawer] = useState<boolean>(false);

  const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string | null>("");
  const [alertType, setAlertType] = useState<AlertType>(AlertType.SUCCESS);

  const dialogContextValue = useMemo(
    () => ({
      authDialog: {
        open: showAuthForm,
        setOpen: setAuthForm,
      },
      menuDrawer: {
        open: menuDrawer,
        setOpen: setMenuDrawer,
      },
    }),
    [showAuthForm, setAuthForm, menuDrawer, setMenuDrawer]
  );

  const alertContextValue = useMemo(
    () => ({
      isAlertVisible: isAlertVisible,
      alertMessage: alertMessage,
      alertType: alertType,
      alertTitle: alertTitle,
      showAlert: (
        _alertType: AlertType,
        _title: string,
        _message: string | null
      ) => {
        setAlertMessage(_message);
        setAlertType(_alertType);
        setAlertTitle(_title);
        setIsAlertVisible(true);
      },
      hideAlert: () => setIsAlertVisible(false),
    }),
    [isAlertVisible, alertMessage, alertType, alertTitle]
  );

  return (
      <ModelContext.Provider value={dialogContextValue}>
        <AlertContext.Provider value={alertContextValue}>
          <RouteGuard />
        </AlertContext.Provider>
      </ModelContext.Provider>
  );
}

export default App;
