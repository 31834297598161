import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class GetCartItemsRequest implements Requester {

  path(): string {
    return ApiPath.CART;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {};
  }

  body(): any {
    return null;
  }
}
