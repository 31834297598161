import { WPGetProductByIdRequest, WPGetProductsRequest } from "../../models/requests";
import { Product } from "../../models/responses/WPProductResponse";
import WPAbstractLogicLayer from "./WPAbstractLogicLayer";

export class WPProductsRepository extends WPAbstractLogicLayer {
  async getProducts(parameter?: object) {
    const request = new WPGetProductsRequest(parameter);
    return this.performRequest<Product[]>(request);
  }

  async getProductById(parameter?: number) {
    const request = new WPGetProductByIdRequest(parameter);
    return this.performRequest<Product>(request);
  }
}

export default WPProductsRepository;
