import React, { useEffect, useState } from "react";
import {
  Navbar,
  Collapse,
  Typography,
  List,
  ListItem,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { extractSlugFromURL } from "../services";
import { __DEV__ } from "../constant";
import { WPMenuRepository } from "../business";

interface NavListMenuProps {
  item: any;
  callBackFunc?: any;
}

function NavListMenu(props: NavListMenuProps) {
  const { item } = props;
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = React.useState(false);

  const renderItems = item?.children?.map((data: any, key: any) => (
    <Link to={`/products/${extractSlugFromURL(data?.url)}`} key={key}>
      <MenuItem
        onClick={props.callBackFunc}
        placeholder={undefined}
        className="flex items-center gap-3 rounded-lg"
      >
        <div className="ml-4 sm:ml-0">
          <Typography
            placeholder={undefined}
            variant="h6"
            color="blue-gray"
            className="flex items-center text-sm font-normal"
          >
            {data?.title}
          </Typography>
          {/* <Typography
              variant="paragraph"
              className="text-xs !font-medium text-blue-gray-500"
            >
              {data?.cat_desc}
            </Typography> */}
        </div>
      </MenuItem>
    </Link>
  ));

  return (
    <React.Fragment>
      <Menu
        open={isMenuOpen}
        handler={setIsMenuOpen}
        offset={{ mainAxis: 20 }}
        placement="bottom"
        allowHover={true}
      >
        <MenuHandler>
          <Typography
            placeholder={undefined}
            as="div"
            variant="small"
            className="font-medium"
          >
            <ListItem
              placeholder={undefined}
              className="flex items-center gap-2 py-2 pr-4 font-medium text-gray-900"
              selected={isMenuOpen || isMobileMenuOpen}
              onClick={() => setIsMobileMenuOpen((cur) => !cur)}
            >
              <Link to={`/products/${extractSlugFromURL(item?.url)}`}>
                {item?.title}
              </Link>
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`hidden h-3 w-3 transition-transform lg:block ${isMenuOpen ? "rotate-180" : ""
                  }`}
              />
              <ChevronDownIcon
                strokeWidth={2.5}
                className={`block h-3 w-3 transition-transform lg:hidden ${isMobileMenuOpen ? "rotate-180" : ""
                  }`}
              />
            </ListItem>
          </Typography>
        </MenuHandler>
        <MenuList
          placeholder={undefined}
          className="hidden max-w-screen-xl lg:block"
        >
          <ul className="grid grid-cols-1 gap-y-2 outline-none outline-0">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <div className="block lg:hidden">
        <Collapse open={isMobileMenuOpen}>{renderItems}</Collapse>
      </div>
    </React.Fragment>
  );
}

enum LoadingState {
  LOADING = "LOADING",
  LOADED = "LOADED",
  ERROR = "ERROR",
}

export function NavbarWithMegaMenu({ callBackFunc }: { callBackFunc?: any }) {
  const [navItems, setNavItems] = useState<any[] | undefined | null>(null);
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING
  );

  const wpMenuRepository = new WPMenuRepository();

  const fetchMenuItems = async () => {
    try {
      const categories = await wpMenuRepository.getMenuByID(18);
      setNavItems(categories?.data?.items);
      setLoadingState(LoadingState.LOADED);
    } catch (error) {
      if (__DEV__) {
        console.log(error);
      }
      setLoadingState(LoadingState.ERROR);
    }
  };

  useEffect(() => {
    fetchMenuItems();
  }, []);

  return (
    <Navbar
      placeholder={undefined}
      fullWidth={true}
      shadow={false}
      variant="filled"
      className="px-1 pt-0 pb-1 bg-white"
    >
      <div className="flex items-center justify-between text-blue-gray-900 h-full">
        <div className="block">
          {loadingState === LoadingState.LOADING && (
            <div className="grid grid-cols-1 md:grid-cols-6 gap-4 p-1 md:w-[640px]">
              {[1, 2, 3, 4, 5, 6].map((item, index) => (
                <Skeleton
                  key={`${item} ${index}`}
                  duration={0.8}
                  width="100%"
                  height="30px"
                />
              ))}
            </div>
          )}
          <List
            placeholder={undefined}
            className="mt-4 mb-6 p-0 lg:mt-0 lg:mb-0 lg:flex-row lg:p-1"
          >
            {loadingState === LoadingState.LOADED && (
              <Link to={"/"}>
                <Typography
                  placeholder={undefined}
                  as="span"
                  variant="small"
                  color="blue-gray"
                  className="font-medium"
                >
                  <ListItem
                    onClick={callBackFunc}
                    placeholder={undefined}
                    className="flex items-center gap-2 py-2 pr-4 border-b-2 lg:border-none text-lg"
                  >
                    Home
                  </ListItem>
                </Typography>
              </Link>
            )}
            {loadingState === LoadingState.LOADED &&
              navItems &&
              navItems.map((item: any, index: number) =>
                item.children && item?.children.length > 0 ? (
                  <NavListMenu item={item} callBackFunc={callBackFunc} />
                ) : (
                  <Link
                    onClick={callBackFunc}
                    key={index}
                    to={`/products/${extractSlugFromURL(item.url)}`}
                  >
                    <Typography
                      placeholder={undefined}
                      as="span"
                      variant="small"
                      color="blue-gray"
                      className="font-medium lg:border-none border-b-2"
                    >
                      <ListItem
                        placeholder={undefined}
                        className="flex items-center gap-2 py-2 pr-4 text-lg"
                      >
                        {item?.title}
                      </ListItem>
                    </Typography>
                  </Link>
                )
              )}
            {loadingState === LoadingState.LOADED && (
              <Link to={"/"}>
                <Typography
                  placeholder={undefined}
                  as="span"
                  variant="small"
                  color="blue-gray"
                  className="font-medium border-b-2  lg:hidden"
                >
                  <ListItem
                    onClick={callBackFunc}
                    placeholder={undefined}
                    className="flex items-center gap-2 py-2 pr-4 text-lg"
                  >
                    Get in Touch


                  </ListItem>
                  <div className="flex items-center gap-2 py-1 px-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z" />
                    </svg>

                    <span className="text-lg">+91-89507-14229</span>

                  </div>
                  <div className="flex items-center gap-2 py-1 px-3">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
                    </svg>


                    <span className="text-lg">care@insidestylestore.com</span>

                  </div>
                </Typography>
              </Link>
            )}
          </List>
        </div>
      </div>
    </Navbar>
  );
}

export default NavbarWithMegaMenu;
