import { FaBars, FaHeart, FaShoppingCart, FaUser } from "react-icons/fa";
import { useContext, useEffect, useState } from "react";
import { ModelContext } from "../App";
import { Link } from "react-router-dom";
import {
  Storage,
  StorageActionType,
  StorageKey,
  StorageType,
} from "../utils/helpers/storage";

import { DropDown } from "./DropDown";
import { getUSERNAME, isUserLoggedIn, sliceSentence } from "../services";
import { MenuDrawer } from "./MenuDrawer";
import NavbarWithMegaMenu from "./NavbarWithMegaMenu";
import { SearchBar } from "./SearchBar";
import { Auth } from "./Auth";
import { LOGO } from "../assets/img";
import { CartRepository } from "../business/CartRepository";
import { Carousel } from "@material-tailwind/react";
import { IoColorFill } from "react-icons/io5";
export function Header() {
  const cartRepo = new CartRepository();

  // Assuming you're calling this within an async function

  const [cartItems, setCartItems] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);

  useEffect(() => {
    fetchCartItems();
  }, [cartItems, cartRepo, totalQuantity]);

  useEffect(() => {
    calculateTotalQuantity(cartItems);
  }, [cartItems, cartRepo, totalQuantity]);

  const fetchCartItems = async () => {
    try {
      const cartResponse = await cartRepo.getCartItems();
      const items: any = cartResponse?.data?.cart;
      setCartItems(items);
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const calculateTotalQuantity = (items: any) => {
    const total = items?.reduce(
      (acc: any, item: any) => acc + parseInt(item?.cart_quantity),
      0
    );
    setTotalQuantity(total);
  };

  const userName = Storage(
    StorageType.SESSION,
    StorageActionType.GET,
    StorageKey.FULLNAME ?? ""
  );

  const modelContextValue = useContext(ModelContext);
  const handleAuthOpen = () =>
    modelContextValue?.authDialog?.setOpen(
      !modelContextValue?.authDialog?.open
    );
  const handleMenuOpen = () => modelContextValue.menuDrawer.setOpen(true);

  const handleLogOut = () => {
    Storage(
      StorageType.COOKIE,
      StorageActionType.CLEAR_ALL,
      StorageKey.KEYID,
      null
    );
    window.location.replace("/");
  };

  return (
    <>
      <div
        style={{
          background: "#FAEFDB",
        }}
        className="fixed z-40 w-full top-0 "
      >
        <Carousel
          className="w-full text-black overflow-hidden"
          navigation={() => {}}
          placeholder={undefined}
        >
          <h1 className="text-[#AB6940] font-bold text-lg py-1 flex justify-center items-center">
            10% off on First Purchase{" "}
          </h1>
        </Carousel>
      </div>
      <header className="top-9 fixed z-30 bg-white shadow-lg w-full">
        <nav
          style={{
            background: "#FCDFB0",
          }}
          className="flex justify-between  sm:justify-between items-center p-2 lg:px-14"
        >
          <div className="section-left flex justify-start items-center">
            <div className="lg:hidden mr-2">
              <FaBars size="22px" onClick={handleMenuOpen} />
            </div>
            <div className={`${!isUserLoggedIn()} && w-[11rem]  sm:w-48`}>
              <Link to="/" className="flex justify-start items-center">
                <img
                  src={LOGO}
                  alt="logo"
                  className={`w-[28px] sm:w-[40px] mr-2`}
                />
                <h3 className="text-xl font-bold">Inside Style</h3>
              </Link>
            </div>
            <div className="hidden sm:block w-[340px] sm:px-4 md:px-8 md:w-[420px] xl:px-16 xl:w-[720px]">
              <SearchBar />
            </div>
          </div>
          <div className="section-right flex gap-6 items-center">
            {userName && (
              <div className="hidden lg:block">
                Welcome <b>{userName}</b>
              </div>
            )}

            {/* {!isUserLoggedIn() && (
              <div
                className={
                  "flex mt-1 sm:mt-0 flex-col items-center justify-center "
                }
                onClick={handleAuthOpen}
              >
                <span className={"mb-[1px]"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 20"
                    fill="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      fillRule="evenodd"
                      d="M15.75 1.5a6.75 6.75 0 0 0-6.651 7.906c.067.39-.032.717-.221.906l-6.5 6.499a3 3 0 0 0-.878 2.121v2.818c0 .414.336.75.75.75H6a.75.75 0 0 0 .75-.75v-1.5h1.5A.75.75 0 0 0 9 19.5V18h1.5a.75.75 0 0 0 .53-.22l2.658-2.658c.19-.189.517-.288.906-.22A6.75 6.75 0 1 0 15.75 1.5Zm0 3a.75.75 0 0 0 0 1.5A2.25 2.25 0 0 1 18 8.25a.75.75 0 0 0 1.5 0 3.75 3.75 0 0 0-3.75-3.75Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                <span className="text-xs">LOGIN</span>
              </div>
            )} */}

            <div className="">
              <DropDown
                title={
                  <div className="flex mt-1 sm:mt-0 flex-col items-center justify-center">
                    <FaUser size="18px" />
                    <span className="mt-1 text-xs">
                      {isUserLoggedIn() ? "Profile" : "Login"}
                    </span>
                  </div>
                }
                showDropArrowIcon={false}
                dropDownItems={[
                  <div key="0" className="py-1">
                    <div className="py-2 px-3">
                      <p className="text-sm font-semibold text-rose-500">
                        Welcome
                      </p>
                      {!isUserLoggedIn() && (
                        <p className="text-xs text-gray-400 font-lighter ">
                          To access account and manage orders
                        </p>
                      )}
                    </div>
                    {isUserLoggedIn() ? (
                      <div className="flex items-center px-4 my-1">
                        <img
                          className="w-8 h-8 rounded-full mr-3"
                          src="https://newsapi.org/images/anonymous.png"
                          alt="user"
                        />
                        <span className="font-medium text-rose-500">
                          {userName
                            ? userName
                            : sliceSentence(getUSERNAME(), 30) ?? ""}
                        </span>
                      </div>
                    ) : (
                      <span
                        onClick={handleAuthOpen}
                        className={
                          "cursor-pointer inline-block rounded transition ease-in-out delay-150 bg-appPrimary-500 text-black mx-3 mb-2 px-2 py-1 text-sm"
                        }
                      >
                        Login / Signup
                      </span>
                    )}
                  </div>,
                  <Link to="/profile" key="1">
                    <div className="py-1 ">
                      <span className="text-gray-700 hover:text-rose-500 hover:font-semibold block px-4 py-2 text-sm ">
                        {"Profile"}
                      </span>
                    </div>
                  </Link>,
                  <Link to="/order" key="1">
                    <div className="py-1 ">
                      <span className="text-gray-700 hover:text-rose-500 hover:font-semibold block px-4 py-2 text-sm ">
                        Orders
                      </span>
                    </div>
                  </Link>,
                  <Link to="/cart" key="1">
                    <div key="3" className="py-1">
                      <span className="text-gray-700 hover:text-rose-500 hover:font-semibold block px-4 py-2 text-sm">
                        Cart
                      </span>
                    </div>
                  </Link>,
                  <Link to="/wishlist" key="2">
                    <div key="2" className="py-1">
                      <span className="text-gray-700 hover:text-rose-500 hover:font-semibold block px-4 py-2 text-sm">
                        Wishlist
                      </span>
                    </div>
                  </Link>,
                  <a href="https://wa.me/+918950714229" target="_blank">
                    <div key="3" className="py-1">
                      <span className="cursor-pointer text-gray-700 hover:text-rose-500 hover:font-semibold block px-4 py-2 text-sm">
                        Contact Us
                      </span>
                    </div>
                  </a>,
                  isUserLoggedIn() ? (
                    <div key="4" className="py-1">
                      <span
                        onClick={handleLogOut}
                        className="cursor-pointer text-gray-700 hover:text-rose-500 hover:font-semibold block px-4 py-2 text-sm"
                      >
                        Logout
                      </span>
                    </div>
                  ) : (
                    <></>
                  ),
                ]}
              />
            </div>
            <div className="hidden lg:block">
              <DropDown
                title={
                  <Link to="/wishlist">
                    <div className="flex  flex-col items-center justify-center">
                      <FaHeart size="18px" />
                      <span className="mt-1 text-xs">Wishlist</span>
                    </div>
                  </Link>
                }
                showDropArrowIcon={false}
                dropDownItems={null}
              />
            </div>
            <div className="hidden lg:block">
              <DropDown
                title={
                  <Link to="/cart">
                    <div className="flex flex-col items-center justify-center relative">
                      <span className="absolute top-[-6px] right-0 rounded-full bg-white shadow-2xl px-1  text-center justify-center text-xs">
                        {totalQuantity && totalQuantity}
                      </span>
                      <FaShoppingCart size="18px" />
                      <span className="mt-1 font-medium text-xs">Cart</span>
                    </div>
                  </Link>
                }
                showDropArrowIcon={false}
                dropDownItems={null}
              />
            </div>
          </div>
        </nav>
        <div className="border-b"></div>
        <div className="hidden lg:block lg:px-10">
          <NavbarWithMegaMenu />
        </div>
      </header>
      {/* margin fix header */}
      <div className="mt-8 lg:mt-20">&nbsp;</div>
      <Auth />
      <div className="lg:hidden">
        <MenuDrawer />
      </div>
    </>
  );
}
