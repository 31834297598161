import { Storage, StorageActionType, StorageKey, StorageType } from "../utils/helpers/storage";

const isUserLoggedIn = () => {
  const UID = Storage(
    StorageType.COOKIE,
    StorageActionType.GET,
    StorageKey.UID
  );
  const KEY_ID = Storage(
    StorageType.COOKIE,
    StorageActionType.GET,
    StorageKey.KEYID
  );
  const USERNAME = Storage(
    StorageType.COOKIE,
    StorageActionType.GET,
    StorageKey.USERNAME
  );

  return UID && KEY_ID && USERNAME ? true : false;
};

const getUID = () =>
  Storage(StorageType.COOKIE, StorageActionType.GET, StorageKey.UID);

const getKEY_ID = () =>
  Storage(StorageType.COOKIE, StorageActionType.GET, StorageKey.KEYID);

const getUSERNAME = () =>
  Storage(StorageType.COOKIE, StorageActionType.GET, StorageKey.USERNAME);

export { getKEY_ID, getUID, getUSERNAME, isUserLoggedIn };
