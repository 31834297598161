import { OtpType, PassCode } from "../../business";
import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";

export class SendOTPRequest implements Requester {
  private phone: string;
  private type: OtpType;
  private code: PassCode;

  constructor(phone: string, type: OtpType, code: PassCode) {
    this.phone = phone;
    this.type = type;
    this.code = code;
  }

  path(): string {
    return ApiPath.VERIFY;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      phone: this.phone,
      type: this.type,
      pass_code: this.code,
    };
  }

  body(): any {
    return null;
  }
}

export default SendOTPRequest;
