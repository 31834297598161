import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";

export class WPGetProductsRequest implements Requester {
  private parameter: object | undefined;

  constructor(parameter?: object) {
    this.parameter = parameter;
  }

  path(): string {
    return ApiPath.WP_PRODUCT;
  }

  method(): RequestType {
    return RequestType.GET;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return this?.parameter?.hasOwnProperty("status")
      ? this.parameter
      : {
          ...this.parameter,
          status: "publish",
        };
  }

  body(): any {
    return null;
  }
}

