import { API_HOST } from "../constant";

export async function pushOrderToShipRocket(data: any) {
  const apiUrl = API_HOST + "/api/shipRocket.php";

  const requestData = data;

  fetch(apiUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      console.log("Response:", data);
      // Handle the response data as needed
    })
    .catch((error) => {
      console.error("Error:", error.message);
      // Handle errors appropriately
    });
}
