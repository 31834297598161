import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { Typography } from "@material-tailwind/react";

import { useMediaQuery } from "react-responsive";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1900 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1500 },
    items: 4,
  },
  miniDesktop: {
    breakpoint: { max: 1500, min: 1300 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1300, min: 1000 },
    items: 2,
  },
  miniTablet: {
    breakpoint: { max: 1000, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 2,
  },
  miniMobile: {
    breakpoint: { max: 540, min: 0 },
    items: 1,
  },
};

export function WideHomePLPCarousel(props) {
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });
  const imageResponsive350 = useMediaQuery({ query: "(max-width: 360px)" });
  const { products } = props;

  // console.log(products, "products");

  return (
    <>
      {!isMobile ? (
        <Carousel
          responsive={responsive}
          arrows={true}
          autoPlay={false}
          autoPlaySpeed={1500}
          infinite={true}
          className="mx-auto z-10"
        >
          {products?.map((item, key, index) => (
            <Link key={key + index} to={`/product/${item?.slug}`}>
              <div className="mx-2">
                <img
                  className=" object-fill h-96 sm:h-[25rem] w-full"
                  src={item?.images[0]?.src}
                  alt={item?.images[0]?.alt}
                />

                <h2 className="text-center m-2 font-bold">
                  {item?.name ?? "Product Title"}
                </h2>
                <h3 className="text-center">
                  Starts from ₹ {item?.price ?? "-"}
                </h3>
              </div>
            </Link>
          ))}
        </Carousel>
      ) : (
        <div className="grid grid-cols-2 gap-y-4 sm:grid-cols-2 relative">
          {products?.slice(0, 8).map((item, index) => (
            <Link key={index} to={`/product/${item?.slug}`} className="relative">
              <div className="mx-2">
                <img
                  className={`object-cover ${
                    !imageResponsive350 ? `h-44` : `h-36`
                  }   w-full`}
                  src={item?.images[0]?.src}
                  alt={item?.images[0]?.alt}
                />
                <h2 className="text-center mt-2 font-semibold text-sm">
                  {item?.name ?? "Product Title"}
                </h2>
                <h3 className="text-center font-normal text-sm">
                  Starts from ₹ {item?.price ?? "-"}
                </h3>
                {item?.sale_price && (
                  <div className="!absolute top-2 left-4 flex gap-2 rounded-sm bg-appPrimary-500 px-2 py-1 z-10">
                    <Typography
                      placeholder={undefined}
                      color="black"
                      className="text-sm uppercase font-medium"
                    >
                      {Math.ceil(
                        ((+item?.regular_price - +item?.sale_price) /
                          +item?.regular_price) *
                          100
                      )}
                      % OFF
                    </Typography>
                  </div>
                )}
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

WideHomePLPCarousel.defaultProps = {
  categories: [],
};
