import {
  Stepper,
  Step,
  Typography,
} from "@material-tailwind/react";
import { classNames } from "../../services";

const paymentStatusCode = [
  {
    title: "pending",
  },
  {
    title: "processing",
  },
  {
    title: "completed",
  },
];

function OrderTrack({
  orderType,
  orderStatus,
}: {
  orderType: string;
  orderStatus:string;
  }) {
  
  const paymentStatus = paymentStatusCode;
  
  const active = paymentStatus.findIndex((item) => item.title == orderStatus);

  // console.log(active, orderStatus)
  
  return (
    <Stepper
      placeholder={undefined}
      className="w-full"
      activeStep={active}
      lineClassName="bg-blue-gray-50"
      activeLineClassName="bg-green-500"
    >
      {paymentStatus?.map((item) => (
        <Step
          placeholder={undefined}
          className="h-4 w-4 !bg-blue-gray-50 text-gray-300 cursor-pointer"
          activeClassName={classNames(
            "ring-0 !bg-green-500 text-green-500",active != paymentStatus.length - 1 ? "animate-pulse" : "" 
          )}
          completedClassName="!bg-green-500 text-green-500"
        >
          <div className="mt-6 relative right-3 text-center text-xs">
            <Typography
              placeholder={undefined}
              variant="h6"
              className="text-xs text-center capitalize"
              color="inherit"
            >
              {item?.title}
            </Typography>
          </div>
        </Step>
      ))}
    </Stepper>
  );
}

export default OrderTrack;
