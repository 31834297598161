export function sliceSentence(sentence:string, maxLength:number) {
  if (sentence.length > maxLength) {
    return sentence.slice(0, maxLength - 3) + "...";
  } else {
    return sentence;
  }
}

export function extractSlugFromURL(url:string) {
  try {
    const slugIndex = url.indexOf("/product-category/");
    if (slugIndex !== -1) {
      const slug = url.substring(slugIndex + "/product-category/".length);
      const trimmedSlug = slug.replace(/\/$/, ""); // Remove trailing slash if present
      return trimmedSlug;
    } else {
      console.error("Invalid URL format");
      return null;
    }
  } catch (error) {
    console.error("Error occurred:", error);
    return null;
  }
}
