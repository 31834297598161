import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Wishlist } from "../../models/responses/common";
import { findWishProductId } from "../../services";
import ProductCardForWishlist from "./ProductCardForWishlist";
import { useWishlist } from "../../hooks";
import { WishlistItem } from "../../layout";

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
  EMPTY,
}

const WishlistPage = () => {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADED
  );

  const { wishlist } = useWishlist();

  const [wishlistResponseData, setWishlistResponseData] = useState<
    WishlistItem[] | [] | null | undefined
  >(wishlist);

  useEffect(() => {
    setWishlistResponseData(
      wishlist?.filter((item: WishlistItem) =>
        findWishProductId(wishlist, item?.productId)
      )
    );
  }, [wishlist]);

  return (
    <div className="p-3 my-12">
      <h2 className="text-2xl  font-bold text-center">Wishlist</h2>
      <div className="grid mt-6 gap-y-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
        {loadingState === LoadingState.LOADING &&
          [1, 2, 3, 4, 5].map((item) => (
            <div key={item} className="w-full max-w-[26rem] h-[32rem] px-1.5">
              <Skeleton width="100%" duration={0.8} height="100%" />
            </div>
          ))}

        {loadingState === LoadingState.LOADED &&
          wishlistResponseData &&
          wishlistResponseData?.map((item: WishlistItem) => (
            <div key={item?.wishId} className="px-1 mx-auto mx-2">
              <ProductCardForWishlist productCode={item?.productId?.toString()} />
            </div>
          ))}
      </div>

      {(wishlistResponseData?.length == 0 ||
        wishlistResponseData == undefined) &&
        loadingState === LoadingState.LOADED && (
          <div className="my-2 sm:w-96 mx-auto">
            <img
              className="mx-auto w-full"
              src="https://cdni.iconscout.com/illustration/premium/thumb/add-favorite-items-into-wishlist-2739549-2283945.png?f=webp"
              alt="no wishlist found"
            />
          </div>
        )}
    </div>
  );
};

export default WishlistPage;
