import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";
import { UserData } from "../responses/UserResponse";

export class UpdateUserRequest implements Requester {
  private userData: UserData;

  constructor(userData: UserData) {
    this.userData = userData;
  }

  path(): string {
    return ApiPath.USER;
  }

  method(): RequestType {
    return RequestType.PUT;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return this.userData;
  }
}
