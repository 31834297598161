import React, { useContext, useEffect, useState } from "react";
import { Drawer, Typography, IconButton } from "@material-tailwind/react";
import { ModelContext } from "../App";
import NavbarWithMegaMenu from "./NavbarWithMegaMenu";
import { ProductListingFilter } from "./ProductListing/ProductListingFilter";

import Skeleton from "react-loading-skeleton";

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
}

const FilterDrawer = ({
  setProductFilterMobile,
  productFilterMobile,
  productFilter,
  setProductFilter,
  productPriceRange,
  setProductPriceRange,
  loadingState,
}: any) => {
  const menuContextValue = useContext(ModelContext);
  const [prevProductFilter, setPrevProductFilter] = useState(null);

  const open = menuContextValue?.menuDrawer?.open;

  useEffect(() => {
    // Check if the product filter has changed and the filter drawer is open
    if (
      prevProductFilter !== null &&
      productFilter !== prevProductFilter &&
      productFilterMobile
    ) {
      // Close the filter drawer
      // window.location.reload();
      setProductFilterMobile(false);
      // Refresh the page
    }
    // Update the previous product filter
    setPrevProductFilter(productFilter);

    // Disable scrolling on the body when the drawer is open
    if (productFilterMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Cleanup the effect
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [
    productFilter,
    productFilterMobile,
    prevProductFilter,
    setProductFilterMobile,
  ]);

  const closeDrawer = () => setProductFilterMobile((prev: any) => !prev);

  return (
    <React.Fragment>
      <Drawer
        placeholder={undefined}
        open={productFilterMobile}
        onClose={closeDrawer}
        className="p-4"
        style={{ zoom: 1 }} // Set the zoom level to 1 to prevent zooming
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography placeholder={undefined} variant="h5" color="black">
            Filters
          </Typography>
          <IconButton
            placeholder={undefined}
            variant="text"
            color="blue-gray"
            onClick={closeDrawer}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <div onClick={() => closeDrawer} className="">
          {loadingState === LoadingState.LOADED && (
            <div className="block h-[600px] overflow-y-scroll">
              <ProductListingFilter
                productFilter={productFilter}
                setProductFilter={setProductFilter}
                productPriceRange={productPriceRange}
                setProductPriceRange={setProductPriceRange}
              />
            </div>
          )}

          {/* {loadingState === LoadingState.LOADING && (
            <div className="block mr-1.5 h-[420px]">
              <Skeleton width="100%" duration={0.8} height="100%" />
            </div>
          )} */}
        </div>
      </Drawer>
    </React.Fragment>
  );
};

export default FilterDrawer;
