import { Carousel } from "@material-tailwind/react";
import { Image } from "../../models/responses/common";
import { Link } from "react-router-dom";

interface ProductImageCarouselProps {
  images: Image[] | [];
  path: string;
}

function ProductImageCarousel(props: ProductImageCarouselProps) {
  const { images,path } = props;
  return (
    <Carousel placeholder={undefined} className="h-full w-full " navigation={() => null}>
      {images?.map((item, index) => (
        <Link target='_blank' rel="noopener noreferrer" key={index} to={path}>
          <img
            className="h-full w-full object-cover "
            src={item?.src}
            alt={item?.alt}
          />
        </Link>
      ))}
    </Carousel>
  );
}

export default ProductImageCarousel;
