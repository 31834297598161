import { Button, Carousel, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { CMSContentType, CMSRepository } from "../business";
import { NO_IMAGE } from "../assets/img";
import Skeleton from "react-loading-skeleton";

export enum LoadingState {
  Loading,
  Loaded,
  Error,
}

export function HeroSection() {
  const [homeBanner, setHomeBanner] = useState<any>([]);
  const [homeBannerLoadingState, setHomeBannerLoadingState] =
    useState<LoadingState>(LoadingState.Loading);

  const [featuredPost, setFeaturedPost] = useState<any>([]);
  const [featuredPostLoadingState, setFeaturedPostLoadingState] = useState(
    LoadingState.Loading
  );

  const cmsRepository = new CMSRepository();

  const fetchHeroBanner = async () => {
    setHomeBannerLoadingState(LoadingState.Loading);

    try {
      const cmsResponse = await cmsRepository.getCMSContent(
        CMSContentType.HOME_BANNER
      );

      setHomeBanner(cmsResponse?.data ?? []);
      setHomeBannerLoadingState(LoadingState.Loaded);
    } catch (error) {
      setHomeBannerLoadingState(LoadingState.Error);
    }
  };

  const fetchFeaturedPost = async () => {
    setFeaturedPostLoadingState(LoadingState.Loading);

    try {
      const cmsResponse = await cmsRepository.getCMSContent(
        CMSContentType.FEATURED
      );

      setFeaturedPost(cmsResponse?.data ?? []);
      setFeaturedPostLoadingState(LoadingState.Loaded);
    } catch (error) {
      setFeaturedPostLoadingState(LoadingState.Error);
    }
  };

  useEffect(() => {
    fetchHeroBanner();
    fetchFeaturedPost();
  }, []);

  return (
    <>
      <div className="md:flex md:flex-row md:gap-6 m-4 sm:m-8 mb-14 ">
        {homeBannerLoadingState === LoadingState.Loaded &&
          homeBanner &&
          homeBanner.length > 0 && (
            <Carousel
              autoplay
              className="mb-8 md:mb-0 h-[41rem]"
              placeholder={undefined}
            >
              {homeBanner?.map((item: any, key: any, index: any) => (
                <div key={index + key} className="relative h-full w-full">
                  <img
                    src={item?.img ?? NO_IMAGE}
                    alt="Hero Banner"
                    className="h-full w-full object-cover animate_animated animate_zoomIn"
                  />
                  <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/40 ">
                    <div className="w-3/4 text-center md:w-2/4">
                      <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl "
                        placeholder={undefined}
                      >
                        {item?.tagline ?? "Tagline..."}
                      </Typography>
                      <div className="flex justify-center gap-2">
                        <Button
                          className="rounded-sm border text-white border-white hover:text-black hover:bg-white px-6 py-2  font-medium animate_animated animate_fadeInRight"
                          size="lg"
                          placeholder={undefined}
                          onClick={() => {
                            window.location.replace(item?.link ?? "#");
                          }}
                        >
                          Explore Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          )}

        {homeBannerLoadingState === LoadingState.Loading && (
          <div className="mb-8 md:mb-0 h-[41rem] w-full">
            <Skeleton duration={0.8} width="100%" height="100%" />
          </div>
        )}

        {featuredPostLoadingState === LoadingState.Loaded &&
          featuredPost &&
          featuredPost?.length > 0 &&
          featuredPost?.map((item: any, key: any, index: any) => (
            <div className="basis-1/2" key={key + index}>
              <div>
                <img
                  className="h-[27rem] w-full object-cover animate_animated animate_zoomIn"
                  src={item?.img ?? NO_IMAGE}
                  alt=""
                />
              </div>
              <div className="flex flex-col justify-center items-center m-8 ">
                <h2 className="font-bold animate_animated animate_fadeInUp">
                  {item?.tagline ?? "Product Title"}
                </h2>
                <p className="text-center mt-2 animate_animated animate_fadeInUp">
                  {item?.description ?? "Product Description..."}
                </p>

                <a href={item?.link ?? "#"}>
                  <button className="mt-4 font-light hover:text-gray-400 animate_animated animate_fadeInUp border border-gray-200 rounded-md px-2 py-1">
                    Shop Now
                  </button>
                </a>
              </div>
            </div>
          ))}

        {featuredPostLoadingState === LoadingState.Loading && (
          <div className="basis-1/2 h-[41rem] w-full">
            <Skeleton duration={0.8} width="100%" height="100%" />
          </div>
        )}
      </div>
      <hr></hr>
    </>
  );
}
