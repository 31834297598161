import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";

export class AddToCartRequest implements Requester {
  private productId: number;

  constructor(productId: number) {
    this.productId = productId;
  }

  path(): string {
    return ApiPath.CART;
  }

  method(): RequestType {
    return RequestType.POST;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return {
      product_id: this.productId,
      quantity:1
    };
  }
}
