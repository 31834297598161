import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CategoriesContext } from "../layout";
import { WPCategory } from "../models/responses/WPCategoriesResponse";

const samplePlaceholderTexts = [
  "bedsheets",
  "curtains",
  "jutebags",
  "curtains",
  "pillows",
  "rugs",
  "rinnerware",
  "lamps",
  "throw blankets",
  "tablecloths",
  "wall art",
  "cushions",
  "cookware",
  "towels",
];

export function SearchBar() {
  const navigate = useNavigate();

  const categoriesData = useContext(CategoriesContext);

  const [currentPlaceholder, setCurrentPlaceholder] = useState<number>(0);

  const placeholderTexts =
    categoriesData?.categories && categoriesData?.categories?.length > 0
      ? categoriesData.categories?.map((item: WPCategory) => item?.name)
      : samplePlaceholderTexts;

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPlaceholder((prev) => (prev + 1) % placeholderTexts?.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [placeholderTexts]);

  return (
    <input
      onChange={(e: any) => {
        if (e.target.value != "") {
          setTimeout(() => {
            navigate(`/search/${e.target.value}`);
          }, 1000);
        } else {
          navigate(`/`);
        }
      }}
      style={{
        background: "#FFF",
      }}
      className="transition ease-in-out delay-150 w-full rounded py-2 px-4 placeholder:text-rose-400 text-rose-600 outline-none border-2"
      type="search"
      placeholder={`Search for ${
        placeholderTexts.length !== 0
          ? placeholderTexts[currentPlaceholder]?.toLowerCase()
          : "products"
      }`}
    />
  );
}
