import { Radio, Input, Button } from "@material-tailwind/react";
import { useState } from "react";

export interface ProductFilter {
  code?: string;
  name?: string;
  asc?: boolean;
}

const productFilters: ProductFilter[] = [
  {
    code: "date",
    name: "Newly Arrived",
    asc: false,
  },
  {
    code: "popularity",
    name: "Popular",
    asc: false,
  },
  {
    code: "price",
    name: "Price High-Low",
    asc: false,
  },
  {
    code: "price",
    name: "Price Low-High",
    asc: true,
  },
  {
    code: "title",
    name: "Name A-Z",
    asc: true,
  },
  {
    code: "title",
    name: "Name Z-A",
    asc: false,
  },
  {
    code: "rating",
    name: "Rating High-Low",
    asc: false,
  },
  {
    code: "rating",
    name: "Rating Low-High",
    asc: true,
  },
];

export function ProductListingFilter({
  productFilter,
  setProductFilter,
  productPriceRange,
  setProductPriceRange,
}: {
  productFilter: ProductFilter;
  setProductFilter: any;
  productPriceRange: {
    min: number;
    max: number;
  };
  setProductPriceRange: any;
}) {
  const [tempProductPriceRange, setTempProductPriceRange] = useState<{
    min: number;
    max: number;
  }>(productPriceRange);

  
  const applyProductPriceRangeFilter = () =>
    setProductPriceRange(tempProductPriceRange);

  return (
    <>
      <div className="p-4 divide-y divide-gray-200 ">
        {productFilters && (
          <>
            <div>
              <h5 className="text-xl font-semibold mb-1 uppercase">Filters</h5>
            </div>
            <div className="py-3">
              <h5 className="text-base font-semibold ">Sort By</h5>
              <div className="grid grid-cols-1">
                {productFilters?.map((item: ProductFilter,index: number) => (
                  <Radio
                  key={index}
                    name="type"
                    defaultChecked={item?.name === productFilter?.name}
                    label={item?.name}
                    crossOrigin={undefined}
                    onClick={() => setProductFilter(item)}
                  />
                ))}
              </div>
            </div>
            <div className="py-3">
              <div className="flex justify-between items-center">
                <h5 className="text-base font-semibold ">Price</h5>

                <p
                  className="text-sm text-red-500 font-bold cursor-pointer"
                  onClick={() =>
                    setProductPriceRange({
                      min: 0,
                      max: 0,
                    })
                  }
                >
                  Clear Filter
                </p>
              </div>
              <div className="grid grid-cols-1 p-3 gap-4">
                <Input
                  color="black"
                  variant="outlined"
                  label={"Min"}
                  size="md"
                  type={"number"}
                  crossOrigin={undefined}
                  min={1}
                  value={
                    tempProductPriceRange?.min !== 0
                      ? tempProductPriceRange?.min
                      : undefined
                  }
                  onChange={(e) =>
                    setTempProductPriceRange({
                      min: +e.target.value,
                      max: +productPriceRange?.max,
                    })
                  }
                />
                <Input
                  color="black"
                  variant="outlined"
                  label={"Max"}
                  size="md"
                  type={"number"}
                  crossOrigin={undefined}
                  value={
                    tempProductPriceRange?.max !== 0
                      ? tempProductPriceRange?.max
                      : undefined
                  }
                  onChange={(e) =>
                    setTempProductPriceRange({
                      min: +productPriceRange?.min,
                      max: +e.target.value,
                    })
                  }
                />
                <Button
                  placeholder={undefined}
                  onClick={applyProductPriceRangeFilter}
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
