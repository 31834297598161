

import { CartItem } from "../layout";
import { CartResponse } from "../models/responses/CartResponse";
import { Product } from "../models/responses/WPProductResponse";

function findCartProductId(
  cart: CartItem[] | [] | null | undefined,
  prodId: number | string
) {
  const cartItem = cart?.find((item: CartItem) => +item?.productId === +prodId);
  if (cartItem) {
    return true;
  }
  return false; // Return false if productId is not found in the cart
}

function getCartId(
  cart: CartItem[] | [] | null | undefined,
  prodId: number | string
) {
  const cartItem = cart?.find((item: CartItem) => +item?.productId === +prodId);
  if (cartItem) {
    return +(cartItem.cartId ?? "0");
  } else {
    return null;
  }
}

function unformatNumber(formattedNumber: string) {
  // Remove any non-numeric characters (e.g., commas, dollar signs, etc.)
  return parseFloat(formattedNumber.replace(/[^0-9.-]+/g, ""));
}

export interface SpecialCartItem {
  cart_id: string;
  cart_user_id: string;
  cart_product_id: string;
  cart_quantity: string;
  cart_created_at: string;
  cart_updated_at: string;
  prodTotalPrice: string;
  product_detail: Product | undefined;
}

function parseCart(data: SpecialCartItem[],amount?:string | number): CartResponse {
  let orderPriceSummary = {
    convenienceFee: 0,
    discount: +(amount ?? "0"),
    subTotal: 0,
    totalAmount: 0,
  };

  let cartItems = [];

  for (let i = 0; i < data.length; i++) {
    if (data[i].product_detail) {
      data[i].prodTotalPrice = (data[i].product_detail?.stock_quantity ?? 0) > 0 ? (
        parseFloat(data[i]?.product_detail?.price ?? "0") *
        parseFloat(data[i].cart_quantity)
      ).toFixed(2) : "0";
      cartItems.push(data[i]);
      orderPriceSummary.subTotal +=
        (data[i].product_detail?.stock_quantity ?? 0) > 0
          ? parseFloat(data[i]?.product_detail?.price ?? "0") *
            parseFloat(data[i].cart_quantity)
          : 0;
      orderPriceSummary.convenienceFee += 0;
      orderPriceSummary.discount += 0;
    }
  }

  orderPriceSummary.totalAmount += orderPriceSummary.subTotal;

  return {
    orderPriceSummary: orderPriceSummary,
    cart: cartItems,
  };
}

export { findCartProductId, getCartId, unformatNumber, parseCart };
