import { ApiPath, RequestType, Requester } from "../../core/network/ApiClient";

export interface PhonePayTransaction {
  merchantId: string;
  merchantTransactionId: string;
  merchantUserId: string;
  amount: number;
  redirectUrl: string;
  redirectMode: string;
  callbackUrl: string;
  mobileNumber: string;
  paymentInstrument: {
    type: string;
  };
}

export class CreatePhonePayPaymentLinkRequest implements Requester {
  private data: PhonePayTransaction;

  constructor(data: PhonePayTransaction) {
    this.data = data;
  }

  path(): string {
    return ApiPath.PhonePay_PAYMENT;
  }

  method(): RequestType {
    return RequestType.POST;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return null;
  }

  body(): any {
    return this.data;
  }
}


