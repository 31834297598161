import { Button, Carousel } from "@material-tailwind/react";
import { SetStateAction, useState } from "react";
import { Image } from "../../models/responses/common";
import { parseImageArray } from "../../services";
import ReactImageMagnify from "react-image-magnify";
import { relative } from "path";
import { Link } from "react-router-dom";
import { PiMagnifyingGlassPlusLight } from "react-icons/pi";
interface PDPImageProps {
  productImages: Image[];
  setIsImagesVisible: any;
}

function PDPImage(props: PDPImageProps) {
  const { productImages, setIsImagesVisible } = props;
  const [productImageArray, setProductImageArray] =
    useState<Image[]>(productImages);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [cursorPosiiton, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleFilterImg = (img: Image) => {
    const filteredImg = parseImageArray(productImageArray, img);
    setProductImageArray(filteredImg);
  };

  const handleMouseHover = (e: any) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();

    // calculate the position (x,y) as a percentage on cursor

    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;

    setPosition({ x, y });

    // update cursorposition

    setCursorPosition({ x: e.pageX - left, y: e.pageY - top });
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:mx-12 my-6 gap-2 relative">
        <div>
          <div className="sm:hidden">
            <Carousel
              placeholder={undefined}
              className="w-full h-[320px] sm:h-[420px] md:h-[540px]"
            >
              {productImageArray &&
                productImageArray?.length > 0 &&
                productImageArray?.map((item: Image) => (
                  <img
                    key={item.id}
                    className="w-full h-full "
                    src={item.src}
                    alt={item.alt}
                  />
                ))}
            </Carousel>
          </div>

          <div
            className="hidden sm:block"
            style={{}}
            onMouseEnter={() => setShowMagnifier(true)}
            onMouseLeave={() => setShowMagnifier(false)}
            onMouseMove={handleMouseHover}
          >
            <img
              style={{ position: "relative", cursor: "pointer" }}
              className="w-[100%] h-[360px] sm:h-[550px] "
              src={productImageArray[0].src}
              alt={productImageArray[0].alt}
            />
            {showMagnifier && (
              <div
                style={{
                  position: "absolute",
                  left: `${cursorPosiiton.x - 50}px`,
                  top: `${cursorPosiiton.y - 50}px`,
                  zIndex: 1000,

                  // make sure the magnifier doesnt interface with mouse events
                  pointerEvents: "none",
                }}
              >
                <div
                  // adjust the background posiiton on based of cursor
                  style={{
                    backgroundImage: `url(${productImageArray[0].src})`,
                    backgroundPosition: `${position.x}% ${position.y}%`,
                    width: 350,
                    height: 350,
                    border: "2px solid #fff",
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
        <div className="hidden sm:grid grid-cols-6  gap-1">
          {productImageArray &&
            productImageArray?.length > 1 &&
            productImageArray?.map((item: Image) => (
              <img
                onClick={() => handleFilterImg(item)}
                key={item.id}
                className="w-full h-full object-cover cursor-pointer border"
                src={item.src}
                alt={item.alt}
              />
            ))}
        </div>

        <div
          className="absolute right-4 top-4 cursor-pointer border-black bg-white border-2 rounded-full p-1"
          onClick={() => {
            setIsImagesVisible(true);
            console.log("clickeddd");
          }}
        >
          <PiMagnifyingGlassPlusLight size={24}/>
        </div>
      </div>
    </>
  );
}

export default PDPImage;
