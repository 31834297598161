import { useEffect, useState } from "react";
import { WPProductsRepository } from "../business";
import { Product } from "../models/responses/WPProductResponse";
import { __DEV__ } from "../constant";
import Skeleton from "react-loading-skeleton";
import { ProductCard } from "./ProductCard";

export enum ProductQuery {
  FEATURED = "featured",
  NEW = "new",
  BEST_SELLER = "bestseller",
}

interface HomePLPProps {
  title: string;
  query: ProductQuery;
}

enum LoadingState {
  LOADING,
  LOADED,
  FAILED,
}

export function HomePLP(props: HomePLPProps) {
  const { title, query } = props;

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.LOADING
  );

  const [homePlp, setHomePlp] = useState<Product[] | undefined | null>([]);

  const wpProductsRepository = new WPProductsRepository();

  const fetchHomePLP = async () => {
    const per_page = 5;
    setLoadingState(LoadingState.LOADING);
    try {
      let wpProductsResponse;
      if (query === ProductQuery.FEATURED) {
        wpProductsResponse = await wpProductsRepository.getProducts({
          featured: true,
          per_page,
        });
      } else if (query === ProductQuery.NEW) {
        wpProductsResponse = await wpProductsRepository.getProducts({
          per_page,
        });
      } 
      else if (query === ProductQuery.BEST_SELLER) {
        wpProductsResponse = await wpProductsRepository.getProducts({
          per_page,
          category: 15,
        });
      }
      __DEV__ && console.log("HomePLP:", wpProductsResponse?.data);
      setHomePlp(wpProductsResponse?.data);
      setLoadingState(LoadingState.LOADED);
    } catch (error) {
      console.log(error);
      setLoadingState(LoadingState.FAILED);
    }
  };

  useEffect(() => {
    fetchHomePLP();
  }, []);

  return (
    <div className="p-3 my-12">
      <h2 className="text-2xl font-bold text-center">{title}</h2>
      <div className="grid mt-6 gap-y-3 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5">
        {loadingState === LoadingState.LOADING &&
          [1, 2, 3, 4, 5].map((item) => (
            <div key={item} className="w-full max-w-[26rem] h-[32rem] px-1.5">
              <Skeleton width="100%" duration={0.8} height="100%" />
            </div>
          ))}

        {loadingState === LoadingState.LOADED &&
          homePlp &&
          homePlp?.map((product: Product) => (
            <div key={product.id} className="px-1 mx-auto">
              <ProductCard product={product} />
            </div>
          ))}
      </div>
    </div>
  );
}
