import { ProfileQuery } from "../../business/ProfileRepository";
import { ApiPath, Requester, RequestType } from "../../core/network/ApiClient";
import { UserAddress } from "../responses/UserResponse";

export class UpdateAddressRequest implements Requester {
  private address: UserAddress[] | [];

  constructor(address: UserAddress[] | []) {
    this.address = address;
  }

  path(): string {
    return ApiPath.PROFILE;
  }

  method(): RequestType {
    return RequestType.PUT;
  }

  headers(): any {
    return null;
  }

  params(): any {
    return {
      query: ProfileQuery.ADDRESS,
    };
  }

  body(): any {
    return {
      address: this.address,
    };
  }
}

export default UpdateAddressRequest;
