import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CategoryCard } from "./CategoryCard";

import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1900 },
    items: 8,
  },
  desktop: {
    breakpoint: { max: 1900, min: 1500 },
    items: 6,
  },
  miniDesktop: {
    breakpoint: { max: 1500, min: 1300 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1300, min: 1000 },
    items: 4,
  },
  miniTablet: {
    breakpoint: { max: 1000, min: 700 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 2,
  },
  miniMobile: {
    breakpoint: { max: 540, min: 0 },
    items: 1,
  },
};

export function CategoryCarousel(props: any) {
  const { categories } = props;
  console.log(categories, "categories");
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  return (
    <>
      {!isMobile ? (
        <Carousel
          responsive={responsive}
          arrows={true}
          autoPlay={false}
          autoPlaySpeed={1500}
          infinite={true}
          className="mx-auto z-10"
        >
          {categories &&
            categories?.map((item: any, key: any) => (
              <div className="mx-2" key={key}>
                <CategoryCard category={item} />
              </div>
            ))}
        </Carousel>
      ) : (
        <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 relative">
          {categories?.map((category: any) => (
            <Link key={category.slug} to={`/products/${category.slug}`}>
              <Card
                placeholder={undefined}
                shadow={false}
                className="relative rounded-none cursor-pointer grid h-[20rem] w-full sm:max-w-[20rem] items-end justify-center overflow-hidden text-center"
              >
                <CardHeader
                  placeholder={undefined}
                  floated={false}
                  shadow={false}
                  color="transparent"
                  className="absolute inset-0 m-0 h-[20rem] w-full rounded-none bg-cover bg-center"
                  style={{
                    backgroundImage: `url(${category.image?.src})`,
                  }}
                >
                  <div className="to-bg-black-10 absolute inset-0 h-[20rem] w-full bg-gradient-to-t from-black/80 via-black/50" />
                </CardHeader>
                <CardBody
                  placeholder={undefined}
                  className="relative py-14 px-6 md:px-12"
                >
                  <Typography
                    placeholder={undefined}
                    variant="h4"
                    color="white"
                    className="font-medium leading-[1.5]"
                  >
                    {category.name ?? "Category Name"}
                  </Typography>
                </CardBody>
              </Card>
            </Link>
          ))}
        </div>
      )}
    </>
  );
}

CategoryCarousel.defaultProps = {
  categories: [],
};
