export const Terms = () => {
  
    return (
        <div>
            <div>
                <h1 className="text-center text-2xl mt-16">Terms And Conditions</h1>

                <div className="mt-8 mx-6 md:mx-12 lg:mx-36">
                    <h2>1. User Account, Password, and Security:</h2>

                    <p className="text-gray-500 mt-2 mb-2 text-justify">
                        If you opt to use the Platform, it is incumbent upon you to maintain
                        the confidentiality of both your Display Name and Password. You are
                        held responsible for all activities carried out under your Display
                        Name and Password. If you provide information that is inaccurate,
                        deceptive, outdated, incomplete, or not in accordance with these
                        Terms of Use, we retain the authority to suspend, terminate, or
                        permanently restrict your access to the Platform.
                    </p>
                    <h2>2. Services Offered:</h2>

                    <p className="text-gray-500 mt-2 mb-2 text-justify">
                        Our platform, Hazel Delightful Designs, offers a range of
                        Internet-based services, with a primary focus on providing users the
                        opportunity to purchase distinctive home decor products. Users can
                        explore and buy a variety of home decor items through our platform,
                        including but not limited to furnishings, decorative pieces, and
                        accessories.
                    </p>
                    <h2>3. Shipping Policy:</h2>

                    <p className="text-gray-500 mt-2 mb-2 text-justify">
                        We know you must be super excited to get your hands on the product.
                        We’ve got your back. Trust our large network of couriers to deliver
                        your products to you as early as possible.
                        <br />
                        You will receive an order confirmation email after you place the
                        order and a tracking link on email after your order is shipped.
                        <br />
                        It takes about 2-3 days after order confirmation to ship your
                        product and 5-10 business days to reach your doorstep.
                        <br />
                        We offer free shipping on orders above Rs 499.
                        <br />
                        The delivery time will vary depending on where you stay.
                        <br />
                        We’ll keep you updated on the order status every step of the way via
                        Email/SMS. You will receive the order tracking link on email after
                        your order is shipped.
                        <br />
                        In case of any special time-bound delivery requests, please share an
                        email us at care@insidestylestore.com about the requested time of
                        delivery. We will try our best to get the order to you at the
                        earliest
                        <br />
                        While we use some of India’s best logistics companies for shipping,
                        we may be bound in coverage by their reach. In case your address is
                        in a location not served by them, we will contact you to find an
                        alternative solution to make your products reach you.  Still unsure
                        of where your order is? Write to care@insidestylestore.com
                        <br />
                        P.S – If you had a less than soulful shopping experience with us,
                        please write to us and tell us why, at care@insidestylestore.com We
                        are committed to making your space yours and it starts here.
                    </p>
                    <h2>4. Return Policy</h2>
                    <p className="text-gray-500 mt-2 mb-2 text-justify">
                        If you are not happy with your purchase, we will accept a return of
                        an unused product within 7 days from the date of delivery of the
                        products. Once we receive the returned item Indian Home Fab will
                        then give a full refund (excluding shipping as we are unable to
                        refund the initial shipping cost of your order) post verification of
                        the product at our warehouse. Refund shall be processed as per the
                        applicable guidelines / notification / law passed by RBI from time
                        to and it may take 07-14 days additional business days to reflect
                        the same in your account post processing the cancellation request if
                        order is cancelled before the shipment or after products are
                        received back and verified at our warehouse if product is returned.
                        For all cash on delivery transactions, refunds shall be made through
                        account transfer as per the details shared by you. Discounted items
                        are not eligible for a return. Returned items must be delivered to
                        us unused, in original packaging, and in the condition, they were
                        received or may not be eligible for refund or be subject to a
                        restocking fee. We cannot be held responsible for items damaged or
                        lost in the return shipment, therefore we recommend an insured and
                        trackable mail service. We aim to accept all returns. In the
                        unlikely event that an item is returned to us in an unsuitable
                        condition, we may have to send it back to you. All goods will be
                        inspected on return
                    </p>
                </div>
            </div>
        </div>
    );
};
