import { AddToCartRequest, EmptyCartRequest, GetCartItemsRequest, RemoveFromCartRequest, UpdateQuantityRequest } from "../models/requests";
import { CartResponse } from "../models/responses/CartResponse";
import AbstractLogicLayer from "./AbstractLogicLayer";

export class CartRepository extends AbstractLogicLayer {
  async getCartItems() {
    const request = new GetCartItemsRequest();
    return this.performRequest<CartResponse>(request);
  }

  async addToCart(
    prodId: number
  ) {
    const request = new AddToCartRequest(prodId);
    return this.performRequest<number>(request);
  }

  async removeFromCart(
    cartId:number
  ) {
    const request = new RemoveFromCartRequest(cartId);
    return this.performRequest<number>(request);
  }

  async updateQuantity(
    cartId: number,
    updatedQuantity: number
  ) {
    const request = new UpdateQuantityRequest(cartId, updatedQuantity);
    return this.performRequest<any>(request);
    
  }

  async emptyCart() {
    const request = new EmptyCartRequest();
    return this.performRequest<any>(request);
  }
}

export default CartRepository;
