import React from "react";
import { Button, IconButton } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import { createArray } from "../services";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  setCurrentPage: any;
}

export function Pagination(props: PaginationProps) {
  const { totalPages, currentPage, setCurrentPage } = props;

  const [active, setActive] = React.useState<number>(currentPage);

  const getItemProps = (index: number) =>
    ({
      variant: active === index ? "filled" : "text",
      onClick: () => setActive(index),
    } as any);

  const next = () => {
    if (active === totalPages) return;
    setActive(active + 1);
  };

  const prev = () => {
    if (active === 1) return;
    setActive(active - 1);
  };

  React.useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    setCurrentPage(active);
  }, [active]);

  return (
    <div className="flex items-center gap-4">
      <Button
        placeholder={undefined}
        variant="text"
        className="flex items-center gap-2"
        onClick={prev}
        disabled={active == 1}
      >
        <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
      </Button>
      <div className="flex items-center gap-2">
        {createArray(totalPages).map((item: number) => (
          <IconButton {...getItemProps(item)}>{item}</IconButton>
        ))}
      </div>
      <Button
        placeholder={undefined}
        variant="text"
        className="flex items-center gap-2"
        onClick={next}
        disabled={active == totalPages}
      >
        Next
        <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
      </Button>
    </div>
  );
}
